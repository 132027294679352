// aggregatePerceptualData.js

export const aggregatePerceptualData = (results) => {
  const accumulator = {};
  const counts = {};
  const commentsAccumulator = {};
  const isPresentAccumulator = {};

  results.forEach(result => {
    const data = result?.perceptualData || {};
    Object.entries(data).forEach(([key, value]) => {
      // Processa VASValue
      if (value?.VASValue !== undefined) {
        if (!accumulator[key]) {
          accumulator[key] = 0;
          counts[key] = 0;
        }
        accumulator[key] += value.VASValue;
        counts[key] += 1;
      }
      
      // Processa attributeComment (concatena tots els comentaris)
      if (value?.attributeComment && value.attributeComment.trim() !== '') {
        if (!commentsAccumulator[key]) {
          commentsAccumulator[key] = value.attributeComment;
        } else {
          commentsAccumulator[key] += "; " + value.attributeComment;
        }
      }
      
      // Processa isPresent: si algun és true, es marca com a true
      if (value?.isPresent === true) {
        isPresentAccumulator[key] = true;
      }
    });
  });

  const meanData = {};
  // Utilitza la unió de totes les claus trobades
  const keys = new Set([
    ...Object.keys(accumulator),
    ...Object.keys(commentsAccumulator),
    ...Object.keys(isPresentAccumulator)
  ]);
  
  keys.forEach(key => {
    const mean = counts[key] ? (accumulator[key] / counts[key]) : 0;
    meanData[key] = {
      VASValue: counts[key] ? Math.round(mean) : 0,
      attributeComment: commentsAccumulator[key] || '',
      isPresent: !!isPresentAccumulator[key]
    };
  });
  
  return meanData;
};