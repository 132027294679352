// useUserData.js

import { useState, useEffect } from 'react';
import { fetchUserData } from '../utils/userUtils';

export function useUserData(apiUrl) {
  const [userId, setUserId] = useState(null);
  const [sociodemographicData, setSociodemographicData] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await fetchUserData(apiUrl);
        console.log("🔍 Data returned by fetchUserData:", userData); // 📌 Afegit per depuració

        setUserId(userData.id);
        setSociodemographicData(userData);
      } catch (error) {
        console.error('⚠️ Error initializing user data:', error);
      }
    };

    fetchUser();
  }, [apiUrl]);

  return { userId, sociodemographicData };
}