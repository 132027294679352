// SessionJoiner.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import pusher from './../../../../services/pusher';
import { useAuth } from '../../../../auth/authContext';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const SessionJoiner = ({ onSessionJoined }) => {
  const { t } = useTranslation();
  const { user } = useAuth();  // ✅ Agafem el context d'autenticació
  const [code, setCode] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0); // Desplaçar-se a la part superior quan es carrega el component
  }, []);

  useEffect(() => {
    // Subscriu-te a l'esdeveniment quan un participant s'uneix a una sessió
    const channel = pusher.subscribe('my-channel');
    
    channel.bind('server-participant-joined', (data) => {
      console.log('Pusher event received:', data);
    });

    return () => {
      // Neteja dels esdeveniments quan es desmunta el component
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const joinSession = () => {
    if (!code || !participantName) {
      setError(t('error_fill_all_fields'));
      return;
    }

    setLoading(true);
    setError('');

    // Preparem les dades per unir-se a la sessió
    const joinData = {
      code,
      participantName,
      userId: user ? user._id : null  // ✅ Afegim el userId si està loguejat
    };

    // Crida a l'API per unir-se a la sessió
    axios.post(`${apiUrl}/interactive-sessions/join`, joinData)
      .then(response => {
        const selectedSample = response.data.selectedSample;
        if (typeof onSessionJoined === 'function') {
          onSessionJoined(code, selectedSample, participantName);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error joining session:', error);
        setError(t('error_joining_session'));
        setLoading(false);
      });
  };  

  const handleInputChange = (e) => {
    setCode(e.target.value);
  };

  return (
    <div>
      <p>{t('lorem_ipsum')}</p><br/>
      <div className="button-container">
        <input
          type="text"
          className="input-field"
          placeholder={t('session_code')}
          value={code}
          onChange={handleInputChange}
          disabled={loading}
        />
        <input
          type="text"
          className="input-field"
          placeholder={t('name_id')}
          value={participantName}
          onChange={(e) => setParticipantName(e.target.value)}
          disabled={loading}
        />
        {error && (
          <p style={{ color: 'red', marginTop: '10px' }}>
            {error}
          </p>
        )}
        <button
          className="button-center"
          onClick={joinSession}
          disabled={loading}
        >
          {loading ? t('joining') : t('join_session')}
        </button>
      </div>
    </div>
  );
};

export default SessionJoiner;