// CAPEVProtocol.jsx

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import CAPEVCitation from './../../../components/citations/CAPEVCitation';

function CAPEVProtocol() {
  const { t } = useTranslation();  

  return (
    <div className="main-content"> {/* 🔹 Contenidor principal */}
      
      {/* 🔹 Contingut principal del protocol */}
      <div className="long-text-container">
        <section className="long-section">
          <Trans 
            i18nKey="cape-v-protocol_whole_text" 
            components={{
              h1: <h1>{t('Title')}</h1>,
              h2: <h2>{t('Subtitle')}</h2>,
              h3: <h3>{t('Section')}</h3>,
              h4: <h4>{t('Subsection')}</h4>,
              p: <p />,
              ul: <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }} />,
              li: <li style={{ marginBottom: '5px' }} />,
              strong: <strong />,
            }}
          />
        </section>
      </div>
    <br/>
      {/* 🔹 Cita i botó de retorn (fora de long-text-container) */}
      <div className="extra-content">
        <div className="cites">
          <CAPEVCitation />
        </div>


      </div>
    </div>
  );
}

export default CAPEVProtocol;