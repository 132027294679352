// ConceptsCategorization.jsx

import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { DragDropContext } from '@hello-pangea/dnd';
import AvailableConcepts from './AvailableConcepts';
import Categories from './Categories';
import Feedback from './Feedback';
import YourCategorization from './YourCategorization';
import ConsentModal from './ConsentModal';
import { useCategories } from './useCategories';
import { useUserData } from './../../../hooks/useUserData';
import { useSubmission } from './useSubmission';

function ConceptsCategorization({ availableConceptsData, apiEndpoint, title, instructions, consentText }) {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  // Hooks modularitzats
  const { userId, sociodemographicData } = useUserData(apiUrl);
  const { availableConcepts, setAvailableConcepts, categories, setCategories, handleAddCategory, handleDeleteCategory, onDragEnd } = useCategories(availableConceptsData);
  const { showFeedback, otherUsersCategorizations, currentPage, totalPages, fetchFeedback, handleSubmit } = useSubmission(apiUrl, apiEndpoint);

  // Estat local separat per cada camp d'entrada
  const [newCategoryName, setNewCategoryName] = useState(''); // Input per afegir categories
  const [observations, setObservations] = useState(''); // Input per afegir observacions
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [consentGiven, setConsentGiven] = useState(null);

  return (
    <div >
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <h2>{t(title)}</h2>
        <Trans i18nKey={instructions} />
      </div>

      {!showFeedback ? (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <AvailableConcepts availableConcepts={availableConcepts} setAvailableConcepts={setAvailableConcepts} />
            <Categories categories={categories} setCategories={setCategories} handleDeleteCategory={handleDeleteCategory} />
          </DragDropContext>

          {/* Afegir nova categoria */}
          <div>
            <label htmlFor="newCategory">{t('add_new_category')}</label>
            <input
              id="newCategory"
              className="input-field"
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder={t('type_category_name')}
            />
            <button className="button-center" 
              onClick={() => {handleAddCategory(newCategoryName); setNewCategoryName(''); }}>
              {t('add')}
            </button>
          </div>

          {/* Observacions */}
          <div>
            <label htmlFor="observations">{t('observations')}</label>
            <textarea
              id="observations"
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
              placeholder={t('add_any_additional_observation_here')}
              className="capevr-textarea"
            />
          </div>

          {/* Botó per enviar dades */}
          <button className="button-center" onClick={() => setShowConsentModal(true)}>
            {t('submit')}
          </button>

          <ConsentModal
            isOpen={showConsentModal}
            onRequestClose={() => setShowConsentModal(false)}
            onConsentChange={setConsentGiven}
            onSubmit={() => handleSubmit(userId, consentGiven, sociodemographicData, categories, observations)}
            consentText={consentText}
          />
        </>
      ) : (
        <>
          <YourCategorization categories={categories} observations={observations} />
          <h2>{t('other_users_categorizations')}</h2>
          <Feedback feedbackData={otherUsersCategorizations} />
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <p>{t('page')} {currentPage} {t('of')} {totalPages}</p>
            <button className="button-center" onClick={() => fetchFeedback(currentPage - 1)} disabled={currentPage === 1}>{t('previous')}</button>
            <button className="button-center" onClick={() => fetchFeedback(currentPage + 1)} disabled={currentPage === totalPages}>{t('next')}</button>
          </div>
        </>
      )}
    </div>
  );
}

export default ConceptsCategorization;