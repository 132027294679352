// OsRatings.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTableVAS from './../../../components/feedback/DataTableVAS';

function OsRatings({ submittedData, sampleDatabase, onGoToFeedback, onRestart, isSessionMode }) {
  const { t } = useTranslation();
  const userData = submittedData.perceptualData || {};

  if (!submittedData) {
    return <p>{t('no_ratings_available')}</p>;
  }

  return (
    <div className="os-ratings">
      <h2>{t('your_ratings')}</h2>
      <p>{t('ratings_introduction')}</p>
      <DataTableVAS
              userData={userData}
              attributeKeys={['overallSeverity']}
          />
       <br/>
        {/* 🔀 Botons segons la base de dades i el mode */}
        <div className="button-container">
        {(!submittedData.voiceSampleId || sampleDatabase === 'upload') ? (
          <button onClick={onRestart} className="button-center">
            {t('restart_with_another_sample')}
          </button>
          ) : (
            !isSessionMode && (
              <button onClick={onGoToFeedback} className="button-center">
                {t('view_feedback')}
              </button>
            )
          )}
        </div>
    </div>
  );
}

export default OsRatings;
