// RecordingConditions.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';

function RecordingConditions({
  isAudioRecorded,
  setIsAudioRecorded,
  sessionModality,
  setSessionModality,
  recordingEnvironment,
  setRecordingEnvironment,
  recordingSetup,
  setRecordingSetup,
  mouthToMicDistanceCm,
  setMouthToMicDistanceCm,
}) {
  const t = useFixedTForResource('CAPEVR');

  return (
    <div className="capevr-conditions-section">
      {/* Audio Recorded */}
      <div className="capevr-condition-item">
        <label>{t('audio_recorded')}:</label>
        <div className="capevr-radio-group">
          <label >
            <input
              type="radio"
              name="isAudioRecorded"
              value="yes"
              checked={isAudioRecorded === "yes"}
              onChange={(e) => setIsAudioRecorded(e.target.value)}
            />
            {t('yes')}
          </label>
          <label >
            <input
              type="radio"
              name="isAudioRecorded"
              value="no"
              checked={isAudioRecorded === "no"}
              onChange={(e) => setIsAudioRecorded(e.target.value)}
            />
            {t('no')}
          </label>
        </div>
      </div>

      {/* Session Modality */}
      <div className="capevr-condition-item">
        <div className="capevr-radio-group">
          <label>
            <input
              type="radio"
              name="sessionModality"
              value="inPerson"
              checked={sessionModality === "inPerson"}
              onChange={(e) => setSessionModality(e.target.value)}
            />
            {t('in_person')}
          </label>
          <label>
            <input
              type="radio"
              name="sessionModality"
              value="virtual"
              checked={sessionModality === "virtual"}
              onChange={(e) => setSessionModality(e.target.value)}
            />
            {t('virtual')}
          </label>
        </div>
      </div>

      {/* Cel·la en blanc */}
      <div className="capevr-condition-item"></div>

      {/* Recording Environment */}
      <div className="capevr-condition-item">
        <label htmlFor="recordingEnvironment">{t('environment')}:</label>
        <select
          id="recordingEnvironment"
          value={recordingEnvironment}
          onChange={(e) => setRecordingEnvironment(e.target.value)}
          className="input-field"
        >
          <option value="" disabled>
            {t('select_environment')}
          </option>
          <option value="clinicRoom">{t('clinic_room')}</option>
          <option value="soundBooth">{t('sound_booth')}</option>
          <option value="bedside">{t('bedside')}</option>
          <option value="home">{t('home')}</option>
        </select>
      </div>

      {/* Recording Device and Platform */}
      <div className="capevr-condition-item">
        <label htmlFor="recordingSetup">{t('recording_device_and_platform')}:</label>
        <input
          type="text"
          id="recordingSetup"
          value={recordingSetup}
          onChange={(e) => setRecordingSetup(e.target.value)}
          className="input-field"
        />
      </div>

      {/* Mouth to Mic Distance */}
      <div className="capevr-condition-item">
        <label htmlFor="mouthToMicDistanceCm">{t('mouth_to_mic_distance')}:</label>
        <input
          type="number"
          id="mouthToMicDistanceCm"
          value={mouthToMicDistanceCm}
          onChange={(e) => setMouthToMicDistanceCm(e.target.value)}
          className="input-field"
        />
      </div>
    </div>
  );
}

export default RecordingConditions;