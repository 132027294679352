// SharingConsentModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Trans, useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

function SharingConsentModal({
  isOpen,
  onRequestClose,
  onSubmit,
  initialShareForFeedback = null,
  initialResearchUsage = null,
  voiceCompetenceLevel = null,
  fontSize = '0.7em'
}) {
  const { t } = useTranslation();

  const [shareForFeedback, setShareForFeedback] = useState(initialShareForFeedback);
  const [researchUsage, setResearchUsage] = useState(initialResearchUsage);

  useEffect(() => {
    setShareForFeedback(initialShareForFeedback);
    setResearchUsage(initialResearchUsage);
  }, [initialShareForFeedback, initialResearchUsage]);

  // 🔑 Quan es marca feedback, habilita automàticament l'ús en recerca
  const handleFeedbackChange = () => {
    const newFeedbackState = !shareForFeedback;
    setShareForFeedback(newFeedbackState);
    if (newFeedbackState) {
      setResearchUsage(true); // Si es marca feedback, activa recerca
    }
  };

  const handleSubmit = () => {
    onSubmit({ shareForFeedback, researchUsage });
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Sharing Consent Modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          minWidth: '300px',
          padding: '20px',
        },
      }}
    >
      <strong>{t('consent_to_share_this_evaluation_title')}</strong>
      <div>
        <p>
          {t('The following options reflect your current profile preferences.')}
          {t(' You can change your general preferences in ')} 
          <a href="/profile" target="_blank" rel="noopener noreferrer">
            {t('your profile settings')}
          </a>.
        </p>
      </div>

      {/* 🔹 Casella per a l'ús en recerca */}
      <div className="checkbox-container" style={{ fontSize }}>
        <label>
          <input
            type="checkbox"
            checked={researchUsage}
            onChange={() => setResearchUsage(!researchUsage)}
            disabled={shareForFeedback} // 🔑 No es pot desmarcar si feedback està actiu
          />
          {t('consent_include_global_database')}
        </label>
      </div>

      {/* 🔹 Casella per compartir per feedback (només si és avançat o expert) */}
      {['advanced', 'expert'].includes(voiceCompetenceLevel) && (
        <div className="checkbox-container" style={{ fontSize }}>
          <label>
            <input
              type="checkbox"
              checked={shareForFeedback}
              onChange={handleFeedbackChange} // 🔑 Activa o desactiva feedback i ajusta recerca
            />
            {t('consent_sharing_for_feedback')}
          </label>
        </div>
      )}

      <br/>

      <div style={{ fontSize }}>
        <Trans
          i18nKey="consent_information_research_and_share_evaluations"
          components={{
            a1: <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Política de Privacitat</a>,
            a2: <a href="/global-database-participant-information-sheet" target="_blank" rel="noopener noreferrer">Informació a les persones participants</a>,
            a3: <a href="/global-database-informed-consent" target="_blank" rel="noopener noreferrer">Consentiment informat</a>,
            }}
        />
      </div>

      <div style={{ textAlign: 'center' }}>
        <button className="button-center" onClick={handleSubmit}>
          {t('submit')}
        </button>
      </div>
    </Modal>
  );
}

export default SharingConsentModal;