// JoinSessionParent.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import SessionJoiner from './join-session-files/SessionJoiner';
import ParticipantSessionView from './join-session-files/ParticipantSessionView';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const JoinSessionParent = ({ onBackToJoin }) => {
  const { t } = useTranslation();
  const [sessionData, setSessionData] = useState(null);
  const [participantName, setParticipantName] = useState("Participant");

  // Funció que s'executa quan un participant s'uneix
  const handleSessionJoined = (code, selectedSample, name) => {
    setParticipantName(name || "Participant");
  
    axios.get(`${apiUrl}/interactive-sessions/${code}`)
      .then(response => {
        setSessionData(response.data);
      })
      .catch(error => {
        console.error("Error carregant la sessió després d'unir-se:", error);
      });
  };  

  // Funció per enviar els resultats de l'eina d'avaluació
  const handleSubmit = (results) => {
    console.log("Resultats enviats:", results);
  };

  return (
    <div>
      <h1 className = "text-green">{t('join_a_session')}</h1>
      {!sessionData ? (
        <SessionJoiner onSessionJoined={handleSessionJoined} />
      ) : (
        <ParticipantSessionView
          sessionData={sessionData}
          apiUrl={apiUrl}
          onSubmit={handleSubmit}
          participantName={participantName}
          isSessionMode={true}
          onJoinAnotherSession={() => {
            setSessionData(null);
            onBackToJoin(); 
          }}
        />
      )}
    </div>
  );
};

export default JoinSessionParent;