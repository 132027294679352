// InteractiveSessions.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import JoinSessionParent from './session-views/JoinSessionParent';
import CreateSessionParent from './session-views/CreateSessionParent';

function InteractiveSessions() {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectorChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="main-content">
      <h1>{t('interactive_sessions')}</h1>
      <div className="selector-container">
        <select value={selectedOption} onChange={handleSelectorChange}>
          <option value="">{t('select_an_option')}</option>
          <option value="join">{t('join_a_session')}</option>
          <option value="create">{t('create_a_session')}</option>
        </select>
      </div>
      <div className="session-container">
        {selectedOption === 'join' && (
          <JoinSessionParent onBackToJoin={() => setSelectedOption('join')} />
        )}
        {selectedOption === 'create' && <CreateSessionParent />}
      </div>
    </div>
  );
}

export default InteractiveSessions;