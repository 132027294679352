// DirectionDeviation.jsx

import React from 'react';
import { variableTranslations } from './../../../../constants/variableTranslations';

function DirectionDeviation({ attribute, directionLabel1, directionLabel2, value = {}, onDirectionChange }) {

  let name1 = "";
  let name2 = "";
  if (attribute === "pitch") {
    name1 = variableTranslations.tooHighPitch;  // "too_high_pitch"
    name2 = variableTranslations.tooLowPitch;    // "too_low_pitch"
  } else if (attribute === "loudness") {
    name1 = variableTranslations.tooLoudLoudness;  // "too_loud_loudness"
    name2 = variableTranslations.tooQuietLoudness; // "too_quiet_loudness"
  }

  const handleOptionChange = (event) => {
    const { name, checked } = event.target;
    const updatedDirection = {
      ...value,
      [name]: { attributeName: name, isPresent: checked }
    };
    onDirectionChange(updatedDirection);
  };

  return (
    <div>
      <label className="horizontal-labels">
        <input
          type="checkbox"
          name={name1}
          checked={value[name1]?.isPresent || false}
          onChange={handleOptionChange}
        />
        {directionLabel1}
      </label>
      <label className="horizontal-labels">
        <input
          type="checkbox"
          name={name2}
          checked={value[name2]?.isPresent || false}
          onChange={handleOptionChange}
        />
        {directionLabel2}
      </label>
    </div>
  );
}

export default DirectionDeviation;