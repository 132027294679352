// CAPEVWorkshopStudent.jsx

import React, { useState, useEffect } from 'react';
import StudentSessionJoiner from './StudentSessionJoiner';
import CAPEVSession from './CAPEVSession';
import RatingView from './RatingView';
import NavigationIcons from '../../../../views/app-views/NavigationIcons';

const CAPEVWorkshopStudent = () => {
  const [sessionCode, setSessionCode] = useState('');
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [selectedSample, setSelectedSample] = useState(null);
  const [studentName, setStudentName] = useState('');
  const [submittedResults, setSubmittedResults] = useState(null);

  useEffect(() => {}, [sessionCode, isSessionActive, selectedSample, studentName, submittedResults]);

  const handleSessionJoined = (code, sample, name) => {
    setStudentName(name);
    setSelectedSample(sample);
    setSessionCode(code);
    setIsSessionActive(true);
  };

  const handleResultsSubmitted = (results) => {
    setSubmittedResults(results);
  };

  const handleBackToStudentSessionJoiner = () => {
    setIsSessionActive(false);
    setStudentName('');
    setSessionCode('');
    setSubmittedResults(null);
  };

  return (
    <div>
      {submittedResults && (
        <RatingView 
          results={submittedResults} 
          onBack={handleBackToStudentSessionJoiner} 
          sessionCode={sessionCode} // Passa sessionCode aquí
          studentName={studentName} 
        />
      )}

      {isSessionActive && !submittedResults && (
        <CAPEVSession
          sessionCode={sessionCode}
          selectedSample={selectedSample}
          studentName={studentName}
          onResultsSubmitted={handleResultsSubmitted}
        />
      )}

      {!isSessionActive && !submittedResults && (
        <StudentSessionJoiner onSessionJoined={handleSessionJoined} />
      )}
      <div className="navigation-container">
          <NavigationIcons />
      </div>
    </div>
  );
};

export default CAPEVWorkshopStudent;