// CAPEVVocalTasks.jsx

import React from 'react';
import { Trans } from 'react-i18next';
import { useFixedTForResource } from '../../../../locales/useFixedTForResource';

function VocalTasks() {
  const t = useFixedTForResource('CAPEV');


  return (
    <div className="highlighted-section">
     <Trans i18nKey="cape_v_tasks" t={t} />
    </div>
  );
}

export default VocalTasks;