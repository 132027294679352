// SortList.jsx

import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import useAudioPlayer from '../../../hooks/useAudioPlayer';

function SortList({ name, items, id }) {
  const { playAudio } = useAudioPlayer();

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="droppable-area">
          <div className="items-container">
            {items.map((item, index) => (
              <Draggable draggableId={item.id} index={index} key={item.id}>
                {(provided) => (
                  <div
                    className="item-container"
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <h4>{item.label}</h4>
                    <button onClick={() => playAudio(item.audioLink)}>Play</button>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
}

export default SortList;