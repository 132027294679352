// OsParent.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OsPreview from './os-views/OsPreview';
import OsForm from './os-views/OsForm';
import OsSession from './os-views/OsSession';
import OsRatings from './os-views/OsRatings';
import FullFeedback from './../../components/feedback/FullFeedback';
import NeurovozCitation from './../../components/citations/NeurovozCitation';
import WaldenCitation from './../../components/citations/WaldenCitation';
import NavigationIcons from './../app-views/NavigationIcons';

function OsParent() {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  const [currentStep, setCurrentStep] = useState("preview");
  const [sampleDatabase, setSampleDatabase] = useState("");
  const [selectedSample, setSelectedSample] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);

  // 🔹 Funció per gestionar el canvi d'estats
  const handleNextStep = (nextStep) => {
    setCurrentStep(nextStep);
  };

  const handleSubmit = (data) => {
    if (data?.goBackToSampleSelection) {
      setSelectedSample(null);
      setCurrentStep("preview");
    } else {
      setSubmittedData(data);
      setCurrentStep("ratings");
    }
  };  
  
  return (
    <div className="capevr-container">
      <h1>{t('overall_severity')}</h1>
      
      {/* 🔹 Vista inicial: Selecció de mode (Preview) */}
      {currentStep === "preview" && (
        <OsPreview
          apiUrl={apiUrl} 
          onSelect={(sample) => {
            setSelectedSample(sample);
            handleNextStep("form");
          }}
          onStartSession={() => handleNextStep("session")}
          sampleDatabase={sampleDatabase}
          onDatabaseChange={setSampleDatabase}
          initialSelection={selectedSample ? undefined : null}
        />
      )}

      {/* 🔹 Formulari */}
      {currentStep === "form" && (
        <OsForm
          apiUrl={apiUrl} 
          selectedSample={selectedSample}
          sampleDatabase={sampleDatabase}
          onSubmit={handleSubmit}
        />
      )}

      {/* 🔹 Sessió interactiva */}
      {currentStep === "session" && (
        <OsSession 
        apiUrl={apiUrl} 
        selectedSample={selectedSample} 
        />
      )}

      {/* 🔹 Resultats de l'Avaluació */}
      {currentStep === "ratings" && (
      <OsRatings
          submittedData={submittedData}
          sampleDatabase={sampleDatabase} 
          onGoToFeedback={() => handleNextStep("feedback")}
          onRestart={() => handleNextStep("preview")} 
        />
      )}

      {/* 🔹 Feedback després dels resultats */}
      {currentStep === "feedback" && (
        <FullFeedback
          apiUrl={apiUrl}
          selectedSample={selectedSample}
          userEvaluation={submittedData}  
          // onGoBackToRatings={() => handleNextStep("ratings")} // Comentat per si es vol reutilitzar
          onGoToPreview={() => handleNextStep("preview")}
        />
      )}

<br/>

      {/* 🔹 Cites sempre visibles */}
      <div className="cites">
          {sampleDatabase === "waldenPVQD" && <WaldenCitation />}
          {sampleDatabase === "neuroVoz" && <NeurovozCitation />}
      </div>

      {/* 🔽 Bloc fix de navegació 🔽 */}
      <div className="navigation-container">
        <NavigationIcons />
      </div>
    </div>
  );
}

export default OsParent;