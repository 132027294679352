// OverallImpressionFeedback.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

const OverallImpressionFeedback = ({ userData = {}, assessments = [], sessionEvaluations = [], consensusEvaluation = null }) => {
    const { t } = useTranslation();

    // 🔍 Obté la impressió general de l'usuari
    const userImpression = userData?.perceptualData?.overallImpression || '-';

    // 🔍 Recull les impressions generals compartides
    const sharedImpressions = assessments
        .map(assessment => assessment?.perceptualData?.overallImpression)
        .filter(Boolean);  // Només comentaris no buits
    
    // 🔍 Recull les impressions generals de sessió
    const sessionImpressions = sessionEvaluations
    .map(evaluation => evaluation?.perceptualData?.overallImpression)
    .filter(Boolean);  // Només comentaris no buits

    // 🔍 Impressió de consens
    const consensusImpression = consensusEvaluation?.perceptualData?.overallImpression;

    const noData =
    userImpression === '-' &&
    sessionImpressions.length === 0 &&
    sharedImpressions.length === 0 &&
    !consensusImpression;

    if (noData) {
        return (
            <div style={{ fontSize: '12px', textAlign: 'center', padding: '8px' }}>
                {t('no_comments_available')}
            </div>
        );
    }

    return (
        <div> {/* Marges iguals a la gràfica */}
            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '12px' }}>
                <thead>
                    <tr>
                        <th style={{ width: '25%', textAlign: 'left', padding: '4px', borderBottom: '1px solid #ccc' }}>
                            {t('source')}
                        </th>
                        <th style={{ width: '75%', textAlign: 'left', padding: '4px', borderBottom: '1px solid #ccc' }}>
                            {t('comment')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* 🔹 Impressió de l'usuari */}
                    <tr>
                        <td style={{ padding: '4px', borderBottom: '1px solid #eee' }}>
                            {t('your_comment')}
                        </td>
                        <td style={{ padding: '4px', borderBottom: '1px solid #eee', color: 'red' }}>
                            {userImpression}
                        </td>
                    </tr>

                    {/* 🔹 Impressions de sessió */}
                    {sessionImpressions.length > 0 ? (
                        sessionImpressions.map((comment, index) => (
                            <tr key={`session-${index}`}>
                                <td style={{ padding: '4px', borderBottom: '1px solid #eee' }}>
                                    {t('session_comments')} #{index + 1}
                                </td>
                                <td style={{ padding: '4px', borderBottom: '1px solid #eee', color: 'red' }}>
                                    {comment}
                                </td>
                            </tr>
                        ))
                    ) : null}

                    {/* 🔹 Impressió de consens */}
                    {consensusImpression && (
                        <tr>
                        <td style={{ padding: '4px', borderBottom: '1px solid #eee' }}>
                            {t('consensus_evaluation')}
                        </td>
                        <td style={{ padding: '4px', borderBottom: '1px solid #eee', color: 'red', fontWeight: 'bold' }}>
                            {consensusImpression}
                        </td>
                        </tr>
                    )}

                    {/* 🔹 Impressions compartides */}
                    {sharedImpressions.length > 0 ? (
                        sharedImpressions.map((comment, index) => (
                            <tr key={`shared-${index}`}>
                                <td style={{ padding: '4px', borderBottom: '1px solid #eee' }}>
                                    {t('other_allvoiced_users_comments')} #{index + 1}
                                </td>
                                <td style={{ padding: '4px', borderBottom: '1px solid #eee', color: '#209385' }}>
                                    {comment}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2" style={{ padding: '4px', textAlign: 'center' }}>
                                {t('no_comments_available')}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default OverallImpressionFeedback;