import React from 'react';
import { useAuth } from '../../../auth/authContext.js';
import { useTranslation } from 'react-i18next';
import FreeSortingTaskStructure from './FreeSortingTaskStructure';
import userSpecificData from './userSpecificData'; // Adjust the import path as necessary

const FreeSortingTask = () => {
    const { t } = useTranslation();
    const { user } = useAuth();

    // Check if the user and user-specific data is available
    if (!user || !userSpecificData[user.username]) {
        return <div>{t('loading')}</div>;
    }

    const currentUserData = userSpecificData[user.username];
    const title = currentUserData.attributes[0].title;

    return (
        <div>
            <h2>{t(title)}</h2> 
            <p>{t('FST_instructions')}</p>
            <div>
                <FreeSortingTaskStructure userData={currentUserData} />
                {/* ... other components ... */}
            </div>
        </div>
    );
};

export default FreeSortingTask;