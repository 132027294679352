// CAPEVCitation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../locales/LanguageContext';

function CAPEVCitation() {
    const { t } = useTranslation();
    const { appLanguage, resourceLanguages } = useLanguage();

    const availableLanguages = resourceLanguages.CAPEV || [];
    const citationLanguage = availableLanguages.includes(appLanguage) ? appLanguage : 'en';
  
    return (
      <div className="cites">
        {['en'].includes(citationLanguage) && (
          <div>
            Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}  
        {['cat', 'es'].includes(citationLanguage) && (
          <div>
            {t('original_instrument')}:<br />Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('permission')}.<br /><br />
            {t('adaptation')}:<br />Calaf, N., & Garcia-Quintana, D. (2024). Development and Validation of the Bilingual Catalan/Spanish Cross-Cultural Adaptation of the Consensus Auditory-Perceptual Evaluation of Voice. Journal of Speech, Language, and Hearing Research, 67(4), 1072-1089. <a href="https://doi.org/10.1044/2024_JSLHR-23-00536" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/2024_JSLHR-23-00536</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2024). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}
         {['jp'].includes(citationLanguage) && (
          <div>
            {t('original_instrument')}:<br />Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('permission')}.<br /><br />
            {t('adaptation')}:<br />Kondo, K., Mizuta, M., Kawai, Y., Sogami, T., Fujimura, S., Kojima, T., ... & Haji, T. (2021). Development and validation of the Japanese version of the Consensus Auditory-Perceptual Evaluation of voice. Journal of Speech, Language, and Hearing Research, 64(12), 4754-4761. <a href="https://doi.org/10.1044/2021_JSLHR-21-00269" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/2021_JSLHR-21-00269</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2021). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}
        {['fa'].includes(citationLanguage) && (
          <div>
            {t('original_instrument')}:<br />Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('permission')}.<br /><br />
            {t('adaptation')}:<br />Salary Majd N, Khoddami SM, Drinnan M, Kamali M, Amiri-Shavaki Y, Fallahian N. Validity and rater reliability of Persian version of the Consensus Auditory Perceptual Evaluation of Voice. Audiol. 2014;23(3):65-74.<br/>© Audiology (2014). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}
        {['fr'].includes(citationLanguage) && (
          <div>
            {t('original_instrument')}:<br />Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('permission')}.<br /><br />
            {t('adaptation')}:<br />Pommée, T., Mbagira, D., & Morsomme, D. (2024). French-Language Adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Voice. <a href="https://doi.org/10.1016/j.jvoice.2024.03.011" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.jvoice.2024.03.011</a><br/>© <a href="https://www.elsevier.com/" target="_blank" rel="noopener noreferrer">Elsevier</a> (2024). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}
        {['kn'].includes(citationLanguage) && (
          <div>
            {t('original_instrument')}:<br />Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('permission')}.<br /><br />
            {t('adaptation')}:<br />Gunjawate, D. R., Ravi, R., & Bhagavan, S. (2020). Reliability and validity of the Kannada version of the Consensus Auditory-Perceptual Evaluation of Voice. Journal of Speech, Language, and Hearing Research, 63(2), 385-392.. <a href="https://doi.org/10.1044/2021_JSLHR-21-00269" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/2021_JSLHR-21-00269</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2020). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}
        {['my'].includes(citationLanguage) && (
          <div>
            {t('original_instrument')}:<br />Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('permission')}.<br /><br />
            {t('adaptation')}:<br />Mossadeq, N. M., Khairuddin, K. A. M., & Zakaria, M. N. (2022). Cross-cultural adaptation of the consensus auditory-perceptual evaluation of voice (CAPE-V) into Malay: A validity study. Journal of Voice. <a href="https://doi.org/10.1016/j.jvoice.2022.05.018" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.jvoice.2022.05.018</a><br/>© <a href="https://www.elsevier.com/" target="_blank" rel="noopener noreferrer">Elsevier</a> (2022). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}
        {['pt-br'].includes(citationLanguage) && (
          <div>
            {t('original_instrument')}:<br />Kempster, G. B., Gerratt, B. R., Verdolini Abbott, K., Barkmeier-Kraemer, J., & Hillman, R. E. (2009). Consensus Auditory-Perceptual Evaluation of Voice: Development of a Standardized Clinical Protocol. American Journal of Speech-Language Pathology, 18(2), 124-132. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />© <a href="https://www.asha.org/" target="_blank" rel="noopener noreferrer">ASHA</a> (2009). {t('permission')}.<br /><br />
            {t('adaptation')}:<br />Behlau M, Rocha B, Englert M, Madazio G. Validation of the Brazilian Portuguese CAPE-V Instrument-Br CAPE-V for Auditory-Perceptual Analysis. J Voice. 2022 Jul;36(4):586.e15-586.e20. Epub 2020 Aug 15. PMID: 32811691. <a href="https://doi.org/10.1016/j.jvoice.2020.07.007" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.jvoice.2020.07.007</a><br/>© <a href="https://www.elsevier.com/" target="_blank" rel="noopener noreferrer">Elsevier</a> (2022). {t('reprinted_with_permission')}.<br /><br />
          </div>
        )}
      </div>
  );
}

export default CAPEVCitation;