// OtherVocalAttributesRating.jsx

import React from 'react';
import VASRatingInput from '../../../../../components/form-items/VASRatingInput';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';
import { variableTranslations } from '../../../../../constants/variableTranslations';

function OtherVocalAttributesRating({
  selectedInstabilities,
  instabilitiesValues,
  setInstabilitiesValues,
  selectedAdditionalFeatures,
  additionalFeaturesValues,
  setAdditionalFeaturesValues,
}) {
  const t = useFixedTForResource('CAPEVR');

  const getTranslatedLabel = (key, valueObj = {}) => {
    if ((key === 'otherInstability' || key === 'otherAdditionalFeature') && valueObj.attributeName) {
      return valueObj.attributeName;
    }
    const translationKey = variableTranslations[key] || key;
    return t(translationKey);
  };

  const handleSliderChange = (key, VASValue, type) => {
    const update = {
      ...((type === 'instability') ? instabilitiesValues : additionalFeaturesValues),
      [key]: {
        ...(type === 'instability' ? instabilitiesValues[key] : additionalFeaturesValues[key]),
        VASValue,
      }
    };

    if (type === 'instability') {
      setInstabilitiesValues(update);
    } else {
      setAdditionalFeaturesValues(update);
    }
  };

  const hasVisibleSliders = selectedInstabilities.length > 0 || selectedAdditionalFeatures.length > 0;

  return (
    <div className="capevr-rating">
      {!hasVisibleSliders && (
        <div className="capevr-info-message">
          {t('select_to_rate')}
        </div>
      )}

      {selectedInstabilities.map((instability) => (
        <VASRatingInput
          key={instability}
          label={getTranslatedLabel(instability, instabilitiesValues[instability])}
          VASValue={instabilitiesValues[instability]?.VASValue || 0}
          setVASValue={(VASValue) => handleSliderChange(instability, VASValue, 'instability')}
          index={instability}
        />
      ))}

      {selectedAdditionalFeatures.map((feature) => (
        <VASRatingInput
          key={feature}
          label={getTranslatedLabel(feature, additionalFeaturesValues[feature])}
          VASValue={additionalFeaturesValues[feature]?.VASValue || 0}
          setVASValue={(VASValue) => handleSliderChange(feature, VASValue, 'feature')}
          index={feature}
        />
      ))}
    </div>
  );
}

export default OtherVocalAttributesRating;