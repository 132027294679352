// InstructorSessionCreator.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import feedbackDataOriginalPVQD from '../../../../constants/feedback-data/feedbackDataOriginalPVQD';
import pusher from '../../../../services/pusher';
import WaldenCitation from './../../../../components/citations/WaldenCitation';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const InstructorSessionCreator = ({ onSessionCreated }) => {
  const { t, i18n } = useTranslation();
  const [code, setCode] = useState('');
  const [selectedSample, setSelectedSample] = useState(null);
  const [showSociodemographicData, setShowSociodemographicData] = useState(false);

  useEffect(() => {
    const channel = pusher.subscribe('my-channel');
    channel.bind('server-create-session', function(data) {
      console.log('Sessió creada:', data);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const handleSampleChange = (event) => {
    const sampleName = event.target.value;
    const sample = feedbackDataOriginalPVQD.find(s => s.sampleName === sampleName);
    setSelectedSample(sample);
  };

  const createSession = () => {
    const instructorId = 'instructor-id';

    if (!instructorId) {
      console.error('instructorId is missing');
      return;
    }

    if (!selectedSample) {
      console.error('No sample selected');
      return;
    }

    const data = { instructorId, selectedSample: selectedSample.sampleName };

    axios.post(`${apiUrl}/sessions/create`, data)
    .then(response => {
      setCode(response.data.code);
      onSessionCreated(response.data.code, selectedSample);
      // El servidor triggerejarà l'esdeveniment a Pusher
    })
    .catch(error => {
      console.error('Error creating session:', error);
    });
  };

  const handleToggleSociodemographicData = () => {
    if (!selectedSample) {
      alert(t('select_a_sample'));
    } else {
      setShowSociodemographicData(!showSociodemographicData);
    }
  };

  return (
    <div>
      <h1>{t('cape_v_workshop_instructor_view')}</h1>
      <p>{t('cape_v_workshop_instructor_instructions')}</p>
      {i18n.language !== 'en' && <p>{t('note_language')}</p>}
      <div className="button-container">
        <select
          value={selectedSample?.sampleName || ''}
          onChange={handleSampleChange}
          className="sample-selector"
        >
          <option value="">{t('select_a_sample')}</option>
          {feedbackDataOriginalPVQD.map((sample, index) => (
            <option key={index} value={sample.sampleName}>
              {sample.name || `Sample ${index + 1}`}
            </option>
          ))}
        </select>

        <div className="sociodemographic-toggle" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <input
            type="checkbox"
            id="showSociodemographicData"
            checked={showSociodemographicData}
            onChange={handleToggleSociodemographicData}
          />
          <label htmlFor="showSociodemographicData">
            {t('show_sociodemographic_data')}
          </label>
        </div>

        {showSociodemographicData && selectedSample && (
          <div className="sample-info">
            <p className="small">{t('subject')}: {selectedSample.participantId || t('not_available')}</p>
            <p className="small">{t('age')}: {selectedSample.age || t('not_available')}</p>
            <p className="small">{t('gender')}: {selectedSample.gender ? t(selectedSample.gender) : t('not_available')}</p>
            <p className="small">{t('diagnose')}: {selectedSample.diagnose ? t(selectedSample.diagnose) : t('not_available')}</p>
            <p className="small">{t('language')}: {selectedSample.language ? t(selectedSample.language) : t('not_available')}</p>
            <p className="small">{t('database')}: {selectedSample.database || t('not_available')}</p>
          </div>
        )}

        {selectedSample?.sampleLink && (
          <div className="audio-container">
            <audio controls src={selectedSample.sampleLink}>
              {t('audio_not_supported')}
            </audio>
          </div>
        )}
        <button className="button-center" onClick={createSession}>{t('create_a_session')}</button>
        {code && <p>{t('session_code')}: {code}</p>}
      </div>
      <br />
      <div className="cites">
        <WaldenCitation />
      </div>
    </div>
  );
};

export default InstructorSessionCreator;