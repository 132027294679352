// Attribute.jsx
import React, { useState } from 'react';
import Collection from './Collection.jsx';
import { useTranslation } from 'react-i18next';
import './../../../locales/i18n';

function Attribute({ attributeData, username }) {
  const { t } = useTranslation();
  const { title, audioFilesSets = [], numberOfSliders, markedSamples } = attributeData;
  const [ratings, setRatings] = useState(Array(numberOfSliders * audioFilesSets.length).fill(0));

  // Function to update ratings based on slider change
  const handleSliderChange = (index, value) => {
    const newRatings = [...ratings];
    newRatings[index] = value;
    setRatings(newRatings);
  };

  // Function to generate CSV from ratings
  const downloadCSV = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
  
    audioFilesSets.forEach((audioFiles, audioSetIndex) => {
      audioFiles.forEach((audioFile, fileIndex) => {
        const overallIndex = audioSetIndex * numberOfSliders + fileIndex;
        const rating = ratings[overallIndex];
        const audioFileName = audioFile.split('/').pop(); 
        csvContent += `${username},${title},${audioFileName},${rating}\n`; // Replace attributeName with title
      });
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const fileName = `${username}_${title}_ratings.csv`; // Replace attributeName with title
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="slider-container">
      <h2>{title}</h2>
      {/* Additional instructions or content */}
      {audioFilesSets.map((audioFiles, index) => (
        <Collection
          key={index}
          audioFiles={audioFiles}
          ratings={ratings}
          handleSliderChange={handleSliderChange}
          collectionIndex={index}
          numberOfSliders={numberOfSliders}
          markedSamples={markedSamples}
        />
      ))}
      {/* Download button and other content */}
      <br></br>
      <button className="button-class" onClick={downloadCSV}>
        {t('download')} {title}
      </button>
      <br></br>
      {/* Other footer content */}
    </div>
  );
}

export default Attribute;
