// ConsentModal.jsx

import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");

const ConsentModal = ({ isOpen, onRequestClose, project, onConfirm }) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({
    inclusionCriteria: false,
    informationAndConsentReadAndAccepted: false,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.name]: e.target.checked,
    });
    setErrorMessage("");
  };

  const handleConfirm = () => {
    // Comprovem que tots els checkbox estan marcats
    if (!checkedItems.inclusionCriteria || !checkedItems.informationAndConsentReadAndAccepted) {
      setErrorMessage(t("please_confirm_all"));
      return;
    }
    // Si tot és correcte, cridem la funció de confirmació i tanquem el modal
    onConfirm();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Consent Modal"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80%",
          maxWidth: "50%",
          overflowY: "auto",
          width: "auto",
        },
      }}
    >
      <h2>{t("please_review_and_accept")}</h2>
      
      {/* Mostrem els criteris d'inclusió */}
      <p>
        <strong>{t("inclusion_criteria")}:</strong> {project.inclusionCriteria}
      </p>
      
      {/* Enllaços als documents */}
      <p>
        <a href={project.participantInformationLink} target="_blank" rel="noopener noreferrer">
          {t("participant_information_sheet")}
        </a>
      </p>
      <p>
        <a href={project.informedConsentLink} target="_blank" rel="noopener noreferrer">
          {t("informed_consent")}
        </a>
      </p>

      <div className="checkboxes">
        <label>
          <input
            type="checkbox"
            name="inclusionCriteria"
            checked={checkedItems.inclusionCriteria}
            onChange={handleCheckboxChange}
          />
          {t("i_meet_the_inclusion_criteria")}
        </label>
        <label>
          <input
            type="checkbox"
            name="informationAndConsentReadAndAccepted"
            checked={checkedItems.informationAndConsentReadAndAccepted}
            onChange={handleCheckboxChange}
          />
          {t("i_have_read_and_accept_the_participant_information_sheet_and_the_informed_consent")}
        </label>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button onClick={handleConfirm} className="button-participate">
        {t("confirm_and_participate")}
      </button>
    </Modal>
  );
};

export default ConsentModal;