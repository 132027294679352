// AdditionalAttributesRating.jsx

import React from 'react';
import VocalAttributeRatingInput from './VocalAttributeRatingInput';
import { useFixedTForResource } from '../../../../locales/useFixedTForResource';
import { variableTranslations } from './../../../../constants/variableTranslations';

function AdditionalAttributesRating({ attributes, onChange }) {
  const t = useFixedTForResource('CAPEV');

  return (
    <div className="additional-attributes-rating">
      {Object.keys(attributes).map((key) => {
        // No mostrem slider per "normalNasality"
        if (key === 'normalNasality') return null;
        // Només mostrem el component si l'atribut està seleccionat
        if (!attributes[key]?.isPresent) return null;

        // Comprovem si és l'atribut "otherAdditionalFeature" o "otherResonance"
        const isOther = key === 'otherAdditionalFeature' || key === 'otherResonance';

        const updatedLabel = isOther && attributes[key].attributeName
          ? attributes[key].attributeName
          : t(variableTranslations[key] || key);

        return (
          <VocalAttributeRatingInput
            key={key}
            label={updatedLabel}
            value={attributes[key]}
            setValue={(updatedValue) => {
              const newAttributes = {
                ...attributes,
                [key]: {
                  ...updatedValue,
                  attributeName: isOther && updatedValue.attributeName
                    ? updatedValue.attributeName
                    : variableTranslations[key] || key
                }
              };
              onChange(newAttributes);
            }}
          />
        );
      })}
    </div>
  );
}

export default AdditionalAttributesRating;