// FullFeedback.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import VASValueFeedback from './VASValueFeedback';
import CommentsTable from './CommentsTable';
import OverallImpressionFeedback from './OverallImpressionFeedback';
import AttributeStatsTable from './AttributeStatsTable';
import { useSharedAssessments } from './../../hooks/useSharedAssessments';

function FullFeedback({ apiUrl, selectedSample, userEvaluation, sessionEvaluations, onGoToPreview, isWVD, isSessionMode, isConsensusMode }) {
  const { t } = useTranslation();
  const { sharedAssessments, loading, error } = useSharedAssessments(apiUrl, selectedSample?._id);

// 🔍 Si estem en mode sessió, busquem si hi ha una avaluació de consens i la tractem com a userEvaluation
const consensusEvaluation = sessionEvaluations?.find(ev => ev.participantName === 'Consensus');

// 🔍 Excloem l'avaluació de consens de les sessionEvaluations
const filteredSessionEvaluations = sessionEvaluations?.filter(ev => ev.participantName !== 'Consensus');

// 🔍 Filtratge de dades segons el mode
const filteredUserEvaluation = isSessionMode ? [] : userEvaluation;
const filteredSharedAssessments = isSessionMode ? [] : sharedAssessments;
const filteredConsensusEvaluation = consensusEvaluation;

const hasAnyDisplayableDataForAttributes = (attributeKeys) => {
  const ignoredKeys = ['attributeName', '_id'];

  const isUsefulValue = (key, val) => {
    if (ignoredKeys.includes(key)) return false;
    if (val === null || val === undefined) return false;
    if (typeof val === 'string') return val.trim() !== '';
    if (typeof val === 'number') return key !== 'VASValue' || val > 0;
    if (typeof val === 'boolean') return val === true;
    return false;
  };

  const checkAnyUsefulData = (evaluation) =>
    Object.entries(evaluation?.perceptualData || {}).some(([attrKey, attrValue]) => {
      if (!attributeKeys.includes(attrKey)) return false;
      if (!attrValue || typeof attrValue !== 'object') return false;

      return Object.entries(attrValue).some(([key, val]) => {
        const result = isUsefulValue(key, val);
        if (result) {
          console.log(`✅ Dada útil trobada a '${attrKey}.${key}':`, val);
        }
        return result;
      });
    });

    return (
      checkAnyUsefulData(filteredUserEvaluation) ||
      (filteredSharedAssessments || []).some(checkAnyUsefulData) ||
      (filteredSessionEvaluations || []).some(checkAnyUsefulData) ||
      checkAnyUsefulData(filteredConsensusEvaluation)
    );    
};

const hasAnyOverallImpression = () => {
  const getVal = (obj) => obj?.perceptualData?.overallImpression;
  return (
    !!getVal(filteredUserEvaluation) ||
    (filteredSharedAssessments || []).some(getVal) ||
    (filteredSessionEvaluations || []).some(getVal) ||
    !!getVal(filteredConsensusEvaluation)
  );
};

  return (
    <div className="capevr-feedback">
      <h1>{t('feedback')}</h1>
      <p>{t('feedback_introduction')}</p>

      {loading ? (
        <p>Loading shared evaluations...</p>
      ) : error ? (
        <p>Error loading shared evaluations.</p>
      ) : (
        <>
    <div className="capevr-section">
      {selectedSample && (
        <div className="audio-container">
          <h2>
            {t("sample")}: {selectedSample.sampleName || selectedSample.name}
          </h2>
          {selectedSample.sampleLink ? (
            <audio controls src={selectedSample.sampleLink}></audio>
          ) : (
            <p>{t("no_audio_for_sample")}</p>
          )}
        </div>
      )}
      </div>

      {hasAnyDisplayableDataForAttributes(['overallSeverity', 'roughness', 'breathiness', 'strain']) && (
          <>
          <h3>{t('standard_attributes')}</h3>
          <VASValueFeedback
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['overallSeverity', 'roughness', 'breathiness', 'strain']}
            isSessionMode={isSessionMode}
          />
          <AttributeStatsTable 
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['overallSeverity', 'roughness', 'breathiness', 'strain']} 
            isSessionMode={isSessionMode}
          />
            </>
        )}

        {hasAnyDisplayableDataForAttributes(['normalPitch', 'tooLowPitch', 'tooHighPitch']) && (
          <>
          <h3>{t('pitch')}</h3>
           <VASValueFeedback
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['tooLowPitch', 'tooHighPitch']}
            isSessionMode={isSessionMode}
          />
          <AttributeStatsTable 
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalPitch', 'tooLowPitch', 'tooHighPitch']}
            isSessionMode={isSessionMode}
          />
          <br/>
          <CommentsTable
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalPitch', 'tooLowPitch', 'tooHighPitch']}
            isSessionMode={isSessionMode}
          />
            </>
        )}

        {hasAnyDisplayableDataForAttributes(['normalLoudness', 'tooQuietLoudness', 'tooLoudLoudness']) && (
          <>
          <h3>{t('loudness')}</h3>
           <VASValueFeedback
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['tooQuietLoudness', 'tooLoudLoudness']}
            isSessionMode={isSessionMode}
          />
          <AttributeStatsTable 
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalLoudness', 'tooQuietLoudness', 'tooLoudLoudness']}
            isSessionMode={isSessionMode}
          />
          <br/>
          <CommentsTable
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalLoudness', 'tooQuietLoudness', 'tooLoudLoudness']}
            isSessionMode={isSessionMode}
          />
          </>
        )}

        {hasAnyDisplayableDataForAttributes(['normalResonance', 'frontResonance', 'backResonance', 'otherResonance']) && (
          <>
          <h3>{t('resonance')}</h3>
          <VASValueFeedback
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['frontResonance', 'backResonance', 'otherResonance']}
            isSessionMode={isSessionMode}
          />
          <AttributeStatsTable 
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalResonance', 'frontResonance', 'backResonance', 'otherResonance']}
            isSessionMode={isSessionMode}
          />
          <br/>
          <CommentsTable
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalResonance', 'frontResonance', 'backResonance', 'otherResonance']}
            isSessionMode={isSessionMode}
          />
          </>
        )}

        {hasAnyDisplayableDataForAttributes(['normalNasality', 'hyponasality', 'hypernasality']) && (
          <>
          <h3>{t('nasality')}</h3>
          <VASValueFeedback
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['hyponasality', 'hypernasality']}
            isSessionMode={isSessionMode}
          />
          <AttributeStatsTable 
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalNasality', 'hyponasality', 'hypernasality']}
            isSessionMode={isSessionMode}
          />
          <br/>
          <CommentsTable
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['normalNasality', 'hyponasality', 'hypernasality']}
            isSessionMode={isSessionMode}
          />
          </>
        )}

        {hasAnyDisplayableDataForAttributes(['vowelsInconsistency', 'sentencesInconsistency', 'extemporaneousSpeechInconsistency']) && (
          <>
          <h3>{t('inconsistencies')}</h3>
          <CommentsTable
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['vowelsInconsistency', 'sentencesInconsistency', 'extemporaneousSpeechInconsistency']}
            isSessionMode={isSessionMode}
          />
          </>
        )}

        {hasAnyDisplayableDataForAttributes(['aphonicBreak', 'pitchBreak', 'pitchInstability', 'spasm', 'tremor', 'otherInstability']) && (
          <>
          <h3>{t('instabilities')}</h3>
          <VASValueFeedback
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['aphonicBreak', 'pitchBreak', 'pitchInstability', 'spasm', 'tremor', 'otherInstability']}
            isSessionMode={isSessionMode}
          />
          <AttributeStatsTable 
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['aphonicBreak', 'pitchBreak', 'pitchInstability', 'spasm', 'tremor', 'otherInstability']}
            isSessionMode={isSessionMode}
          />
          <br/>
          <CommentsTable
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['aphonicBreak', 'pitchBreak', 'pitchInstability', 'spasm', 'tremor', 'otherInstability']}
            isSessionMode={isSessionMode}
          />
          </>
        )}

        {hasAnyDisplayableDataForAttributes(['aphonia', 'asthenia', 'diplophonia', 'falsetto', 'fry', 'hardGlottalAttack', 'wetGurgly', 'otherAdditionalFeature']) && (
          <>
          <h3>{t('additional_features')}</h3>
          <VASValueFeedback
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['aphonia', 'asthenia', 'diplophonia', 'falsetto', 'fry', 'hardGlottalAttack', 'wetGurgly', 'otherAdditionalFeature']}
            isSessionMode={isSessionMode}
          />
          <AttributeStatsTable 
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['aphonia', 'asthenia', 'diplophonia', 'falsetto', 'fry', 'hardGlottalAttack', 'wetGurgly', 'otherAdditionalFeature']}
            isSessionMode={isSessionMode}
          />
          <br/>
          <CommentsTable
            userData={filteredUserEvaluation}
            assessments={filteredSharedAssessments}
            sessionEvaluations={filteredSessionEvaluations}
            consensusEvaluation={filteredConsensusEvaluation}
            attributeKeys={['aphonia', 'asthenia', 'diplophonia', 'falsetto', 'fry', 'hardGlottalAttack', 'wetGurgly', 'otherAdditionalFeature']}
            isSessionMode={isSessionMode}
          />
          </>
        )}

{hasAnyOverallImpression() && (
          <>
            <h3>{t('overall_impression')}</h3>
            <OverallImpressionFeedback 
              userData={filteredUserEvaluation}
              assessments={filteredSharedAssessments}
              sessionEvaluations={filteredSessionEvaluations}
              consensusEvaluation={filteredConsensusEvaluation}
              isSessionMode={isSessionMode}
            />
          </>
        )}

        <br />
        <div className="button-container">
          {isWVD ? (
            <button onClick={() => window.location.href = '/wvd-event'} className="button-center">
              {t('back_to_wvd_event')}
            </button>
          ) : !(isSessionMode || isConsensusMode) && (
            <button onClick={onGoToPreview} className="button-center">
              {t('restart_with_another_sample')}
            </button>
          )}
        </div>
      </>
    )}
  </div>
  );
}

export default FullFeedback;