// attributeTranslations.js
// Traduccions genèriques per a qualsevol variable del projecte.

export const variableTranslations = {
    // perceptual attributes
    overallSeverity: 'overall_severity',
    roughness: 'roughness',
    breathiness: 'breathiness',
    strain: 'strain',
    aphonicBreak: 'aphonic_break',
    pitchBreak: 'pitch_break',
    pitchInstability: 'pitch_instability',
    spasm: 'spasm',
    tremor: 'tremor',
    otherInstability: 'other_instability',
    aphonia: 'aphonia',
    asthenia: 'asthenia',
    diplophonia: 'diplophonia',
    falsetto: 'falsetto',
    fry: 'fry',
    hardGlottalAttack: 'hard_glottal_attack',
    wetGurgly: 'wet_gurgly',
    otherAdditionalFeature: 'other_additional_feature',
    otherResonance: 'other_additional_resonance',
    normalPitch: 'normal_pitch',
    tooHighPitch: 'too_high_pitch',
    tooLowPitch: 'too_low_pitch',
    normalLoudness: 'normal_loudness',
    tooLoudLoudness: 'too_loud_loudness',
    tooQuietLoudness: 'too_quiet_loudness',
    normalResonance: 'normal_resonance',
    frontResonance: 'front_resonance',
    backResonance: 'back_resonance',
    normalNasality: 'normal_nasality',
    hyponasality: 'hyponasality',
    hypernasality: 'hypernasality',
    vowelsInconsistency: 'vowels_inconsistency',
    sentencesInconsistency: 'sentences_inconsistency',
    extemporaneousSpeechInconsistency: 'extemporaneous_speech_inconsistency',

    // Diagnoses
    adductorSpasmodicDysphonia: 'adductor_spasmodic_dysphonia',
    atrophy: 'atrophy',
    bilateralPhonotraumaticLesion: 'bilareral_phonotraumatic_lesion',
    bilateralVocalFoldParalysis: 'bilateral_vocal_fold_paralysis',
    bilateralVocalFoldParesis: 'bilateral_vocal_fold_paresis',
    candidaLaryngitis: 'candida_laryngitis',
    chronicCough: 'chronic_cough',
    glotticCancer: 'glottic_cancer',
    goiter: 'goiter',
    hypomobility: 'hypomobility',
    laryngealGranuloma: 'laryngeal_granuloma',
    laryngealStenosis: 'laryngeal_stenosis',
    leukoplakia: 'leukoplakia',
    muscleTensionDysphonia: 'muscle_tension_dysphonia',
    noDataRegardingVoiceComplaintOrDiagnosis: 'no_data_regarding_voice_complaint_or_diagnosis',
    noVoiceComplaints: 'no_voice_complaints',
    paradoxicalVocalFoldMovement: 'paradoxical_vocal_fold_movement',
    parkinsonDisease: 'parkinson_disease',
    phonotrauma: 'phonotrauma',
    phonotraumaticLesion: 'phonotraumatic_lesion',
    reinkeEdema: 'reinke_edema',
    ulcerativeLaryngitis: 'ulcerative_laryngitis',
    unilateralVocalFoldParalysis: 'unilateral_vocal_fold_paralysis',
    unilateralVocalFoldParesis: 'unilateral_vocal_fold_paresis',
    vocalFoldCyst: 'vocal_fold_cyst',
    vocalFoldEdema: 'vocal_fold_edema',
    vocalFoldLesions: 'vocal_fold_lesions',
    vocalFoldNodules: 'vocal_fold_nodules',
    vocalFoldPolyp: 'vocal_fold_polyp',
    vocalFoldScar: 'vocal_fold_scar',
    voiceComplaintNoConfirmedDiagnosis: 'voice_complaint_no_confirmed_diagnosis',

    // Other variables
    standardizationOfDemographicData: 'standardization_of_demographic_data',
    normalizationTo70dB:'normalization_to_70dB'
};
