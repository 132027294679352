// CAPEVRCitation.jsx
// TODO actualitzar cita

import React from 'react';
import { useTranslation } from 'react-i18next';

function CAPEVRCitation() {
    const { t } = useTranslation();

  return (
    <div className="cites">
          <div>
          Kempster, Nagle & Solomon (2025). Development and Rationale for the Consensus Auditory-Perceptual Evaluation of Voice – Revised (CAPE-Vr). <i>Journal of Voice</i><br />. {t('reprinted_with_permission')}.<br /><br />
          </div>
      </div>
  );
}

export default CAPEVRCitation;