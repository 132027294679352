// csvUtils.js

/**
 * Converteix les dades en format CSV i permet la descàrrega d'un fitxer .csv.
 * @param {Array} data - Array d'objectes amb les dades.
 * @param {string} fileName - Nom del fitxer CSV.
 */
export const downloadCSV = (data, fileName = 'export.csv') => {
    if (!data || data.length === 0) {
        console.warn('No hi ha dades per exportar.');
        return;
    }

    const headers = Object.keys(data[0]);
    const csvContent = [
        headers.join(','), // Capçalera
        ...data.map(row =>
            headers.map(field => `"${row[field] ?? ''}"`).join(',')
        )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
