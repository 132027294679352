// WVDEvent.jsx

import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import { useAuth } from './../../auth/authContext';
import LockIcon from './../../components/icons/LockIcon';
import WVDConsentModal from "./wvd-event-files/WVDConsentModal";
import Counter from './wvd-event-files/Counter';
import RankingsTable from './wvd-event-files/RankingsTable';
import WorldMap from './wvd-event-files/WorldMap';
import Legend from './wvd-event-files/Legend';

function WVDEvent() {
  const { t } = useTranslation();
  const { user, setUser } = useAuth(); // assumeix que el context proporciona setUser
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  // Funció per calcular les dates d'inici i final de l'esdeveniment.
  const getEventTimes = () => {
    const now = new Date();
    let currentYear = now.getFullYear();
    let eventStart = new Date(Date.UTC(currentYear, 3, 15, 10, 0, 0)); 
    let eventEnd = new Date(eventStart.getTime() + 50 * 60 * 60 * 1000);  // 50 hores més tard

    // Si l'esdeveniment ha finalitzat, recalcula per al proper esdeveniment
    if (now > eventEnd) {
      eventStart = new Date(Date.UTC(currentYear + 1, 3, 15, 10, 0, 0)); 
      eventEnd = new Date(eventStart.getTime() + 50 * 60 * 60 * 1000);
    }
    return { eventStart, eventEnd };
  };

  const { eventStart, eventEnd } = useMemo(() => getEventTimes(), []);

  // Estat per al temps actual, avaluacions i dades de país
  const [currentTime, setCurrentTime] = useState(new Date());
  const isEventActive = currentTime >= eventStart && currentTime < eventEnd;
  const [assessmentCount, setAssessmentCount] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [isConsentModalOpen, setConsentModalOpen] = useState(false);

  // Actualitza currentTime cada segon
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Polling a l'API: durant l'esdeveniment es fa polling cada 5 segons;
  // un cop finalitzat, es fa una consulta única per obtenir el snapshot.
  useEffect(() => {
    let interval;
    const pollAssessments = async () => {
      try {
        const response = await fetch(`${apiUrl}/voice-assessments/wvd-count`);
        const data = await response.json();
        setAssessmentCount(data.total);
        setCountryData(data.byCountry);
      } catch (error) {
        console.error('Error al obtenir les avaluacions:', error);
      }
    };

    if (currentTime <= eventEnd) {
      // Durant l'esdeveniment, polling cada 5 segons.
      pollAssessments();
      interval = setInterval(pollAssessments, 5000);
    } else {
      // Un cop finalitzat, obtenim el snapshot una vegada.
      pollAssessments();
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentTime, eventEnd, apiUrl]);

  // Defineix la data del pròxim esdeveniment (per exemple, l'any vinent)
  const nextEventStart = new Date(Date.UTC(eventStart.getFullYear() + 1, 3, 15, 10, 0, 0));

  // Funció per actualitzar el consentiment de l'usuari
  const updateUserConsent = async () => {
    if (!user || !user._id || !user.token) {
      console.error("User object is missing required properties.");
      return;
    }
    try {
      const response = await axios.put(
        `${apiUrl}/users/${user._id}/update-consent`,
        { acceptResearchUsage: true },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      if (response.status === 200) {
        // Actualitza l'estat de l'usuari en el context
        setUser({ ...user, acceptResearchUsage: true });
      } else {
        console.error("Error updating consent");
      }
    } catch (error) {
      console.error("Error updating consent", error);
    }
  };

  // Funció per gestionar el clic al botó "Participate"
  const handleParticipateClick = () => {
    if (!user) {
      // Si no està autenticat, cal obrir la finestra de login
      console.warn("Usuari no autenticat");
      // Aquí podries cridar a una funció openLoginModal(), per exemple.
    } else if (user && !user.acceptResearchUsage) {
      // Si l'usuari no ha donat consentiment, obre el modal
      setConsentModalOpen(true);
    } else {
      // Si tot és correcte, obre la pàgina de participació en una nova pestanya
      window.open('/wvd-participate', '_blank');
    }
  };

  // Funció per tancar el modal
  const closeConsentModal = () => {
    setConsentModalOpen(false);
  };

  // Funció per confirmar el consentiment: actualitza el perfil i obre la pàgina de participació
  const handleConsentConfirm = async () => {
    await updateUserConsent();
    setConsentModalOpen(false);
    window.open('/wvd-participate', '_blank');
  };

  return (
    <div className="main-content">
      <h1 style={{ color: '#ac1e1a' }}>{t('global_perceptual_voice_assessment_marathon')}</h1>
      <Trans i18nKey="wvd_event_introduction"></Trans>
      <img src="/images/wvd-date.png" alt="WVD Icon" style={{ maxWidth: '300px' }} />

      {/* Comptadors encapsulats en el component Counter */}
      <Counter 
        currentTime={currentTime} 
        eventStart={eventStart} 
        eventEnd={eventEnd} 
        nextEventStart={nextEventStart} 
      />

      {/* Botó Participa */}
      <div className="button-container" style={{ textAlign: 'center', margin: '16px 0' }}>
        <button 
          className="button-participate" 
          onClick={handleParticipateClick}
          disabled={!isEventActive}
          style={{ padding: '12px 24px', fontSize: '16px', cursor: isEventActive ? 'pointer' : 'not-allowed', opacity: isEventActive ? 1 : 0.5 }}
        >
          {t('participate')}
          <span className="restricted-marker">
            {/* Mostrar el candau si NO està actiu per motiu d’hora o consentiment */}
            <LockIcon isRestricted={!isEventActive || (user && !user.acceptResearchUsage)} />
          </span>
        </button>
      </div>

      {/* Secció que queda apagada fins a l’inici de l’esdeveniment */}
      <div
        style={{
          opacity: isEventActive ? 1 : 0.4,
          filter: isEventActive ? 'none' : 'grayscale(60%)',
          transition: 'opacity 0.3s, filter 0.3s'
        }}
      >
        <h2>{t('assessment_count')}</h2>
        <div className="assessments" style={{ textAlign: 'center', margin: '0 0' }}>
          <h1 style={{ fontSize: '60px', color: '#d32919', margin: 0 }}>
            {assessmentCount}
          </h1>
        </div>
        <br/>
        <h2>{t('country_ranking')}</h2>
        <RankingsTable data={countryData} />
        <br/>
        <h2>{t('assessment_map')}</h2>
        <WorldMap data={countryData} />
        <Legend />
      </div>

      {/* Consent Modal */}
      <WVDConsentModal 
        isOpen={isConsentModalOpen}
        onRequestClose={closeConsentModal}
        informedConsentLink="/global-database-informed-consent"
        participantInformationLink="/global-database-participant-information-sheet"
        onConfirm={handleConsentConfirm}
      />
    </div>
  );
}

export default WVDEvent;