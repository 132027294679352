// useSessionData.js

import { useState, useEffect } from 'react';
import axios from 'axios';

// Funció per adaptar els resultats de la sessió al format que esperen els components de feedback
const adaptSessionData = (data) => {
  if (!data) return null;
  
  // Si hi ha resultats, els transformem
  const adaptedResults = data.results?.map(result => ({
    submittedData: {
      // Si result.perceptualData té la propietat 'perceptualData', la usem; sinó, usem result.perceptualData directament
      perceptualData: result.perceptualData?.perceptualData || result.perceptualData || {},
      // Afegim el voiceSampleId a partir de la mostra seleccionada de la sessió
      voiceSampleId: data.selectedSample?._id || null,
    },
    participantName: result.participantName,
    userId: result.userId,
  })) || [];

  return {
    ...data,
    results: adaptedResults,
  };
};

export function useSessionData(apiUrl, sessionCode) {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!sessionCode) {
      setLoading(false);
      return;
    }

    const fetchSessionData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/interactive-sessions/${sessionCode}`);
        // Adaptem les dades abans de guardar-les
        const adaptedData = adaptSessionData(response.data);
        setSessionData(adaptedData);
      } catch (err) {
        console.error("Error fetching session data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSessionData();
  }, [apiUrl, sessionCode]);

  return { sessionData, loading, error, setSessionData };
}