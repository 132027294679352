import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import countryOptions from "../../../constants/countryOptions";

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

// Construeix un mapping de nom de país a codi ISO.
// Les claus són els noms dels països en minúscules.
const nameToIso = {};
countryOptions.forEach(option => {
  nameToIso[option.label.toLowerCase()] = option.value;
});

const WorldMap = ({ data = [] }) => {
  // Construir un mapping de codi ISO a nombre d’avaluacions
  const dataMap = {};
  data.forEach(d => {
    dataMap[d._id.toUpperCase()] = d.count;
  });

  // Funció per obtenir el color en funció del nombre d’avaluacions:
  // Si hi ha avaluacions, es mostra verd; si no, gris.
  const getColor = (count) => {
    if (count <= 0) return "#EEE"; // Cap avaluació: color per defecte
    if (count >= 1 && count <= 10) return "#d2e9e6";
    if (count >= 11 && count <= 50) return "#a5d3ce";
    if (count >= 51 && count <= 200) return "#79beb5";
    if (count >= 201 && count <= 500) return "#4ca89d";
    // Més de 500 avaluacions
    return "#209385";
  };

  return (
    <ComposableMap projectionConfig={{ scale: 150 }} width={800} height={400}>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => {
            const countryName = geo.properties.name;
            // Converteix el nom a minúscules i busca el codi ISO corresponent
            const isoCode = nameToIso[countryName.toLowerCase()];
            // Si s'ha trobat el codi, utilitza'l per obtenir el compte; altrament, 0.
            const count = isoCode ? dataMap[isoCode] || 0 : 0;
            
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={getColor(count)}
                stroke="#FFF"
                style={{
                  default: { outline: "none" },
                  hover: { fill: getColor(count), outline: "none" },
                  pressed: { outline: "none" }
                }}
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
};

export default WorldMap;
