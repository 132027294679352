// ResourcesForResearch.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../../locales/i18n';
import { useAuth } from '../../auth/authContext';
import NavigationIcons from './../app-views/NavigationIcons';

function ConceptualResources() {
    const { t } = useTranslation();
    const { user } = useAuth(); 
    const navigate = useNavigate();

    const renderLockIcon = (isRestricted) => (
        isRestricted ? (
            <i className="fas fa-lock" style={{ color: user ? '#a5d3ce' : 'red' }}></i>
        ) : null
    );

    return (
        <div className="main-content">
            <h1>{t('conceptual_resources')}</h1>
            <p>{t('lorem_ipsum')}</p>

            <p className="centered-text">{t('categorize_relevant_voice_qualities')}:</p>
            <button className="button-link" onClick={() => navigate('/perceptual-attributes-categorization')}>
                {t('voice_qualities_categorization')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/>

            <p className="centered-text">{t('general_voice_concepts_categorization')}:</p>
            <button className="button-link" onClick={() => navigate('/general-voice-concepts-categorization')}>
                {t('general_voice_concepts_categorization')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/>

            <p className="centered-text">{t('collaborate_with_allvoiced_to_advance_vocal_science')}:</p>
            <button className="navigation-button" onClick={() => navigate('/open-research-hub')}>
                {t('open_research_hub')}
            </button><br/>

            <div className="navigation-container">
                <NavigationIcons exclude={['/resources-for-research']} />
            </div>
        </div>
    );
}

export default ConceptualResources;