// CreateSessionParent.jsx

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import SessionCreator from './create-session-files/SessionCreator';
import SessionControlView from './create-session-files/SessionControlView';
import { useSessionData } from './../../../hooks/useSessionData'; 


const CreateSessionParent = () => {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';
  const [sessionCode, setSessionCode] = useState(null);
  const [sampleDatabase, setSampleDatabase] = useState(null); 
  const { sessionData, loading, error } = useSessionData(apiUrl, sessionCode);

  // Aquesta funció s'executa quan SessionCreator crea la sessió
  const handleSessionCreated = (data) => {
    console.log("Sessió creada amb dades:", data);
    setSessionCode(data.code); 
  };  

  // Funció per refrescar la sessió i obtenir les últimes dades
  const handleRefresh = () => {
    if (!sessionCode) return;
    setSessionCode(null); 
    setTimeout(() => setSessionCode(sessionData?.code), 0); 
  };  

  const handleCreateAnotherSession = () => {
    setSessionCode(null);
  };

  // Aquesta funció s'executa quan es canvia la base de dades (des de SampleManager)
  const handleDatabaseChange = (db) => {
    setSampleDatabase(db);
  };

  return (
    <div>
      <h1 className = "text-green">{t('create_a_session')}</h1>
      {!sessionCode ? (
        <SessionCreator 
          apiUrl={apiUrl} 
          onSessionCreated={handleSessionCreated} 
          onDatabaseChange={handleDatabaseChange} 
        />
      ) : loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error_loading_session')}</p>
      ) : (
        <SessionControlView 
          apiUrl={apiUrl} 
          sessionData={sessionData} 
          onRefresh={handleRefresh}
          onCreateAnotherSession={handleCreateAnotherSession}
          sampleDatabase={sampleDatabase} 
        />
      )}
    </div>
  );
};

export default CreateSessionParent;