// RBSFeedback.jsx

import React from 'react';
import VASFeedbackGraph from '../../components/graphs/VASFeedbackGraph';

const RBSFeedback = ({ feedbackData, formData, t }) => {
  const userRatings = {
      roughness: formData ? formData.roughness : undefined,
      breathiness: formData ? formData.breathiness : undefined,
      strain: formData ? formData.strain : undefined,
  };

  const attributes = [t('roughness'), t('breathiness'), t('strain')];

  const attributeMapping = {
      [t('roughness')]: 'roughness',
      [t('breathiness')]: 'breathiness',
      [t('strain')]: 'strain',
  };

  return (
      <div className="feedback-container">
          <h2>{t('feedback')}</h2>
      {feedbackData && feedbackData.map((sample, index) => (
        <div key={index} className="feedback-item">
        <p className="small">{t('subject')}: {sample.participantId}</p>
        <p className="small">{t('age')}: {sample.age}</p>
        <p className="small">{t('gender')}: {sample.gender ? t(sample.gender) : t('not_available')}</p> 
        <p className="small">{t('diagnose')}: {sample.diagnose ? t(sample.diagnose) : t('not_available')}</p> 
        <p className="small">{t('language')}: {sample.language ? t(sample.language) : t('not_available')}</p>
        <p className="small">{t('database')}: {sample.database}</p>
        </div>
      ))}
    {attributes.map(attributeName => {
    const dataKey = attributeMapping[attributeName];
    return (
        <VASFeedbackGraph
            key={attributeName}
            attributeName={attributeName}
            attributeData={{
                pastRatings: feedbackData.map(sample => sample[dataKey]).flat().filter(rating => rating !== undefined),
                userRating: userRatings[dataKey],
                sharedRatings: feedbackData.map(sample => sample[`shared_${dataKey}_ratings`]).flat().filter(rating => rating !== undefined),
                        }}
                    />
                );
            })}
        </div>
    );
};

export default RBSFeedback;