// SampleSelector.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

const SampleSelector = ({ value, onChange }) => {
    const { t } = useTranslation();

    return (
        <div >
            <select
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="select"
            >
                <option value="">{t('pick_a_sample_selection_method')}</option>
                <option value="random">{t('get_a_random_sample')}</option>
                <option value="manual">{t('select_a_sample_yourself')}</option>
            </select>
        </div>
    );
};

export default SampleSelector;