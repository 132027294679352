// AppRoutes.jsx

// UTILITIES
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import ScrollToTop from '../utils/ScrollToTop';

/////////////////
///// OPEN //////
/////////////////

// REGISTRATION AND PROFILE
import ConfirmationPage from '../auth/account/ConfirmationPage';
import EmailVerification from '../auth/account/EmailVerification';
import PasswordResetComponent from '../auth/account/PasswordResetComponent';
import LoginComponent from '../auth/account/LoginComponent';

// OPEN APP VIEWS
import Header from '../views/app-views/Header';
import Landing from '../views/app-views/Landing';
import Footer from '../views/app-views/Footer';

import LegalNotice from '../views/open-access/LegalNotice';
import PrivacyPolicy from '../views/open-access/PrivacyPolicy';

import ResearchAtAllVoiced from '../views/open-access/ResearchAtAllVoiced';
import AboutUs from '../views/open-access/AboutUs';
import WhoIsTalking from '../views/open-access/WhoIsTalking';
import HowToCite from '../views/open-access/HowToCite';

import ResourcesForTraining from '../views/open-access/ResourcesForTraining';
import ResourcesForTeaching from '../views/open-access/ResourcesForTeaching';
import ResourcesForResearch from '../views/open-access/ResourcesForResearch';
import AllTheResources from '../views/open-access/AllTheResources';

// OPEN RESOURCES
import RBSTraining from '../views/RBSTraining/RBSTraining';
import OSTraining from '../views/OSTraining/OSTraining';
import OSClassroom from '../views/OSTraining/OSClassroom';
import CAPEVClassroom from '../views/cape-v-old/CAPEVClassroom';
import CAPEVScholar from '../views/cape-v-old/CAPEVScholar';
import CAPEVWorkshop from '../views/cape-v-old/CAPEVWorkshop';
import CAPEVWorkshopStudent from '../views/cape-v-old/cape-v-workshop/cape-v-workshop-students-files/CAPEVWorkshopStudent'; 

// OPEN RESEARCH PROJECTS COMPONENTS
import GlobalDatabasePIS from '../views/open-research-hub/participant-information-sheets/GlobalDatabasePIS';
import GlobalDatabaseIC from '../views/open-research-hub/informed-consents/GlobalDatabaseIC';

//////////////////////
/// EVENT-SPECIFIC ///
//////////////////////

import ASHAConventionAnchors from '../views/experiments/anchors/ASHAConventionAnchors';
import JoinAnchors from '../views/experiments/anchors/JoinAnchors';
import TallerCAPEVWorkshopStudent from '../views/cape-v-old/cape-v-workshop/cape-v-workshop-students-files/TallerCAPEVWorkshopStudent';

////////////////////////
/// PROTECTED ROUTES ///
////////////////////////

// STANDARD_USERS 
// Profile
import UserProfile from '../auth/account/UserProfile';

// Resources
import CAPEVTraining from '../views/cape-v-old/CAPEVTraining';
import GuessTheDiagnosis from '../views/diagnosis/GuessTheDiagnosis';
import CAPEVWorkshopInstructor from '../views/cape-v-old/cape-v-workshop/cape-v-workshop-instructor-files/CAPEVWorkshopInstructor'; 

// STUDENTS
import StudentDashboard from '../views/authenticated-routes/StudentDashboard'; 

//////////////////
///// ADMIN //////
//////////////////

// PRIVATE USE
import TallerCAPEVWorkshopInstructor from '../views/cape-v-old/cape-v-workshop/cape-v-workshop-instructor-files/TallerCAPEVWorkshopInstructor';

// CURRENT DEVELOPMENT
import AdminDashboard from '../views/authenticated-routes/AdminDashboard';
import WVDEvent from '../views/wvd-event/WVDEvent';
import WVDParticipate from '../views/wvd-event/WVDParticipate';

import ResourcesRepository from '../views/open-access/ResourcesRepository';
import OpenResearchHub from '../views/open-research-hub/OpenResearchHub';
import JoinAStudy from '../views/open-access/JoinAStudy';
import CustomResearchSolutions from '../views/custom-research-solutions/CustomResearchSolutions';

import AuditoryPerceptualTools from '../views/open-access/AuditoryPerceptualTools';
import AuditoryPerceptualGames from '../views/open-access/AuditoryPerceptualGames';
import ResourceBuilder from '../views/resource-builder/ResourceBuilder';

import OsParent from '../views/os/OsParent';
import CAPEVParent from '../views/cape-v/CAPEVParent';
import CAPEVProtocol from '../views/cape-v/cape-v-views/CAPEVProtocol';
import CAPEVRParent from '../views/cape-vr/CAPEVRParent';
import CAPEVRProtocol from '../views/cape-vr/cape-vr-views/CAPEVRProtocol';
import ConceptualResources from '../views/open-access/ConceptualResources';

import InteractiveSessions from '../views/sessions/InteractiveSessions';

import PerceptualAttributesCategorization from '../views/drag-and-drop/PerceptualAttributesCategorization';
import PerceptualAttributesHierarchy from '../views/drag-and-drop/PerceptualAttributesHierarchy';
import GeneralVoiceConceptsCategorization from '../views/drag-and-drop/GeneralVoiceConceptsCategorization';
import GeneralVoiceConceptsHierarchy from '../views/drag-and-drop/GeneralVoiceConceptsHierarchy';

import CAPEVRAdaptationPIS from '../views/open-research-hub/participant-information-sheets/CAPEVRAdaptationPIS';
import CAPEVRAdaptationIC from '../views/open-research-hub/informed-consents/CAPEVRAdaptationIC';
import PerceptualAttributesArtisticPIS from '../views/open-research-hub/participant-information-sheets/PerceptualAttributesArtisticPIS';
import PerceptualAttributesArtisticIC from '../views/open-research-hub/informed-consents/PerceptualAttributesArtisticIC';

// FUTURE DEVELOPMENT
import ResearchMethodologyGrid from '../views/methodology/ResearchMethodologyGrid';
import ConceptManager from '../views/tree/ConceptManager';
import EARV1 from '../views/ear-v1/EARV';
import EARV from '../views/ear-v/EARV';

// WHO KNOWS
import CapeVEntrenament from '../views/cape-v-old/CapeVEntrenament';
import CapeVEntrenamiento from '../views/cape-v-old/CapeVEntrenamiento';
import CAPEVClinic from '../views/cape-v-old/CAPEVClinic';

import ConceptsFreeSortingTask from '../views/free-sorting-task/ConceptsFreeSortingTask';
import Anchors from '../views/experiments/anchors/Anchors';
import VASexperiment from '../views/experiments/vas-experiment/VASexperiment';
import RandomVASexperiment from '../views/experiments/random-vas-experiment/RandomVASexperiment';
import FreeSortingTask from '../views/experiments/free-sorting-task/FreeSortingTask';
import CollectionsFreeSortingTask from '../views/experiments/collections-free-sorting-task/CollectionsFreeSortingTask';
import TwoAFCexperiment from '../views/experiments/2AFC-experiment/TwoAFCexperiment';
import Training from '../views/training-in-three-phases/Training';

const AppRoutes = () => {
  return (
    <>
      <Header />
      <div className="flex-container">
        <div className="main-content">
          <div className="content-area">
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Landing />} />

                {/* //// */}
                {/* OPEN */}
                {/* //// */}

                {/* REGISTRATION AND PROFILE */}

                <Route path="/confirmation" element={<ConfirmationPage />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                <Route path="/password-reset/:token" element={<PasswordResetComponent />} />
                <Route path="/login" element={<LoginComponent />} />

                {/* OPEN APP VIEWS */}

                <Route path="/legal-notice" element={<LegalNotice />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route path="/research-at-all-voiced" element={<ResearchAtAllVoiced />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/citations" element={<WhoIsTalking />} />
                <Route path="/how-to-cite" element={<HowToCite />} />

                <Route path="/resources-for-autonomous-training" element={<ResourcesForTraining />} />
                <Route path="/resources-for-teaching-and-learning" element={<ResourcesForTeaching />} />
                <Route path="/resources-for-research" element={<ResourcesForResearch />} />
                <Route path="/all-the-resources" element={<AllTheResources />} />

                {/* OPEN RESOURCES */}

                <Route path="/rbs-training" element={<RBSTraining />} />
                <Route path="/os-training" element={<OSTraining />} />
                <Route path="/os-classroom" element={<OSClassroom />} />
                <Route path="/cape-v-classroom" element={<CAPEVClassroom />} />
                <Route path="/cape-v-scholar" element={<CAPEVScholar />} />
                <Route path="/cape-v-workshop" element={<CAPEVWorkshop />} />
                <Route path="/cape-v-workshop-student" element={<CAPEVWorkshopStudent />} />

                {/* OPEN RESEARCH HUB */}
                <Route path="/open-research-hub" element={<OpenResearchHub />} />
                <Route path="/join-a-study" element={<JoinAStudy />} />
                <Route path="/global-database-participant-information-sheet" element={<GlobalDatabasePIS />} />
                <Route path="/global-database-informed-consent" element={<GlobalDatabaseIC />} />
                <Route path="/cape-vr-adaptation-participant-information-sheet" element={<CAPEVRAdaptationPIS />} />
                <Route path="/cape-vr-adaptation-informed-consent" element={<CAPEVRAdaptationIC />} />

                {/* WORLS VOICE DAY EVENT */}
                <Route path="/wvd-event" element={<WVDEvent />} />
                <Route path="/wvd-participate" element={<WVDParticipate />} />

                {/* ////////////// */}
                {/* EVENT-SPECIFIC */}
                {/* ////////////// */}

                <Route path="/2024-asha-convention-anchors" element={<ASHAConventionAnchors />} />
                <Route path="/join-anchors" element={<JoinAnchors />} />
                <Route path="/taller" element={<TallerCAPEVWorkshopStudent />} />

                {/* //////////////// */}
                {/* PROTECTED ROUTES */}
                {/* //////////////// */}

                {/* STANDARD_USERS */}

                {/* Profile */}
                <Route path="/profile" element={<ProtectedRoute allowedRoles={['admin', 'standard_user']}><UserProfile /></ProtectedRoute>} />

                {/* Resources */}
                <Route path="/cape-v-training" element={<ProtectedRoute allowedRoles={['admin', 'standard_user', 'student' ]}><CAPEVTraining /></ProtectedRoute>} />
                <Route path="/guess-the-diagnosis" element={<ProtectedRoute allowedRoles={['admin', 'standard_user', 'student' ]}><GuessTheDiagnosis /></ProtectedRoute>} />
                <Route path="/cape-v-workshop-instructor" element={<ProtectedRoute allowedRoles={['admin', 'standard_user']}><CAPEVWorkshopInstructor /></ProtectedRoute>} />

                {/* STANDARD_USERS */}
                <Route path="/student-dashboard" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><StudentDashboard /></ProtectedRoute>} />

                {/* ///// */}
                {/* ADMIN */}
                {/* ///// */}

                {/* PRIVATE USE */}
                <Route path="/cape-v-taller-vista-instructor" element={<ProtectedRoute allowedRoles={['admin']}><TallerCAPEVWorkshopInstructor /></ProtectedRoute>} />

                {/* CURRENT DEVELOPMENT */}
                <Route path="/admin-dashboard" element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} />
                <Route path="/resources" element={<ProtectedRoute allowedRoles={['admin']}><ResourcesRepository /></ProtectedRoute>} />
                <Route path="/custom-research-solutions" element={<ProtectedRoute allowedRoles={['admin']}><CustomResearchSolutions /></ProtectedRoute>} />

                <Route path="/tools" element={<ProtectedRoute allowedRoles={['admin']}><AuditoryPerceptualTools /></ProtectedRoute>} />
                <Route path="/games" element={<ProtectedRoute allowedRoles={['admin']}><AuditoryPerceptualGames /></ProtectedRoute>} />
                <Route path="/resource-builder" element={<ProtectedRoute allowedRoles={['admin']}><ResourceBuilder /></ProtectedRoute>} />

                <Route path="/interactive-sessions" element={<ProtectedRoute allowedRoles={['admin']}><InteractiveSessions /></ProtectedRoute>} />
                
                <Route path="/os" element={<ProtectedRoute allowedRoles={['admin']}><OsParent /></ProtectedRoute>} />
                <Route path="/cape-v" element={<ProtectedRoute allowedRoles={['admin']}><CAPEVParent /></ProtectedRoute>} />
                <Route path="/cape-v-protocol" element={<ProtectedRoute allowedRoles={['admin']}><CAPEVProtocol /></ProtectedRoute>} />
                <Route path="/cape-vr" element={<ProtectedRoute allowedRoles={['admin', 'collaborator']}><CAPEVRParent /></ProtectedRoute>} />
                <Route path="/cape-vr-protocol" element={<ProtectedRoute allowedRoles={['admin', 'collaborator']}><CAPEVRProtocol /></ProtectedRoute>} />
                <Route path="/conceptual-resources" element={<ProtectedRoute allowedRoles={['admin']}><ConceptualResources /></ProtectedRoute>} />

                <Route path="/perceptual-attributes-categorization" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><PerceptualAttributesCategorization /></ProtectedRoute>} />
                <Route path="/perceptual-attributes-hierarchy" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><PerceptualAttributesHierarchy /></ProtectedRoute>} />
                <Route path="/general-voice-concepts-categorization" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><GeneralVoiceConceptsCategorization /></ProtectedRoute>} />
                <Route path="/general-voice-concepts-hierarchy" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><GeneralVoiceConceptsHierarchy /></ProtectedRoute>} />

                <Route path="/perceptual-attributes-categorization-participants-information" element={<ProtectedRoute allowedRoles={['admin']}><PerceptualAttributesArtisticPIS /></ProtectedRoute>} />
                <Route path="/perceptual-attributes-categorization-informed-consent" element={<ProtectedRoute allowedRoles={['admin']}><PerceptualAttributesArtisticIC /></ProtectedRoute>} />
              
                {/* FUTURE DEVELOPMENT */}

                <Route path="/metodology-lab" element={<ProtectedRoute allowedRoles={['admin']}><ResearchMethodologyGrid /></ProtectedRoute>} />
                <Route path="/attributes-conceptualization-tree" element={<ProtectedRoute allowedRoles={['admin']}><ConceptManager /></ProtectedRoute>} />
                <Route path="/ear-v" element={<ProtectedRoute allowedRoles={['admin']}><EARV /></ProtectedRoute>} />
                <Route path="/ear-v1" element={<ProtectedRoute allowedRoles={['admin']}><EARV1 /></ProtectedRoute>} />

                {/* WHO KNOWS */}
                <Route path="/cape-v-entrenament" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><CapeVEntrenament /></ProtectedRoute>} />
                <Route path="/cape-v-entrenamiento" element={<ProtectedRoute allowedRoles={['admin']}><CapeVEntrenamiento /></ProtectedRoute>} />
                <Route path="/cape-v-clinic" element={<ProtectedRoute allowedRoles={['admin']}><CAPEVClinic /></ProtectedRoute>} />

                <Route path="/concepts" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><ConceptsFreeSortingTask /></ProtectedRoute>} />
                <Route path="/anchors" element={<ProtectedRoute allowedRoles={['admin']}><Anchors /></ProtectedRoute>} />
                <Route path="/free-sorting-task" element={<ProtectedRoute allowedRoles={['admin']}><FreeSortingTask /></ProtectedRoute>} />
                <Route path="/collections-free-sorting-task" element={<ProtectedRoute allowedRoles={['admin']}><CollectionsFreeSortingTask /></ProtectedRoute>} />
                <Route path="/2-afc" element={<ProtectedRoute allowedRoles={['admin']}><TwoAFCexperiment /></ProtectedRoute>} />
                <Route path="/vas-experiment" element={<ProtectedRoute allowedRoles={['admin']}><VASexperiment /></ProtectedRoute>} />
                <Route path="/random-vas-experiment" element={<ProtectedRoute allowedRoles={['admin']}><RandomVASexperiment /></ProtectedRoute>} />
                <Route path="/training-in-three-phases" element={<ProtectedRoute allowedRoles={['admin']}><Training /></ProtectedRoute>} />

                </Routes>
            </ScrollToTop>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AppRoutes;