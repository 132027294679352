// CAPEVPreview.jsx
// TODO activar crear sessió aquí? 
// caldrà afegir onStartSession


import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../auth/authContext";
import LoginModal from "../../../components/modals/LoginModal";
import SampleManager from "../../../components/audio/SampleManager";
import LockIcon from "../../../components/icons/LockIcon";

const CAPEVPreview = ({ apiUrl, onSelect, onDatabaseChange, initialSelection }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [selectedSample, setSelectedSample] = useState(null);
    const [selectedOption, setSelectedOption] = useState(initialSelection || null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (selectedOption === "viewForm") {
            setSelectedSample(null);
        }
    }, [selectedOption]);
    

    const handleSampleSelected = (sample) => {
        setSelectedSample(sample);
        setError(false);
    };

    const handleGoToForm = () => {
        if (selectedOption === "chooseSample" && !selectedSample) {
            setError(true);
        } else {
            onSelect(selectedSample);
        }
    };

    /*const handleStartSession = () => {
        if (!selectedSample) {
            setError(true);
        } else if (!user) {
            setIsLoginModalOpen(true);
        } else {
            onStartSession();
        }
    };*/

    return (
        <div className="main-content">
            <p>{t("cape_v_introduction")}</p>
            <a href="/cape-v-protocol" target="_blank" rel="noopener noreferrer" style={{ display: "block", marginBottom: "15px" }}>
                {t("cape_v_procedures")}
            </a>

            {/* 🔹 Opcions inicials amb radio buttons */}
            <div style={{ textAlign: "center" }}>
                <label>
                    <input
                        type="radio"
                        name="previewOption"
                        value="viewForm"
                        checked={selectedOption === "viewForm"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        style={{ marginRight: "8px" }}
                    />
                    {t("view_the_capev_form")}
                </label>
                <label>
                    <input
                        type="radio"
                        name="previewOption"
                        value="chooseSample"
                        checked={selectedOption === "chooseSample"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        style={{ marginRight: "8px" }}
                    />
                    {t("choose_a_sample_to_evaluate")}
                </label>
            </div>

            {/* 🔹 Botó per veure el formulari sense mostra */}
            {selectedOption === "viewForm" && (
                <button onClick={handleGoToForm} className="button-center" style={{ marginTop: "20px" }}>
                    {t("cape_v_form")}
                </button>
            )}

            {/* 🔹 Gestió de selecció de mostra */}
            {selectedOption === "chooseSample" && (
                <SampleManager
                    apiUrl={apiUrl}
                    onDatabaseChange={onDatabaseChange} 
                    onSampleSelected={handleSampleSelected}
                    />

            )}

            {error && (
                <p style={{ textAlign: "center", color: "red", marginTop: "10px" }}>
                    {t("error_sample_selection")}
                </p>
            )}

            {/* 🔹 Botons quan la mostra és vàlida */}
            {selectedSample && (
                <>
                    <button
                        onClick={user ? handleGoToForm : () => setIsLoginModalOpen(true)}
                        className="button-center restricted-link"
                    >
                        {t("evaluate_this_sample")}
                        <span className="restricted-marker">
                            <LockIcon isRestricted={true} user={user} />
                        </span>
                    </button>
                    {/* 🔒 Opcions ocultes per a futur ús 
                    <br/>
                    <button onClick={handleStartSession} className="button-link restricted-link">
                        {t("create_an_interactive_session")}
                        <span className="restricted-marker">
                            <LockIcon isRestricted={true} user={user} />
                        </span>
                    </button>*/}
                </>
            )}

            <LoginModal isOpen={isLoginModalOpen} onRequestClose={() => setIsLoginModalOpen(false)} />
        </div>
    );
};

export default CAPEVPreview;