// Feedback.jsx

import React from 'react';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import { useTranslation } from 'react-i18next';
import FeedbackGraph from './FeedbackGraph';

const Feedback = ({ feedbackData, sliderValues, evaluatedAttribute }) => {
    const { playAudio } = useAudioPlayer();
    const { t } = useTranslation();
  
    console.log('Feedback Data:', feedbackData);
    console.log('Slider Values:', sliderValues);
  
    return (
      <div className="feedback-container">
        <h1>{evaluatedAttribute}</h1>
        <h2>Feedback</h2>
        <p>
        {t('lorem_ipsum')}
        <br /><br />App en construcción. Cambia el idioma al inglés para ver correctamente el feedback. 
       </p>
      {feedbackData.map((sample, index) => {
        // Filtra els sliderValues per aquells que corresponguin a aquesta mostra específica
        // Suposem que estem filtrant per 'sampleName', però ajusta aquesta part segons la teva estructura de dades
        const filteredSliderValues = sliderValues.filter(sliderValue => sliderValue.sampleName === sample.sampleName);

        return (
          <div key={index}>
            <p class="small">Collection ID: {sample.collectionId}</p>
            <p class="small">Sample: {sample.sampleName}</p>
            <p class="small">Subject Age: {sample.subjectAge}</p>
            <p class="small">Subject Gender: {sample.subjectGender}</p>
            <p class="small">ENT Diagnose: {sample.entDiagnose}</p>
            <button onClick={() => playAudio(sample.sampleLink)}>Play</button>
            <div className="feedback-graph-container">
              <FeedbackGraph
                feedbackData={[sample]} // Pass individual sample as an array
                sliderValues={filteredSliderValues}
                evaluatedAttribute={evaluatedAttribute}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Feedback;