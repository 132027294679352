// AdditionalFeatures.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './../../../locales/i18n';

function AdditionalFeatures({ value, onChange }) {
  const { t } = useTranslation();
  const [features, setFeatures] = useState(value.features || {});
  const [additionalFeature, setAdditionalFeature] = useState(value.text || '');

  useEffect(() => {
    setFeatures(value.features || {});
    setAdditionalFeature(value.text || '');
  }, [value]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const newFeatures = { ...features };
    if (checked) {
      newFeatures[name] = newFeatures[name] || { value: 0, variability: 'consistent' };
    } else {
      delete newFeatures[name];
    }
    setFeatures(newFeatures);
    onChange({ features: newFeatures, text: additionalFeature });
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setAdditionalFeature(newText);
    onChange({ features: { ...features, other: { ...features.other, label: newText } }, text: newText });
  };

  const diplophonia = 'diplophonia';
  const fry = 'fry';
  const falsetto = 'falsetto';
  const asthenia = 'asthenia';
  const aphonia = 'aphonia';
  const pitchInstability = 'pitch_instability';
  const tremor = 'tremor';
  const wetGurgly = 'wet_gurgly';
  const otherFeature = 'other';  // Manté aquesta clau com a 'other'

  const featureList = i18n.language === 'jp'
    ? [diplophonia, fry, falsetto, asthenia, aphonia, pitchInstability, tremor, otherFeature]
    : [diplophonia, fry, falsetto, asthenia, aphonia, pitchInstability, tremor, wetGurgly, otherFeature];

  return (
    <div className="button-container">
      <label className="centered-text"><strong>{t('additional_features')}</strong></label>
      <div>
        {featureList.map((feature) => (
          <label key={feature} className="horizontal-labels">
            <input
              type="checkbox"
              name={feature}
              checked={features.hasOwnProperty(feature)}
              onChange={handleCheckboxChange}
            />
            {t(feature)}
          </label>
        ))}
      </div>
      {features.hasOwnProperty('other') && (
        <textarea
          value={additionalFeature}
          onChange={handleTextChange}
          rows="4"
          cols="50"
          placeholder={t('specify')}
        />
      )}
    </div>
  );
}

export default AdditionalFeatures;