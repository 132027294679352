// Counter.jsx

import React from 'react';
import Countdown from './Countdown';
import CountUp from './CountUp';
import { useTranslation } from 'react-i18next';

const Counter = ({ currentTime, eventStart, eventEnd }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Abans de l'esdeveniment */}
      {currentTime < eventStart && (
        <div className="countdown" style={{ textAlign: 'center', margin: '0' }}>
          <h2>{t('event_starts_in', { year: eventStart.getFullYear() })}</h2>
          <Countdown targetDate={eventStart} />
        </div>
      )}

      {/* Durant l'esdeveniment */}
      {currentTime >= eventStart && currentTime < eventEnd && (
        <>
          <div className="countdown" style={{ textAlign: 'center', margin: '16px 0' }}>
            <h1>{t('event_started', { year: eventStart.getFullYear() })}</h1>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginBottom: '16px' }}>
            <div className="elapsed" style={{ width: '50%', textAlign: 'center' }}>
              <h3>{t('time_elapsed')}</h3>
              <CountUp startDate={eventStart} />
            </div>
            <div className="remaining" style={{ width: '50%', textAlign: 'center' }}>
              <h3>{t('time_remaining')}</h3>
              <Countdown targetDate={eventEnd} />
            </div>
          </div>
        </>
      )}

      {/* Després de l'esdeveniment */}
      {currentTime >= eventEnd && (
        <div className="countdown" style={{ textAlign: 'center', margin: '16px 0' }}>
          <h1>{t('final_event_count', { year: eventStart.getFullYear() })}</h1>
        </div>
      )}
    </>
  );
};

export default Counter;