import React, { useState, useEffect, useCallback } from 'react';

function CountUp({ startDate }) {
  const calculateElapsed = useCallback(() => {
    const now = new Date();
    const diff = now - startDate;
    if (diff <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    return {
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
      seconds: Math.floor((diff / 1000) % 60)
    };
  }, [startDate]);

  const [elapsed, setElapsed] = useState(calculateElapsed());

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsed(calculateElapsed());
    }, 1000);
    return () => clearInterval(timer);
  }, [calculateElapsed]);

  return (
    <div>
      <span>
        {elapsed.days}d {elapsed.hours}h {elapsed.minutes}m {elapsed.seconds}s
      </span>
    </div>
  );
}

export default CountUp;