// GuessTheDiagnosis.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSampleInitializer from './useSampleInitializer';
import useAudioPlayerWithStop from '../../hooks/useAudioPlayerWithStop';
import diagnosisDataOriginalPVQD from '../../constants/feedback-data/diagnosisDataOriginalPVQD';
import NavigationIcons from './../../views/app-views/NavigationIcons';
import WaldenCitation from './../../components/citations/WaldenCitation';

function GuessTheDiagnosis() {
    const { t, i18n } = useTranslation();
    const [sampleCount, setSampleCount] = useState(2); // Default to 2 samples
    const { samples, diagnoses, initializeSamples } = useSampleInitializer(diagnosisDataOriginalPVQD, sampleCount);
    const [guesses, setGuesses] = useState({});
    const [results, setResults] = useState({});
    const [currentSample, setCurrentSample] = useState(null);
    const { playAudio, stopAudio } = useAudioPlayerWithStop();

    useEffect(() => {
        initializeSamples();
    }, [initializeSamples]);

    const handleSampleCountChange = (event) => {
        setSampleCount(Number(event.target.value));
    };

    const handlePlaySample = (sample) => {
        setCurrentSample(sample);
        playAudio(sample.sampleLink);
    };

    const handleStopSample = () => {
        setCurrentSample(null);
        stopAudio();
    };

    const handleSelectDiagnosis = (participantId, diagnosis) => {
        setGuesses(prev => ({ ...prev, [participantId]: diagnosis }));
        if (results[participantId] !== undefined) {
            setResults(prev => ({ ...prev, [participantId]: undefined }));
        }
    };

    const checkAnswers = () => {
        const newResults = {};
        samples.forEach(sample => {
            newResults[sample.participantId] = guesses[sample.participantId] === sample.diagnose ? 'correct' : 'incorrect';
        });
        setResults(newResults);
    };

    const handleReset = () => {
        stopAudio(); // Stop the audio
        setCurrentSample(null); // Clear the current sample
        initializeSamples(); // Reinitialize the samples
    };

    return (
        <div>
            <h1>{t('guess_the_diagnosis_title')}</h1>
            <p>{t('guess_the_diagnosis_instructions')}</p>
            {i18n.language !== 'en' && <p>{t('note_language')}</p>}

            <div className="sample-selector">
                <label>{t('select_the_number_of_samples')}: </label>
                <select onChange={handleSampleCountChange} value={sampleCount}>
                    <option value="2">2 {t('samples')}</option>
                    <option value="3">3 {t('samples')}</option>
                    <option value="4">4 {t('samples')}</option>
                    <option value="5">5 {t('samples')}</option>
                </select>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        {diagnoses.map((diagnosis, index) => <th key={index}>{t(diagnosis)}</th>)} 
                    </tr>
                </thead>
                <tbody>
                    {samples.map((sample) => (
                        <tr key={sample.participantId}>
                            <td>
                                {currentSample === sample ? (
                                    <button className='play-button' onClick={handleStopSample}>Stop</button>
                                ) : (
                                    <button className='play-button' onClick={() => handlePlaySample(sample)}>Play</button>
                                )}
                            </td>
                            {diagnoses.map((diagnosis, index) => (
                                <td key={`${sample.participantId}-${index}`}>
                                    <button
                                        className={`button ${results[sample.participantId] === 'correct' && guesses[sample.participantId] === diagnosis ? 'correct' : results[sample.participantId] === 'incorrect' && guesses[sample.participantId] === diagnosis ? 'incorrect' : ''} ${guesses[sample.participantId] === diagnosis ? 'selected' : ''}`}
                                        onClick={() => handleSelectDiagnosis(sample.participantId, diagnosis)}
                                    >
                                        X
                                    </button>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <br/>
            <div className="button-container">
                <button onClick={checkAnswers} className='button-center'>{t('check_the_answers')}</button>
                <button onClick={handleReset} className='navigation-button'>{t('restart_with_other_samples')}</button>
            </div>
            <br/>
            <WaldenCitation />
            <div className="navigation-container">
                <NavigationIcons />
            </div>
        </div>
    );
}

export default GuessTheDiagnosis;