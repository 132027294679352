// EvaluationToolsSelector.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from 'axios';

const EvaluationToolSelector = ({ apiUrl, onToolSelected }) => {
  const { t } = useTranslation();
  const [tools, setTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const response = await axios.get(`${apiUrl}/evaluation-tools`);
        // Filtra només les eines actives
        setTools(response.data.data.filter(tool => tool.isActive));
      } catch (err) {
        console.error("Error fetching evaluation tools:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTools();
  }, [apiUrl]);

  const handleChange = (e) => {
    const toolId = e.target.value;
    setSelectedTool(toolId);
    const tool = tools.find(t => t._id === toolId);
    if (onToolSelected) {
      onToolSelected(tool);
    }
  };

  if (loading) return <p>Loading evaluation tools...</p>;
  if (error) return <p>Error loading evaluation tools.</p>;

  return (
    <div className="evaluation-tool-selector">
      <select
        id="evaluation-tool-select"
        value={selectedTool}
        onChange={handleChange}
      >
        <option value="">{t("select_an_evaluation_tool")}</option>
        {tools.map(tool => (
          <option key={tool._id} value={tool._id}>
            {tool.toolName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EvaluationToolSelector;