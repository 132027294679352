// Feedback.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function Feedback({ userHierarchy, otherUsersHierarchy, fetchFeedback, currentPage, totalPages }) {
    const { t } = useTranslation();

// 🔹 Funció per renderitzar una jerarquia en format d'arbre
const renderHierarchy = (nodes, level = 0) => {
    if (!Array.isArray(nodes)) {
      console.warn(`⚠️ 'nodes' esperava un array però ha rebut:`, nodes);
      nodes = nodes.children ? [nodes] : []; // 🔹 Convertim l'objecte en array si té `children`, si no deixem buit
    }
  
    return (
      <ul style={{ listStyleType: 'none', paddingLeft: `${level * 20}px` }}>
        {nodes.map((node, index) => (
          <li key={index} style={{ 
            fontWeight: node.type === 'category' || node.type === 'subcategory' ? 'bold' : 'normal',
            fontStyle: node.type === 'sub-subcategory' ? 'italic' : 'normal',
            color: node.type === 'category' || node.type === 'subcategory' || node.type === 'sub-subcategory' ? '#209385' : 'black',
            marginBottom: '5px'
          }}>
            {t(node.name)}
            {node.children && node.children.length > 0 && renderHierarchy(node.children, level + 1)}
          </li>
        ))}
      </ul>
    );
  };   
  
  return (
    <>
        <div >
            <h2>{t('feedback')}</h2>

            {/* 🔹 Contenidor en dues columnes per comparar jerarquies */}
            <div className="hierarchical-comparison">
                {/* 📌 Jerarquia de l'usuari */}
                <div className="hierarchy-box">
                    <h3 className="hierarchy-title">{t('your_hierarchy')}</h3>
                    {userHierarchy && userHierarchy.length > 0 ? renderHierarchy(userHierarchy) : <p className="hierarchy-title">{t('no_data_available')}</p>}
                </div>

                {/* 📌 Jerarquia d'altres usuaris */}
                <div className="hierarchy-box">
                    <h3 className="hierarchy-title">{t('other_users_hierarchies')}</h3>
                    {otherUsersHierarchy.length > 0 ? (
                        otherUsersHierarchy.map((categorization, index) => {
                            const hierarchyData = Array.isArray(categorization) ? categorization : [categorization];
                            return <div key={index}>{renderHierarchy(hierarchyData)}</div>;
                        })
                    ) : (
                        <p className="centered-text text-red">{t('no_data_available')}</p>
                    )}

                    {/* 🔹 Navegació entre pàgines */}
                    <div className="hierarchy-navigation">
                        <button className="button-center" onClick={() => fetchFeedback(Number(currentPage) - 1)} disabled={currentPage === 1}>
                            {t('previous')}
                        </button>
                        <button className="button-center" onClick={() => fetchFeedback(Number(currentPage) + 1)} disabled={currentPage >= totalPages}>
                            {t('next')}
                        </button>
                        <br />
                        <span>{t('page')} {currentPage} {t('of')} {totalPages}</span>
                    </div>
                </div>
            </div>
        </div>
    </>
);
}

export default Feedback;