// NeurovozCitation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

const NeurovozCitation = () => {
  const { t } = useTranslation();

  return (
    <div className="cites">
      {t('samples_and_data_source')} ({t('with_permission')}):<br />
      arXiv preprint: J. Mendes-Laureano, J. A. Gómez-García, A. Guerrero-López,E. Luque-Buzo, J. D. Arias-Londoño, F. J. Grandas-Pérez, and J. I. Godino-Llorente, “Neurovoz: a castillian spanish corpus of parkinsonian speech,” arXiv preprint arXiv:2403.02371 (2024). Link: <a href="https://arxiv.org/abs/2403.02371" target="_blank" rel="noopener noreferrer">https://arxiv.org/abs/2403.02371</a>.<br/><br/>
      Zenodo dataset: Mendes-Laureano, J., Gómez-García, J. A., Guerrero-López, A., Luque-Buzo, E., Arias-Londoño, J. D., Grandas-Pérez, F. J., & Godino Llorente, J. I. (2024). NeuroVoz: a Castillian Spanish corpus of parkinsonian speech (1.0.0) [Data set]. Zenodo. <a href="https://doi.org/10.5281/zenodo.10777657" target="_blank" rel="noopener noreferrer">https://doi.org/10.5281/zenodo.10777657</a>.<br/>
    </div>
  );
};

export default NeurovozCitation;