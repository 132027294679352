// CAPEVTraining.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CAPEVForm from './cape-v-shared-files/CAPEVForm';
import CAPEVCitation from './../../components/citations/CAPEVCitation';
import CAPEVFeedback from './cape-v-shared-files/CAPEVFeedback';
import VisualizationToggles from './cape-v-shared-files/VisualizationToggles';
import feedbackDataOriginalPVQD from '../../constants/feedback-data/feedbackDataOriginalPVQD';
import axios from 'axios';
import { useAuth } from './../../auth/authContext';
import NavigationIcons from './../app-views/NavigationIcons';
import WaldenCitation from './../../components/citations/WaldenCitation';

function CAPEVTraining() {
    const { t, i18n } = useTranslation();
    const { user } = useAuth(); 

    const [selectedSample, setSelectedSample] = useState(null);
    const [feedbackVisible, setFeedbackVisible] = useState(false);
    const [sharedEvaluations, setSharedEvaluations] = useState([]);
    const [formData, setFormData] = useState(null);

    // Estats per a la visibilitat de les dades
    const [showSociodemographicData, setShowSociodemographicData] = useState(false);
    const [showStandardAttributes, setShowStandardAttributes] = useState(true);
    const [showAdditionalAttributes, setShowAdditionalAttributes] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

    // Selecció aleatòria de mostres
    const selectRandomSample = () => {
        const randomIndex = Math.floor(Math.random() * feedbackDataOriginalPVQD.length);
        const sample = feedbackDataOriginalPVQD[randomIndex];
        setSelectedSample(sample);
    };

    useEffect(() => {
        selectRandomSample(); // Selecciona una mostra quan es munta el component
    }, []);

    const onShowFormData = () => {
        setFeedbackVisible(true);
    };

    const handleSubmit = (data) => {
        if (!selectedSample) {
            console.error('Sample name is undefined');
            return;
        }

        setFormData(data);
        setFeedbackVisible(true);
        window.scrollTo(0, 0);

        if (selectedSample.sampleName) {
            axios.get(`${apiUrl}/shared-evaluations/sample/${selectedSample.sampleName}`)
                .then(response => {
                    const evaluations = Array.isArray(response.data) ? response.data : [response.data];
                    setSharedEvaluations(evaluations);
                })
                .catch(error => {
                    console.error('Error fetching shared evaluations:', error);
                    setSharedEvaluations([]);
                });
        }
        setTimeout(() => {
            window.scrollTo(0, 0);
          }, 0);
    };

    const handleRestart = () => {
        setSelectedSample(null);
        setFeedbackVisible(false);
        setFormData(null);
        selectRandomSample(); // Selecciona una nova mostra quan es reinicia
        window.scrollTo(0, 0);
    };

    const getFilteredPVQDEvaluations = () => {
        if (!selectedSample) return [];
        return feedbackDataOriginalPVQD.filter(data => data.sampleName === selectedSample.sampleName);
    };

    return (
        <div>
            <h1>{t('cape_v_training_title')}</h1>
            
            {/* Mostra les instruccions només quan el feedback no és visible */}
            {!feedbackVisible && (
                <p>{t('cape_v_training_specific_instructions')}</p>
            )}
            {i18n.language !== 'en' && <p>{t('note_language')}</p>}
            {selectedSample && (
                <div className="audio-container">
                    <audio controls src={selectedSample.sampleLink}>
                        Your browser does not support the audio element.
                    </audio>
                </div>
            )}
            
            {!feedbackVisible && selectedSample && (
                <CAPEVForm 
                    initialData={{ overallSeverity: 0, roughness: 0, breathiness: 0, strain: 0, pitch: 0, loudness: 0 }}
                    onSubmit={handleSubmit}
                    sampleData={selectedSample}
                    userId={user?._id}
                    onShowFormData={onShowFormData}
                />
            )}
            
            {feedbackVisible && (
                <div className="feedback-screen">
                    <VisualizationToggles
                        showSociodemographicData={showSociodemographicData}
                        showStandardAttributes={showStandardAttributes}
                        showAdditionalAttributes={showAdditionalAttributes}
                        onToggleSociodemographicData={() => setShowSociodemographicData(prev => !prev)}
                        onToggleStandardAttributes={() => setShowStandardAttributes(prev => !prev)}
                        onToggleAdditionalAttributes={() => setShowAdditionalAttributes(prev => !prev)}
                    />
                    <CAPEVFeedback 
                        sampleData={selectedSample}
                        formData={formData || {}}
                        t={t} 
                        formSubmitted={true}
                        sharedEvaluations={sharedEvaluations} 
                        pvqdEvaluations={getFilteredPVQDEvaluations()}
                        showSociodemographicData={showSociodemographicData}
                        showStandardAttributes={showStandardAttributes}
                        showAdditionalAttributes={showAdditionalAttributes}
                    />
                    <br/>
                    <div className="button-container">
                        <button onClick={handleRestart} className="navigation-button">{t('restart_with_another_sample')}</button>
                    </div>
                </div>
            )}
            
            <br/>
            <div className="cites">
                <CAPEVCitation />
                <WaldenCitation />
            </div>
        <div className="navigation-container">
          <NavigationIcons />
        </div>
        </div>
    );
}

export default CAPEVTraining;