// VocalAttributeRatingInput.jsx

import React from 'react';
import DirectionDeviation from './DirectionDeviation';
import { useFixedTForResource } from '../../../../locales/useFixedTForResource';
import { variableTranslations } from './../../../../constants/variableTranslations';

function VocalAttributeRatingInput({ label, value, setValue, index }) {
  const t = useFixedTForResource('CAPEV');
  
  const isDirectional = label === "pitch" || label === "loudness";
  
  // Assegurem que 'value' és un objecte amb VASValue, isConsistent i direction per atributs direccional.
  const directionalValue = isDirectional 
    ? (value || { VASValue: 0, isConsistent: null, direction: {} }) 
    : null;
  
  // Calculem la direcció activa (la que té isPresent true)
  const activeDirectionKey = isDirectional 
    ? Object.keys(directionalValue.direction || {}).find(key => directionalValue.direction[key]?.isPresent)
    : null;
  // El valor de consistència de la direcció activa (pot ser true, false o null)
  const activeConsistent = activeDirectionKey ? directionalValue.direction[activeDirectionKey].isConsistent : null;
  
  let sliderValue = 0;
  let isDisabled = false;
  
  if (isDirectional) {
    const directionObj = directionalValue.direction || {};
    const activeKey = activeDirectionKey;
    sliderValue = activeKey
      ? (directionObj[activeKey].VASValue || 0)
      : (directionalValue.VASValue || 0);
    isDisabled = !activeKey;
  } else {
    sliderValue = (value && value.VASValue) || 0;
    isDisabled = false;
  }
  
  const onSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (isDirectional) {
      const directionObj = directionalValue.direction || {};
      const activeKey = activeDirectionKey;
      let updated;
      if (activeKey) {
        updated = {
          ...directionalValue,
          direction: {
            ...directionObj,
            [activeKey]: {
              ...directionObj[activeKey],
              VASValue: newValue,
            },
          },
        };
      } else {
        updated = { ...directionalValue, VASValue: newValue };
      }
      setValue(updated);
    } else {
      const updated = {
        ...value,
        VASValue: newValue,
      };
      setValue(updated);
    }
  };
  
  const handleRadioChange = (event) => {
    const newConsistent = event.target.value === "consistent";
    if (isDirectional) {
      const currentDirection = directionalValue.direction || {};
      const activeKey = activeDirectionKey;
      if (activeKey) {
        const updatedDirection = {
          ...currentDirection,
          [activeKey]: {
            ...currentDirection[activeKey],
            isConsistent: newConsistent,
          },
        };
        // No toquem el camp global isConsistent: deixem-lo com a null
        const updated = {
          ...directionalValue,
          direction: updatedDirection,
          isPresent: true,
        };
        setValue(updated);
      }
    } else {
      const updated = {
        ...value,
        VASValue: (value && value.VASValue) || 0,
        isConsistent: newConsistent,
        isPresent: true,
      };
      setValue(updated);
    }
  };
  
  const handleDirectionChange = (newDirection) => {
    if (isDirectional) {
      const updated = {
        ...directionalValue,
        direction: {
          ...directionalValue.direction,
          ...newDirection,
        },
      };
      setValue(updated);
    }
  };
  
  return (
    <div className="VocalAttributeRatingInput">
      {isDirectional && (
        <DirectionDeviation
          attribute={label}
          directionLabel1={
            label === "pitch"
              ? t(variableTranslations.tooHighPitch)
              : t(variableTranslations.tooLoudLoudness)
          }
          directionLabel2={
            label === "pitch"
              ? t(variableTranslations.tooLowPitch)
              : t(variableTranslations.tooQuietLoudness)
          }
          value={directionalValue ? directionalValue.direction : {}}
          onDirectionChange={handleDirectionChange}
        />
      )}
      <div className="VocalAttributeRatingInput-row">
        <div className="VocalAttributeRatingInput-col VocalAttributeRatingInput-col-left">
          <label htmlFor={`slider-${label}-${index}`}>{t(label)}</label>
        </div>
        <div className="VocalAttributeRatingInput-col VocalAttributeRatingInput-col-center">
          <input
            type="range"
            id={`slider-${label}-${index}`}
            name={`slider-${label}-${index}`}
            min="0"
            max="100"
            value={sliderValue}
            onChange={onSliderChange}
            disabled={isDisabled}
            className="capevr-slider"
          />
        </div>
        <div className="VocalAttributeRatingInput-col VocalAttributeRatingInput-col-right">
          <div className="VocalAttributeRatingInput-radio">
            <input 
              type="radio" 
              id={`consistent-${label}-${index}`} 
              name={label} 
              value="consistent" 
              onChange={handleRadioChange} 
              checked={isDirectional ? activeConsistent === true : (value && value.isConsistent === true)}
            />
            <label htmlFor={`consistent-${label}-${index}`}>{t('c')}</label>
            <input 
              type="radio" 
              id={`intermittent-${label}-${index}`} 
              name={label} 
              value="intermittent" 
              onChange={handleRadioChange} 
              checked={isDirectional ? activeConsistent === false : (value && value.isConsistent === false)}
            />
            <label htmlFor={`intermittent-${label}-${index}`}>{t('i')}</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VocalAttributeRatingInput;