// ResonanceNasalityEvaluation.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';

function ResonanceNasalityEvaluation({
  normalResonance,
  setNormalResonance,
  frontResonance,
  setFrontResonance,
  backResonance,
  setBackResonance,
  normalNasality,
  setNormalNasality,
  hypernasality,
  setHypernasality,
  hyponasality,
  setHyponasality,
}) {
  const t = useFixedTForResource('CAPEVR');

  const handleResonanceSelection = (type) => {
    setNormalResonance({ ...normalResonance, isPresent: type === 'normal' });
    setFrontResonance({ ...frontResonance, isPresent: type === 'front' });
    setBackResonance({ ...backResonance, isPresent: type === 'back' });
  };

  const handleNasalitySelection = (type) => {
    setNormalNasality({ ...normalNasality, isPresent: type === 'normal' });
    setHypernasality({ ...hypernasality, isPresent: type === 'hypernasality' });
    setHyponasality({ ...hyponasality, isPresent: type === 'hyponasality' });
  };

  return (
    <div>
      {/* Resonance */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('resonance')}:</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="resonance"
                checked={normalResonance.isPresent}
                onChange={() => handleResonanceSelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="resonance"
                checked={frontResonance.isPresent}
                onChange={() => handleResonanceSelection('front')}
              />
              {t('front')}
            </label>
            <label>
              <input
                type="radio"
                name="resonance"
                checked={backResonance.isPresent}
                onChange={() => handleResonanceSelection('back')}
              />
              {t('back')}
            </label>
          </div>

          {/* Comentari associat a Resonance */}
          {(normalResonance.isPresent || frontResonance.isPresent || backResonance.isPresent) && (
            <textarea
              id="resonanceComment"
              value={
                normalResonance.isPresent
                  ? normalResonance.attributeComment
                  : frontResonance.isPresent
                  ? frontResonance.attributeComment
                  : backResonance.isPresent
                  ? backResonance.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalResonance.isPresent) {
                  setNormalResonance({ ...normalResonance, attributeComment: e.target.value });
                } else if (frontResonance.isPresent) {
                  setFrontResonance({ ...frontResonance, attributeComment: e.target.value });
                } else if (backResonance.isPresent) {
                  setBackResonance({ ...backResonance, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              aria-label={t('resonance_comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>

      {/* Nasality */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('nasality')}:</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="nasality"
                checked={normalNasality.isPresent}
                onChange={() => handleNasalitySelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="nasality"
                checked={hyponasality.isPresent}
                onChange={() => handleNasalitySelection('hyponasality')}
              />
              {t('hyponasal')}
            </label>
            <label>
              <input
                type="radio"
                name="nasality"
                checked={hypernasality.isPresent}
                onChange={() => handleNasalitySelection('hypernasality')}
              />
              {t('hypernasal')}
            </label>
          </div>

          {/* Comentari associat a Nasality */}
          {(normalNasality.isPresent || hypernasality.isPresent || hyponasality.isPresent) && (
            <textarea
              id="nasalityComment"
              value={
                normalNasality.isPresent
                  ? normalNasality.attributeComment
                  : hypernasality.isPresent
                  ? hypernasality.attributeComment
                  : hyponasality.isPresent
                  ? hyponasality.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalNasality.isPresent) {
                  setNormalNasality({ ...normalNasality, attributeComment: e.target.value });
                } else if (hypernasality.isPresent) {
                  setHypernasality({ ...hypernasality, attributeComment: e.target.value });
                } else if (hyponasality.isPresent) {
                  setHyponasality({ ...hyponasality, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              aria-label={t('nasality_comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ResonanceNasalityEvaluation;