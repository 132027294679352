// CAPEVClassroom.jsx
// TODO Tot funciona en entorn de producció però en entorn de desenvolupament surten errors en la lògica de dades. 
// Com que en producció sembla que tot va bé, ho deixo així, però caldrà monitoritzar si apareixen problemes i adreçar-los. 

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CAPEVForm from './cape-v-shared-files/CAPEVForm';
import CAPEVCitation from './../../components/citations/CAPEVCitation';
import CAPEVFeedback from './cape-v-shared-files/CAPEVFeedback';
import VisualizationToggles from './cape-v-shared-files/VisualizationToggles';
import feedbackDataOriginalPVQD from '../../constants/feedback-data/feedbackDataOriginalPVQD';
import axios from 'axios';
import { useAuth } from './../../auth/authContext';
import NavigationIcons from './../app-views/NavigationIcons';
import WaldenCitation from './../../components/citations/WaldenCitation';
import RatingDetails from './cape-v-shared-files/RatingDetails'; // Importem RatingDetails
import { formatResultsForCSV, formatResultsForReport } from './cape-v-shared-files/formatResultsForClipboard';

// Definim l'URL de l'API utilitzant variables d'entorn
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

function CAPEVClassroom() {
    const { t, i18n } = useTranslation();
    const { user } = useAuth(); 
  
    const [selectedSampleIndex, setSelectedSampleIndex] = useState('');
    const [selectedSample, setSelectedSample] = useState(null);
    const [feedbackVisible, setFeedbackVisible] = useState(false);
    const [sharedEvaluations, setSharedEvaluations] = useState([]);
    const [formData, setFormData] = useState(null);
    const [resultsVisible, setResultsVisible] = useState(false);
  
    // Estats per a la visibilitat de les dades
    const [showSociodemographicData, setShowSociodemographicData] = useState(false);
    const [showStandardAttributes, setShowStandardAttributes] = useState(true);
    const [showAdditionalAttributes, setShowAdditionalAttributes] = useState(false);
  
    const handleSampleChange = (event) => {
      const index = event.target.value;
      selectSample(index);
    };
  
    const selectSample = (index) => {
      const sample = feedbackDataOriginalPVQD[index];
      setSelectedSample(sample);
      setSelectedSampleIndex(index);
    };
  
    const onShowFormData = () => {
      setResultsVisible(true); // Mostrar resultats en lloc de feedback directament
    };
  
    const handleSubmit = (data) => {
      if (!selectedSample) {
        console.error('Sample is undefined');
        return;
      }
    
      // Afegeix el nom real de la mostra seleccionada a formData
      setFormData({
        ...data,
        sampleName: selectedSample.sampleName  // Nom de la mostra des de selectedSample
      });
      
      setResultsVisible(true);  // Mostra els resultats
      window.scrollTo(0, 0);
    
      if (selectedSample.sampleName) {
        axios.get(`${apiUrl}/shared-evaluations/sample/${selectedSample.sampleName}`)
          .then(response => {
            const evaluations = Array.isArray(response.data) ? response.data : [response.data];
            setSharedEvaluations(evaluations);
          })
          .catch(error => {
            console.error('Error fetching shared evaluations:', error);
            setSharedEvaluations([]);
          });
      }
    };
  
    const handleRestart = () => {
      setSelectedSample(null);
      setSelectedSampleIndex('');
      setFeedbackVisible(false);
      setResultsVisible(false); // Reinicia també la visibilitat dels resultats
      setFormData({
        sampleName: '',
        overallSeverity: { value: 0, variability: 'consistent' },
        roughness: { value: 0, variability: 'consistent' },
        breathiness: { value: 0, variability: 'consistent' },
        strain: { value: 0, variability: 'consistent' },
        pitch: { value: 0, variability: 'consistent', direction: { tooHigh: false, tooLow: false } },
        loudness: { value: 0, variability: 'consistent', direction: { tooLoud: false, tooSoft: false } },
        additionalResonances: { resonances: {} },
        additionalFeatures: { features: {} },
        comments: ''
      });  // Assigna valors per defecte
      window.scrollTo(0, 0);
    };    
  
    const getFilteredPVQDEvaluations = () => {
      if (!selectedSample) return [];
      return feedbackDataOriginalPVQD.filter(data => data.sampleName === selectedSample.sampleName);
    };

    const handleCopyToClipboard = (format) => {
      let resultsString = '';
      if (format === 'csv') {
        resultsString = formatResultsForCSV(formData, t);
      } else if (format === 'report') {
        const formattedData = formatResultsForReport(formData, t);
        const lines = [
          `${t('sample')}: ${formData.sampleName}`,
          `${t('overall_severity')}: ${formattedData.overallSeverity}`,
          `${t('roughness')}: ${formattedData.roughness}`,
          `${t('breathiness')}: ${formattedData.breathiness}`,
          `${t('strain')}: ${formattedData.strain}`,
          `${t('pitch')}: ${formattedData.pitch}`,
          `${t('loudness')}: ${formattedData.loudness}`,
          `${t('comments_resonance')}: ${formattedData.additionalResonancesResults}`,
          `${t('additional_features')}: ${formattedData.additionalFeaturesResults}`,
          `${t('general_comments')}: ${formattedData.comments}`
        ];
        resultsString = lines.join('\n');
      }
      navigator.clipboard.writeText(resultsString)
        .then(() => {
          alert(t("ratings_copied_to_clipboard"));
        })
        .catch(err => {
          console.error(t("error_copying_to_clipboard"), err);
        });
    };  
  
    return (
      <div>
        <h1>{t('cape_v_classroom_title')}</h1>
        
        {!feedbackVisible && !resultsVisible && (
          <p>{t('cape_v_classroom_specific_instructions')}</p>
        )}
        {i18n.language !== 'en' && <p>{t('note_language')}</p>}
        <div className="media-controls">
          <select
            value={selectedSampleIndex || ''}
            onChange={handleSampleChange}
            className="sample-selector"
          >
            <option value="">{t('select_a_sample')}</option>
            {feedbackDataOriginalPVQD.map((sample, index) => (
              <option key={index} value={index}>
                {sample.name || `Sample ${index + 1}`}
              </option>
            ))}
          </select>
        </div>
        
        {selectedSample && (
          <div className="audio-container">
            <audio controls src={selectedSample.sampleLink}>
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
        
        {!feedbackVisible && !resultsVisible && (
          <CAPEVForm 
            initialData={{ overallSeverity: 0, roughness: 0, breathiness: 0, strain: 0, pitch: 0, loudness: 0 }}
            onSubmit={handleSubmit}
            sampleData={selectedSample}
            userId={user?._id}
            onShowFormData={onShowFormData}
          />
        )}
        
        {resultsVisible && (
          <div>
            <h2>{t('your_ratings')}</h2>
            <RatingDetails results={formData} />
            <div className="button-container">
              <button onClick={() => handleCopyToClipboard('csv')} className="button-center">{t('copy_ratings_csv')}</button>
              <button onClick={() => handleCopyToClipboard('report')} className="button-center">{t('copy_ratings_report')}</button>
              <button onClick={() => setFeedbackVisible(true)} className="navigation-button">{t('feedback')}</button>
            </div>
          </div>
        )}

        {feedbackVisible && (
          <div className="feedback-screen">
            <VisualizationToggles
              showSociodemographicData={showSociodemographicData}
              showStandardAttributes={showStandardAttributes}
              showAdditionalAttributes={showAdditionalAttributes}
              onToggleSociodemographicData={() => setShowSociodemographicData(prev => !prev)}
              onToggleStandardAttributes={() => setShowStandardAttributes(prev => !prev)}
              onToggleAdditionalAttributes={() => setShowAdditionalAttributes(prev => !prev)}
            />
            <CAPEVFeedback 
              sampleData={selectedSample}
              formData={formData || {}}
              t={t} 
              formSubmitted={true}
              sharedEvaluations={sharedEvaluations} 
              pvqdEvaluations={getFilteredPVQDEvaluations()}
              showSociodemographicData={showSociodemographicData}
              showStandardAttributes={showStandardAttributes}
              showAdditionalAttributes={showAdditionalAttributes}
            />
            <br/>
            <div className="button-container">
              <button onClick={handleRestart} className="navigation-button">{t('restart_with_another_sample')}</button>
            </div>
          </div>
        )}
        
        <br/>
        <div className="cites">
          <CAPEVCitation />
          <WaldenCitation />
        </div>
        <div className="navigation-container">
          <NavigationIcons />
        </div>
      </div>
    );
}

export default CAPEVClassroom;