// WVDConsentModal.jsx
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Trans, useTranslation } from 'react-i18next';

Modal.setAppElement('#root'); // Assegura't d'indicar l'element principal

const WVDConsentModal = ({ 
  isOpen, 
  onRequestClose, 
  informedConsentLink, 
  participantInformationLink, 
  onConfirm 
}) => {
  const { t } = useTranslation();

  const [checkedItems, setCheckedItems] = useState({
    informationAndConsentReadAndAccepted: false,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.name]: e.target.checked,
    });
    setErrorMessage("");
  };

  const handleConfirm = () => {
    // Comprovem que el checkbox està marcat
    if (!checkedItems.informationAndConsentReadAndAccepted) {
      setErrorMessage(t("please_confirm"));
      return;
    }
    // Si tot és correcte, cridem la funció de confirmació i tanquem el modal
    onConfirm();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t('consent_modal_title')}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '500px',
          minWidth: '300px',
          padding: '20px',
          maxHeight: '80%',
          overflowY: 'auto',
          zIndex: 1000,
        },
        overlay: {
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)'
        },
      }}
    >
      <h2>{t('informed_consent')}</h2>
      <div style={{ fontSize: '0.9em' }}>
        <Trans
          i18nKey="wvd_consent_modal_text"
          components={{
            profileLink: <a href="/profile" target="_blank" rel="noopener noreferrer">{t('profile_link_text')}</a>
          }}
        />
      </div>
      <p>
        <a style={{ fontSize: '0.9em' }} href={participantInformationLink} target="_blank" rel="noopener noreferrer">
          {t('participant_information_sheet')}
        </a>
      </p>
      <p>
        <a style={{ fontSize: '0.9em' }} href={informedConsentLink} target="_blank" rel="noopener noreferrer">
          {t('informed_consent')}
        </a>
      </p>
      <div className="checkboxes">
        <label>
          <input
            type="checkbox"
            name="informationAndConsentReadAndAccepted"
            checked={checkedItems.informationAndConsentReadAndAccepted}
            onChange={handleCheckboxChange}
          />
          {t("i_have_read_and_accept_the_participant_information_sheet_and_the_informed_consent")}
        </label>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button onClick={handleConfirm} className="button-participate">
        {t("confirm_and_participate")}
      </button>
    </Modal>
  );
};

export default WVDConsentModal;