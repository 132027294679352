// CAPEVForm.jsx

// En aquest codi es separen les lògiques per guardar dades per a la recerca i per compartir 
// avaluacions. `dataForResearch` s'utilitza per emmagatzemar dades en un format més estructurat 
// per a la recerca, mentre que `evaluationData` es manté en el format original per assegurar 
// que els mecanismes de feedback continuïn funcionant. Aquesta separació permet una transició 
// més suau cap a una organització de dades més eficient i mantenible. 
// Quan hi ha consentiment de l'usuari per compartir, les mateixes dades `dataForResearch` 
// s'emmagatzemen paral·lelament en una nova col·lecció anomenada `sharing_data`, a l'espera 
// de ser utilitzada en els nous mecanismes de feedback que es programin.

import React, { useState, useEffect } from 'react';
import CAPEVFormItself from './CAPEVFormItself';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ConsentModal from '../../../components/modals/ConsentModal';

export function calculateAge(birthDate, evaluationDate = new Date()) {
  const birth = new Date(birthDate);
  let age = evaluationDate.getFullYear() - birth.getFullYear();
  const monthDiff = evaluationDate.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && evaluationDate.getDate() < birth.getDate())) {
      age--;
  }
  return age;
}

function CAPEVForm({ onSubmit, sampleData, initialData = {} }) {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';
  const [consentGiven, setConsentGiven] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userWantsToShareEvaluations, setUserWantsToShareEvaluations] = useState(false);
  const [userId, setUserId] = useState(null);
  const [age, setAge] = useState(null);
  const [pronoun, setPronoun] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [communicationLanguage, setCommunicationLanguage] = useState("");
  const [profession, setProfession] = useState("");
  const [voiceCompetenceLevel, setVoiceCompetenceLevel] = useState("");

  const [showSociodemographicData, setShowSociodemographicData] = useState(false);

  const DEFAULT_CHARACTERISTIC_PARAMETERS = { value: 0, variability: 'consistent' };
  const INITIAL_PITCH_DIRECTION = { tooHigh: false, tooLow: false };
  const INITIAL_LOUDNESS_DIRECTION = { tooLoud: false, tooSoft: false };
  
  // Inicialitzacions per a pitch i loudness
  const INITIAL_PITCH_CHARACTERISTIC_PARAMETERS = { 
    value: 0, 
    variability: 'consistent', 
    direction: INITIAL_PITCH_DIRECTION 
  };
  const INITIAL_LOUDNESS_CHARACTERISTIC_PARAMETERS = { 
    value: 0, 
    variability: 'consistent', 
    direction: INITIAL_LOUDNESS_DIRECTION 
  };  

  const [overallSeverity, setOverallSeverity] = useState(initialData.overallSeverity || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [roughness, setRoughness] = useState(initialData.roughness || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [breathiness, setBreathiness] = useState(initialData.breathiness || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [strain, setStrain] = useState(initialData.strain || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [pitch, setPitch] = useState(initialData.pitch || INITIAL_PITCH_CHARACTERISTIC_PARAMETERS);
  const [loudness, setLoudness] = useState(initialData.loudness || INITIAL_LOUDNESS_CHARACTERISTIC_PARAMETERS);
  const [additionalResonances, setAdditionalResonances] = useState({ resonances: initialData.additionalResonances || {}, text: '' });
  const [additionalFeatures, setAdditionalFeatures] = useState({ features: initialData.additionalFeatures || {}, text: '' });
  const [comments, setComments] = useState(initialData.comments || "");

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (token) {
      axios.get(`${apiUrl}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        const user = response.data;
        setUserId(user._id);
        setPronoun(user.pronoun);
        setCountryOfResidence(user.countryOfResidence);
        setCommunicationLanguage(user.communicationLanguage);
        setProfession(user.profession);
        setVoiceCompetenceLevel(user.voiceCompetenceLevel);

        const evaluationDate = new Date();
        const calculatedAge = calculateAge(user.dateOfBirth, evaluationDate);
        setAge(calculatedAge);

        if ((user.voiceCompetenceLevel === 'advanced' || user.voiceCompetenceLevel === 'expert') && user.wantsToShareEvaluations) {
          setUserWantsToShareEvaluations(true);
          setConsentGiven(true); 
        }
      }).catch(error => {
        console.error('Error fetching user data:', error);
      });
    }
  }, [apiUrl]);

  const handleToggleSociodemographicData = () => {
    if (!sampleData) {
      alert(t('select_a_sample'));
    } else {
      setShowSociodemographicData(!showSociodemographicData);
    }
  };  

  const handleAdditionalResonanceChange = (updatedResonances) => {
    setAdditionalResonances(prevState => ({
      ...prevState,
      resonances: updatedResonances.resonances,
      text: updatedResonances.text !== undefined ? updatedResonances.text : prevState.text
    }));
  };

  const handleAdditionalFeatureChange = (updatedFeatures) => {
    setAdditionalFeatures(prevState => ({
      ...prevState,
      features: updatedFeatures.features,
      text: updatedFeatures.text !== undefined ? updatedFeatures.text : prevState.text
    }));
  };

// `dataForResearch` és una estructura de dades que agrupa les dades sociodemogràfiques i perceptives 
// per facilitar l'anàlisi de les dades de recerca. Aquesta estructura és la que s'envia al backend 
// per emmagatzemar dades de recerca. 
// Quan hi ha consentiment de l'usuari per compartir, les mateixes dades `dataForResearch` 
// s'emmagatzemen paral·lelament en una nova col·lecció anomenada `sharing_data`, a l'espera 
// de ser utilitzada en els nous mecanismes de feedback que es programin.

const dataForResearch = {
  sociodemographicData: {
      age,
      pronoun,
      countryOfResidence,
      communicationLanguage,
      profession,
      voiceCompetenceLevel,
  },
  perceptualData: {
      overallSeverity,
      roughness,
      breathiness,
      strain,
      pitch,
      loudness,
      ...additionalResonances.resonances,
      ...additionalFeatures.features,
      comments,
  }
};

// `evaluationData` manté les dades sociodemogràfiques desagrupades i les de ressonàncies i característiques
// addicionals agrupades, tal com s'havien utilitzat anteriorment en el codi. Això es fa per assegurar que els
// mecanismes de feedback existents continuïn funcionant correctament sense haver de reestructurar tot el codi 
// immediatament. En el futur, es planificarà una migració per unificar les estructures de dades, simplificar 
// el codi, i assegurar una millor organització i mantenibilitat a llarg termini.

const evaluationData = {
  overallSeverity,
  roughness,
  breathiness,
  strain,
  pitch,
  loudness,
  additionalResonances: additionalResonances.resonances,
  additionalFeatures: additionalFeatures.features,
  comments,
  age,
  pronoun,
  countryOfResidence,
  communicationLanguage,
  profession,
  voiceCompetenceLevel,
};


const handleSubmit = async () => {
  if (!sampleData) {
    alert(t('select_a_sample'));
    return; // Aturem la funció si no hi ha mostra seleccionada
  }

  const token = localStorage.getItem('token');

  // Envies les dades al component pare
  onSubmit(evaluationData);
  window.scrollTo(0, 0);

  if (token) {
    try {
      const response = await axios.get(`${apiUrl}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const user = response.data;

      if (user.acceptResearchUsage) {
        await axios.post(`${apiUrl}/research`, {
          userId: user._id,
          evaluationType: 'CAPE-V',
          dataForResearch,
          sampleName: sampleData.sampleName,
        });
        console.log('Dades de recerca guardades correctament');
      }

      if (userWantsToShareEvaluations) {
        setModalIsOpen(true);
        return;
      }
    } catch (error) {
      console.error('Error guardant les dades de recerca:', error);
    }
  }
};

  const handleConsentSubmit = async () => {
    if (consentGiven) {
        try {
            // Primera operació: enviar evaluationData a shared_evaluations
            await axios.post(`${apiUrl}/shared-evaluations`, {
              userId: userId,
              evaluationType: 'CAPE-V',
              evaluationData: evaluationData,
              sampleName: sampleData.sampleName
            });
            console.log('Avaluació compartida correctament');

            // Segona operació: enviar dataForResearch a sharing_data
            await axios.post(`${apiUrl}/sharing`, {
              userId: userId,
              evaluationType: 'CAPE-V',  
              dataForResearch: dataForResearch,  
              sampleName: sampleData.sampleName
            });
            console.log('Dades pels propers feedbacks que es programin compartides correctament');

        } catch (error) {
            console.error('Error compartint les dades:', error);
        }
    } else {
        console.log('L\'usuari ha decidit no compartir l\'avaluació.');
    }

    setModalIsOpen(false);
    onSubmit(evaluationData);
    window.scrollTo(0, 0);
};

  const clearForm = () => {
    setOverallSeverity(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setRoughness(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setBreathiness(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setStrain(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setPitch(INITIAL_PITCH_CHARACTERISTIC_PARAMETERS);
    setLoudness(INITIAL_LOUDNESS_CHARACTERISTIC_PARAMETERS);
    setAdditionalResonances({ resonances: {}, text: '' });
    setAdditionalFeatures({ features: {}, text: '' });
    setComments("");
  };

  return (
    <div className="VocalEvaluationForm">
      <p>{t('cape_v_general_instructions')}</p>

      {/* Checkbox per mostrar dades sociodemogràfiques */}
      <div className="sociodemographic-toggle" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <input
          type="checkbox"
          id="showSociodemographicData"
          checked={showSociodemographicData}
          onChange={handleToggleSociodemographicData}
        />
        <label htmlFor="showSociodemographicData">
          {t('show_sociodemographic_data')}
        </label>
      </div>

      {showSociodemographicData && sampleData && (
        <div className="sample-info">
          <p className="small">{t('subject')}: {sampleData.participantId || t('not_available')}</p>
          <p className="small">{t('age')}: {sampleData.age || t('not_available')}</p>
          <p className="small">{t('gender')}: {sampleData.gender ? t(sampleData.gender) : t('not_available')}</p>
          <p className="small">{t('diagnose')}: {sampleData.diagnose ? t(sampleData.diagnose) : t('not_available')}</p>
          <p className="small">{t('language')}: {sampleData.language ? t(sampleData.language) : t('not_available')}</p>
          <p className="small">{t('database')}: {sampleData.database || t('not_available')}</p>
        </div>      
      )}

      <br/>

      <div className="button-container">
        <button className="button-center" onClick={clearForm}>{t('clear_form')}</button>
      </div>
      <CAPEVFormItself
        overallSeverity={overallSeverity} setOverallSeverity={setOverallSeverity}
        roughness={roughness} setRoughness={setRoughness}
        breathiness={breathiness} setBreathiness={setBreathiness}
        strain={strain} setStrain={setStrain}
        pitch={pitch} setPitch={setPitch}
        loudness={loudness} setLoudness={setLoudness}
        additionalResonances={additionalResonances} setAdditionalResonances={setAdditionalResonances}
        additionalFeatures={additionalFeatures} setAdditionalFeatures={setAdditionalFeatures}
        comments={comments} setComments={setComments}
        handleAdditionalResonanceChange={handleAdditionalResonanceChange}
        handleAdditionalFeatureChange={handleAdditionalFeatureChange}
      />
      <br/>
      <div className="button-container">
        <button className="button-center" onClick={handleSubmit}>{t('submit')}</button>
      </div>
      <br />
      <ConsentModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onConsentChange={setConsentGiven}
        consentGiven={consentGiven}
        onSubmit={handleConsentSubmit}
      />
    </div>
  );
}

export default CAPEVForm;