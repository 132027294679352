// Legend.jsx
// Legend.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';

const Legend = () => {
  const { t } = useTranslation();

  const legendData = [
    { range: "0", color: "#EEE", label: `0 ${t('assessments')}` },
    { range: "1 - 10", color: "#d2e9e6", label: `1-10 ${t('assessments')}` },
    { range: "11 - 50", color: "#a5d3ce", label: `11-50 ${t('assessments')}` },
    { range: "51 - 200", color: "#79beb5", label: `51-200 ${t('assessments')}` },
    { range: "201 - 500", color: "#4ca89d", label: `201-500 ${t('assessments')}` },
    { range: "501+", color: "#209385", label: `501+ ${t('assessments')}` },
  ];

  return (
    <div style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "16px",
      marginBottom: "16px",
      maxWidth: "600px",
      gap: "16px"
    }}>
      {legendData.map((item, index) => (
        <div key={index} style={{
          display: "flex",
          alignItems: "center",
          minWidth: "120px"
        }}>
          <div style={{
            width: "20px",
            height: "20px",
            backgroundColor: item.color,
            marginRight: "4px"
          }} />
          <span style={{ fontSize: "12px" }}>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;