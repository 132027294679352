// useSharedAssessments.js

import { useState, useEffect } from 'react';
import axios from 'axios';

export function useSharedAssessments(apiUrl, sampleId) {
    const [sharedAssessments, setSharedAssessments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSharedAssessments = async () => {
            if (!sampleId) {
                console.warn("⚠️ [useSharedAssessments] No sample ID provided.");
                setLoading(false);
                return;
            }

            try {
                // 🔑 Passa el sampleId com a paràmetre de la petició GET
                const response = await axios.get(`${apiUrl}/voice-assessments?sampleId=${sampleId}`);
                setSharedAssessments(response.data.data);
                console.log("✅ [useSharedAssessments] Shared assessments fetched:", response.data.data);
            } catch (err) {
                console.error("⚠️ [useSharedAssessments] Error fetching shared assessments:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchSharedAssessments();
    }, [apiUrl, sampleId]);

    return { sharedAssessments, loading, error };
}