// CAPEVForm.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFixedTForResource } from '../../../locales/useFixedTForResource';
import ResourceLanguageWarning from '../../../locales/ResourceLanguageWarning';
import { useUserData } from './../../../hooks/useUserData';
import SharingConsentModal from './../../../components/modals/SharingConsentModal';
import Demographics from './cape-v-files/Demographics.jsx';
import VocalTasks from './cape-v-files/VocalTasks';
import StandardVocalAttributesRating from './cape-v-files/StandardVocalAttributesRating';
import AdditionalAttributesRating from './cape-v-files/AdditionalAttributesRating';
import AdditionalResonances from './cape-v-files/AdditionalResonances';
import AdditionalFeatures from './cape-v-files/AdditionalFeatures';

function CAPEVForm({ apiUrl, selectedSample, sampleDatabase, onSubmit, defaultPerceptualData }) {
  const t = useFixedTForResource('CAPEV');
  const { sociodemographicData } = useUserData(apiUrl);

  const {
    age: raterAge,
    pronoun: raterPronoun,
    countryOfResidence: raterCountry,
    communicationLanguage: raterLanguage,
    profession: raterProfession,
    voiceCompetenceLevel: raterVoiceCompetence,
    wantsToShareEvaluations,
    acceptResearchUsage,
  } = sociodemographicData || {};

  const [isSharingConsentModalOpen, setIsSharingConsentModalOpen] = useState(false);
  const [pendingSharedAssessment, setPendingSharedAssessment] = useState(null);

  const [subjectNameId, setSubjectNameId] = useState('');
  const [subjectGender, setSubjectGender] = useState('');
  const [subjectAge, setSubjectAge] = useState('');
  const [examinerId, setExaminerId] = useState('');
  const [recordingDate, setRecordingDate] = useState('');

  const [overallSeverity, setOverallSeverity] = useState(() => defaultPerceptualData?.overallSeverity || { VASValue: 0, isConsistent: null });
  const [roughness, setRoughness] = useState(() => defaultPerceptualData?.roughness || { VASValue: 0, isConsistent: null });
  const [breathiness, setBreathiness] = useState(() => defaultPerceptualData?.breathiness || { VASValue: 0, isConsistent: null });
  const [strain, setStrain] = useState(() => defaultPerceptualData?.strain || { VASValue: 0, isConsistent: null });

  const [additionalResonancesValues, setAdditionalResonancesValues] = useState(() => ({
    normalNasality: defaultPerceptualData?.normalNasality || { attributeName: "normalNasality", isPresent: null, VASValue: 0 },
    hyponasality: defaultPerceptualData?.hyponasality || { attributeName: "hyponasality", isPresent: null, VASValue: 0 },
    hypernasality: defaultPerceptualData?.hypernasality || { attributeName: "hypernasality", isPresent: null, VASValue: 0 },
    otherResonance: defaultPerceptualData?.otherResonance || { attributeName: "", isPresent: false, VASValue: 0 }
  }));

  const [additionalFeaturesValues, setAdditionalFeaturesValues] = useState(() => ({
    diplophonia: defaultPerceptualData?.diplophonia || { attributeName: "diplophonia", isPresent: false },
    fry: defaultPerceptualData?.fry || { attributeName: "fry", isPresent: false },
    falsetto: defaultPerceptualData?.falsetto || { attributeName: "falsetto", isPresent: false },
    asthenia: defaultPerceptualData?.asthenia || { attributeName: "asthenia", isPresent: false },
    aphonia: defaultPerceptualData?.aphonia || { attributeName: "aphonia", isPresent: false },
    pitchInstability: defaultPerceptualData?.pitchInstability || { attributeName: "pitchInstability", isPresent: false },
    tremor: defaultPerceptualData?.tremor || { attributeName: "tremor", isPresent: false },
    wetGurgly: defaultPerceptualData?.wetGurgly || { attributeName: "wetGurgly", isPresent: false },
    otherAdditionalFeature: defaultPerceptualData?.otherAdditionalFeature || { attributeName: "", isPresent: false, VASValue: 0 }
  }));

  const [normalPitch, setNormalPitch] = useState(() => defaultPerceptualData?.normalPitch || { isPresent: null, isConsistent: null });
  const [tooHighPitch, setTooHighPitch] = useState(() => defaultPerceptualData?.tooHighPitch || { isPresent: null, isConsistent: null });
  const [tooLowPitch, setTooLowPitch] = useState(() => defaultPerceptualData?.tooLowPitch || { isPresent: null, isConsistent: null });

  const [normalLoudness, setNormalLoudness] = useState(() => defaultPerceptualData?.normalLoudness || { isPresent: null, isConsistent: null });
  const [tooLoudLoudness, setTooLoudLoudness] = useState(() => defaultPerceptualData?.tooLoudLoudness || { isPresent: null, isConsistent: null });
  const [tooQuietLoudness, setTooQuietLoudness] = useState(() => defaultPerceptualData?.tooQuietLoudness || { isPresent: null, isConsistent: null });

  useEffect(() => {
    if (sampleDatabase !== 'upload' && selectedSample) {
      setSubjectNameId(selectedSample.subjectId?.subjectNameId || '');
      setSubjectGender(selectedSample.subjectId?.subjectProfile?.gender || '');
      setSubjectAge(selectedSample.subjectId?.subjectProfile?.age || '');
      setExaminerId(selectedSample.identificationData?.examinerId || '');
      setRecordingDate(selectedSample.identificationData?.recordingDate || '');
    }
  }, [selectedSample, sampleDatabase]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const voiceAssessment = {
      userId: sociodemographicData?.id || null,
      voiceSampleId: selectedSample?._id || null,
      voiceDatabaseId: selectedSample?.identificationData?.voiceDatabaseId || null,
      subjectId: selectedSample?.subjectId || null,         
      isSharedForFeedback: false,
      raterData: {
        age: raterAge,
        pronoun: raterPronoun,
        countryOfResidence: raterCountry,
        communicationLanguage: raterLanguage,
        profession: raterProfession,
        voiceCompetenceLevel: raterVoiceCompetence,
      },
      evaluationTool: "67cebba8ce47613f6ee36021",
      perceptualData: {
        overallSeverity: { ...overallSeverity, attributeName: "overallSeverity", isPresent: overallSeverity.VASValue > 15 },
        roughness: { ...roughness, attributeName: "roughness", isPresent: roughness.VASValue > 14 },
        breathiness: { ...breathiness, attributeName: "breathiness", isPresent: breathiness.VASValue > 13 },
        strain: { ...strain, attributeName: "strain", isPresent: strain.VASValue > 15 },
        normalPitch: { ...normalPitch, attributeName: "normalPitch" },
        tooHighPitch: { ...tooHighPitch, attributeName: "tooHighPitch" },
        tooLowPitch: { ...tooLowPitch, attributeName: "tooLowPitch" },
        normalLoudness: { ...normalLoudness, attributeName: "normalLoudness" },
        tooLoudLoudness: { ...tooLoudLoudness, attributeName: "tooLoudLoudness" },
        tooQuietLoudness: { ...tooQuietLoudness, attributeName: "tooQuietLoudness" },
        // Aplica els spread per als altres camps...
        ...additionalResonancesValues,
        ...additionalFeaturesValues,
        // Override dels camps "other" amb fallback només en el payload:
        otherResonance: {
          attributeName:
            additionalResonancesValues.otherResonance?.attributeName.trim() !== ""
              ? additionalResonancesValues.otherResonance.attributeName
              : t('otherResonance'),
          isPresent: additionalResonancesValues.otherResonance?.isPresent,
          VASValue: additionalResonancesValues.otherResonance?.VASValue || 0,
        },
        otherAdditionalFeature: {
          attributeName:
            additionalFeaturesValues.otherAdditionalFeature?.attributeName.trim() !== ""
              ? additionalFeaturesValues.otherAdditionalFeature.attributeName
              : t('otherAdditionalFeature'),
          isPresent: additionalFeaturesValues.otherAdditionalFeature?.isPresent,
          VASValue: additionalFeaturesValues.otherAdditionalFeature?.VASValue || 0,
        },
      },
    };

    if (!selectedSample || sampleDatabase === "upload") {
      onSubmit(voiceAssessment);
      return;
    }

    if (acceptResearchUsage || wantsToShareEvaluations) {
      setPendingSharedAssessment(voiceAssessment);
      setIsSharingConsentModalOpen(true);
    } else {
      onSubmit(voiceAssessment);
    }
  };

  const handleConfirmSharing = async ({ shareForFeedback, researchUsage }) => {
    if (!pendingSharedAssessment) return;
    if (!shareForFeedback && !researchUsage) {
      setIsSharingConsentModalOpen(false);
      onSubmit(pendingSharedAssessment);
      return;
    }
    const assessmentToSubmit = {
      ...pendingSharedAssessment,
      isSharedForFeedback: shareForFeedback,
      acceptResearchUsage: researchUsage,
    };
    try {
      await axios.post(`${apiUrl}/voice-assessments`, assessmentToSubmit, {
        headers: { "Content-Type": "application/json" },
      });
      onSubmit(assessmentToSubmit);
    } catch (error) {
      console.error("⚠️ Error en l'enviament de dades compartides:", error);
    } finally {
      setIsSharingConsentModalOpen(false);
    }
  };

  return (
    <form className="capevr-form" onSubmit={handleSubmit}>

    <ResourceLanguageWarning resourceKey="CAPEV" />

      <div className="capevr-section">
        <Demographics
          subjectNameId={subjectNameId}
          setSubjectNameId={setSubjectNameId}
          subjectGender={subjectGender}
          setSubjectGender={setSubjectGender}
          subjectAge={subjectAge}
          setSubjectAge={setSubjectAge}
          examinerId={examinerId}
          setExaminerId={setExaminerId}
          recordingDate={recordingDate}
          setRecordingDate={setRecordingDate}
        />
      </div>
      <div className="capevr-section">
        <h2>{t("stimuli")}</h2>
        <VocalTasks />
      </div>
      <div className="capevr-section">
        {selectedSample && (
          <div className="audio-container">
            <h2>{t("sample")}</h2>
            {selectedSample.sampleLink ? (
              <audio controls src={selectedSample.sampleLink}></audio>
            ) : (
              <p>{t("no_audio_for_sample")}</p>
            )}
          </div>
        )}
      </div>
      {!selectedSample && (
        <div className="button-container capevr-section">
          <h2>{t("sample")}</h2>
          <button
            type="button"
            className="button-center"
            onClick={() => onSubmit({ goBackToSampleSelection: true })}
          >
            {t("choose_a_sample_to_evaluate")}
          </button>
        </div>
      )}
      <div className="capevr-section">
        <h2>{t("rating_form")}</h2>
        <StandardVocalAttributesRating
          overallSeverity={overallSeverity}
          setOverallSeverity={setOverallSeverity}
          roughness={roughness}
          setRoughness={setRoughness}
          breathiness={breathiness}
          setBreathiness={setBreathiness}
          strain={strain}
          setStrain={setStrain}
          normalPitch={normalPitch}
          setNormalPitch={setNormalPitch}
          tooHighPitch={tooHighPitch}
          setTooHighPitch={setTooHighPitch}
          tooLowPitch={tooLowPitch}
          setTooLowPitch={setTooLowPitch}
          normalLoudness={normalLoudness}
          setNormalLoudness={setNormalLoudness}
          tooLoudLoudness={tooLoudLoudness}
          setTooLoudLoudness={setTooLoudLoudness}
          tooQuietLoudness={tooQuietLoudness}
          setTooQuietLoudness={setTooQuietLoudness}
        />
      </div>
      <div className="capevr-section">
        <AdditionalAttributesRating
          attributes={{
            ...additionalResonancesValues,
            ...additionalFeaturesValues,
          }}
          onChange={(updatedAttributes) => {
            setAdditionalResonancesValues({
              normalNasality: updatedAttributes.normalNasality,
              hyponasality: updatedAttributes.hyponasality,
              hypernasality: updatedAttributes.hypernasality,
              otherResonance: updatedAttributes.otherResonance,
            });
            setAdditionalFeaturesValues({
              diplophonia: updatedAttributes.diplophonia,
              fry: updatedAttributes.fry,
              falsetto: updatedAttributes.falsetto,
              asthenia: updatedAttributes.asthenia,
              aphonia: updatedAttributes.aphonia,
              pitchInstability: updatedAttributes.pitchInstability,
              tremor: updatedAttributes.tremor,
              wetGurgly: updatedAttributes.wetGurgly,
              otherAdditionalFeature: updatedAttributes.otherAdditionalFeature,
            });
          }}
        />
      </div>
      <div className="capevr-section">
        <AdditionalResonances
          value={additionalResonancesValues}
          onChange={setAdditionalResonancesValues}
        />
      </div>
      <div className="capevr-section">
        <AdditionalFeatures
          value={additionalFeaturesValues}
          onChange={setAdditionalFeaturesValues}
        />
      </div>
      <div className="button-container">
        <button className="button-center" type="submit">
          {t("submit")}
        </button>
      </div>
      <SharingConsentModal
        isOpen={isSharingConsentModalOpen}
        onRequestClose={() => setIsSharingConsentModalOpen(false)}
        onSubmit={handleConfirmSharing}
        initialShareForFeedback={sociodemographicData?.wantsToShareEvaluations || false}
        initialResearchUsage={sociodemographicData?.acceptResearchUsage || false}
        voiceCompetenceLevel={sociodemographicData?.voiceCompetenceLevel}
      />
    </form>
  );
}

export default CAPEVForm;