// ProtectedRoute.jsx

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/authContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
    const { user, loadingUser } = useAuth();
    const location = useLocation();

    if (loadingUser) {
        console.log("⏳ `ProtectedRoute.jsx` esperant a `loadingUser`...");
        return <div>Loading...</div>;
    }

    if (!user) {
        console.log("🔒 Usuari no autenticat. Guardant la ruta i redirigint a `/login`...");
        localStorage.setItem('preLoginRoute', location.pathname); // Guarda la ruta original
        return <Navigate to="/login" replace />;
    }

    if (allowedRoles && !allowedRoles.includes(user.role)) {
        return <Navigate to="/unauthorized" replace />;
    }

    return children;
};

export default ProtectedRoute;