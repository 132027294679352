// utils/generateTempId.js

export const getTempParticipantId = () => {
    let tempId = sessionStorage.getItem('tempParticipantId');
    if (!tempId) {
      tempId = Math.random().toString(36).substr(2, 9);
      sessionStorage.setItem('tempParticipantId', tempId);
    }
    return tempId;
  };
  