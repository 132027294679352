// useFixedTForResource.js

import { useLanguage } from './LanguageContext';
import i18n from './i18n';

export const useFixedTForResource = (resourceKey) => {
  const { appLanguage, resourceLanguages } = useLanguage();

  const availableLangs = resourceLanguages[resourceKey] || [];
  const resourceLang = availableLangs.includes(appLanguage) ? appLanguage : 'en';

  // Et retorna un t() fix en l’idioma correcte per aquest recurs
  return i18n.getFixedT(resourceLang);
};