// DataTableCI.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { variableTranslations } from '../../constants/variableTranslations';

const DataTableCI = ({ userData = {}, attributeKeys = [] }) => {
    const { t } = useTranslation();
    const tableRef = useRef();

    useEffect(() => {
        const tableContainer = d3.select(tableRef.current);
        tableContainer.selectAll('*').remove();

        const processData = () => {
            return attributeKeys
                .map(key => {
                    const userEntry = userData?.[key] || {};
                    const hasVASValue = userEntry.VASValue !== undefined && userEntry.VASValue > 0;
                    const hasPresence = userEntry.isPresent === true;

                    if (!hasVASValue && !hasPresence) return null;

                    const isTranslatable = variableTranslations.hasOwnProperty(userEntry.attributeName);
                    const attributeName = userEntry.attributeName
                        ? (isTranslatable ? t(variableTranslations[userEntry.attributeName]) : userEntry.attributeName)
                        : t(variableTranslations[key] || key);

                    const userRating = hasVASValue
                        ? `${userEntry.VASValue}/100`
                        : hasPresence
                            ? t('yes')
                            : '-';

                    const consistency = userEntry.isConsistent === true 
                        ? t('consistent') 
                        : (userEntry.isConsistent === false 
                            ? t('intermittent') 
                            : '-');

                    return {
                        attribute: attributeName,
                        userRating,
                        consistency
                    };
                })
                .filter(Boolean);
        };

        const data = processData();

        if (data.length === 0) {
            tableContainer.append('p')
                .text(t('no_data_available'))
                .style('font-size', '12px');
            return;
        }

        const table = tableContainer.append('table')
            .style('width', '100%')
            .style('border-collapse', 'collapse')
            .style('font-size', '14px');

        const thead = table.append('thead');
        thead.append('tr')
            .selectAll('th')
            .data([t('attribute'), t('your_rating'), t('consistency')])
            .enter()
            .append('th')
            .style('text-align', 'left')
            .style('padding', '4px')
            .style('border-bottom', '1px solid #ccc')
            .style('width', '33%')
            .text(d => d);

        const tbody = table.append('tbody');
        data.forEach(({ attribute, userRating, consistency }) => {
            const row = tbody.append('tr');

            row.append('td')
                .style('padding', '4px')
                .style('border-bottom', '1px solid #eee')
                .text(attribute);

            row.append('td')
                .style('padding', '4px')
                .style('border-bottom', '1px solid #eee')
                .text(userRating);

            row.append('td')
                .style('padding', '4px')
                .style('border-bottom', '1px solid #eee')
                .text(consistency);
        });
    }, [userData, attributeKeys, t]);

    return <div ref={tableRef}></div>;
};

export default DataTableCI;