// StandardVocalAttributesRating.jsx

import React from 'react';
import VocalAttributeRatingInput from './VocalAttributeRatingInput';
import { useFixedTForResource } from '../../../../locales/useFixedTForResource';
import { variableTranslations } from './../../../../constants/variableTranslations';

function StandardVocalAttributesRating({
  overallSeverity, setOverallSeverity,
  roughness, setRoughness,
  breathiness, setBreathiness,
  strain, setStrain,
  normalPitch, setNormalPitch,
  tooHighPitch, setTooHighPitch,
  tooLowPitch, setTooLowPitch,
  normalLoudness, setNormalLoudness,
  tooLoudLoudness, setTooLoudLoudness,
  tooQuietLoudness, setTooQuietLoudness,
}) {
  const t = useFixedTForResource('CAPEV');

  return (
    <div>
      <VocalAttributeRatingInput
        label={t('overall_severity')}
        value={overallSeverity}
        setValue={setOverallSeverity}
      />
      <VocalAttributeRatingInput
        label={t('roughness')}
        value={roughness}
        setValue={setRoughness}
      />
      <VocalAttributeRatingInput
        label={t('breathiness')}
        value={breathiness}
        setValue={setBreathiness}
      />
      <VocalAttributeRatingInput
        label={t('strain')}
        value={strain}
        setValue={setStrain}
      />

      {/* Pitch */}
      <VocalAttributeRatingInput
        label="pitch"
        value={{
          VASValue: normalPitch?.VASValue || 0,
          isConsistent: normalPitch?.isConsistent,
          direction: {
            [variableTranslations.tooHighPitch]: tooHighPitch ?? { isPresent: false, isConsistent: null },
            [variableTranslations.tooLowPitch]: tooLowPitch ?? { isPresent: false, isConsistent: null },
          },
        }}
        setValue={(updatedValue) => {
          setNormalPitch((prev) => ({
            ...prev,
            VASValue: updatedValue.VASValue,
            isConsistent: updatedValue.isConsistent,
          }));
          setTooHighPitch(
            updatedValue.direction?.[variableTranslations.tooHighPitch] ?? { isPresent: false, isConsistent: null }
          );
          setTooLowPitch(
            updatedValue.direction?.[variableTranslations.tooLowPitch] ?? { isPresent: false, isConsistent: null }
          );
        }}
      />

      {/* Loudness */}
      <VocalAttributeRatingInput
        label="loudness"
        value={{
          VASValue: normalLoudness?.VASValue || 0,
          isConsistent: normalLoudness?.isConsistent,
          direction: {
            [variableTranslations.tooLoudLoudness]: tooLoudLoudness ?? { isPresent: false, isConsistent: null },
            [variableTranslations.tooQuietLoudness]: tooQuietLoudness ?? { isPresent: false, isConsistent: null },
          },
        }}
        setValue={(updatedValue) => {
          setNormalLoudness((prev) => ({
            ...prev,
            VASValue: updatedValue.VASValue,
            isConsistent: updatedValue.isConsistent,
          }));
          setTooLoudLoudness(
            updatedValue.direction?.[variableTranslations.tooLoudLoudness] ?? { isPresent: false, isConsistent: null }
          );
          setTooQuietLoudness(
            updatedValue.direction?.[variableTranslations.tooQuietLoudness] ?? { isPresent: false, isConsistent: null }
          );
        }}
      />
    </div>
  );
}

export default StandardVocalAttributesRating;