// AudioManager.jsx

import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useFetchSamples from "../../hooks/useFetchSamples";
import AudioUploader from "./AudioUploader";

const AudioManager = ({ apiUrl, mode, sampleDatabaseId, onSampleSelected }) => {
  const { t } = useTranslation();
  const { samples, loading, error } = useFetchSamples(sampleDatabaseId, apiUrl);
  const [randomSample, setRandomSample] = useState(null);

  // 🟢 Filtrar mostres públiques
  const publicSamples = useMemo(() => 
    samples
      .filter(sample => sample.visibility?.isPublic)
      .sort((a, b) => (a.sampleName || "").localeCompare(b.sampleName || "")),
    [samples]
  );  

  // 🔹 Selecció aleatòria de mostra si el mode és "random"
  useEffect(() => {
    if (mode === "random" && !randomSample && publicSamples.length > 0) {
      const randomIndex = Math.floor(Math.random() * publicSamples.length);
      const sample = publicSamples[randomIndex];
      setRandomSample(sample);
      onSampleSelected(sample);
    }
  }, [mode, randomSample, onSampleSelected, publicSamples]);

  // 🔹 Selecció manual de mostra
  const handleManualSelection = (index) => {
    const selectedSample = publicSamples[index] || null;
    setRandomSample(selectedSample);
    onSampleSelected(selectedSample);
  };

  // 🔹 Pujada de fitxer
  const handleFileUpload = (uploadedFile) => {
    const sampleNameFromUpload = uploadedFile.name.replace(/\.[^/.]+$/, "");
    const sampleLink = URL.createObjectURL(uploadedFile);
    onSampleSelected({ sampleLink, sampleName: sampleNameFromUpload });
  };

  return (
    <div>
      {loading && <p>{t("loading_samples")}</p>}
      {error && <p style={{ color: "red" }}>{t("error_loading_samples")}: {error}</p>}

      {/* 🔹 Selecció manual de mostres */}
      {mode === "manual" && publicSamples.length > 0 && (
        <select
          onChange={(e) => handleManualSelection(parseInt(e.target.value, 10))}
        >
          <option value="">{t("select_a_sample")}</option>
          {publicSamples.map((sample, index) => (
            <option key={sample._id} value={index}>
              {sample.sampleName || `Sample ${index + 1}`}
            </option>
          ))}
        </select>
      )}

      {/* 🔹 Pujada de fitxer */}
      {mode === "upload" && <AudioUploader onFileUpload={handleFileUpload} />}
    </div>
  );
};

export default AudioManager;