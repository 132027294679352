// ResearchProjectCard.jsx

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import axios from "axios";
import ConsentModal from "./ConsentModal";
import LockIcon from "../../../components/icons/LockIcon"; 

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5001/api";

export function ResearchProjectCard({ project, isExpanded, onSelect, user, setUser, openLoginModal }) {
  const { t } = useTranslation();
  const [isEnrolled, setIsEnrolled] = useState(user?.acceptResearchUsage || false);
  const [loading, setLoading] = useState(false);
  const [isConsentModalOpen, setConsentModalOpen] = useState(false);

  useEffect(() => {
    if (!user || !user.token) {
      console.warn("User object is missing or incomplete");
      return;
    }
  }, [user]);

  useEffect(() => {
    setIsEnrolled(user?.acceptResearchUsage || false);
  }, [user]);

  // Funció per actualitzar la inscripció
  const toggleEnrollment = async () => {
    if (project.id !== 1) return;
    if (!user || !user._id || !user.token) {
      console.error("User object is missing required properties.");
      return;
    }
    setLoading(true);
    try {
      const newEnrollmentStatus = !isEnrolled;
      const response = await axios.put(
        `${apiUrl}/users/${user._id}/update-consent`,
        { acceptResearchUsage: newEnrollmentStatus },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      if (response.status === 200) {
        setIsEnrolled(newEnrollmentStatus);
        setUser({ ...user, acceptResearchUsage: newEnrollmentStatus });
      } else {
        console.error("Error updating enrollment status");
      }
    } catch (error) {
      console.error("Request failed", error);
    } finally {
      setLoading(false);
    }
  };

  // Definim les funcions per obrir/tancar el modal de consentiments i per completar la participació.
  const openConsentModal = () => {
    setConsentModalOpen(true);
  };

  const closeConsentModal = () => {
    setConsentModalOpen(false);
  };

  const handleParticipation = async () => {
    // Crida a toggleEnrollment per actualitzar l'estat d'enrollment
    await toggleEnrollment();
  };

  return (
    <div className={`research-card ${isExpanded ? "expanded" : ""}`}>
      {/* Etiqueta d'estat visible sempre */}
      <span className="status-label" style={{ color: "red", fontSize: "0.8em" }}>
        {project.isActive ? t("active") : t("available_soon")}
      </span>
      
      <h2>{project.title}</h2>
      <h3 className="project-subtitle">{project.recruitmentCall}</h3>
      <p>{project.recruitmentDescription}</p>

      {isExpanded && (
        <div className="project-details">
          {/* Mostrem els detalls clàssics del projecte */}
          <p>
            <strong>{t("principal_investigator")}:</strong> {project.principalInvestigator}
          </p>
          <p>
            <strong>{t("organization")}:</strong> {project.researcherOrganization}
          </p>
          <p>
            <strong>{t("inclusion_criteria")}:</strong> {project.inclusionCriteria}
          </p>

          <ul>
            <li>
              <a
                href={project.participantInformationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("participant_information_sheet")}
              </a>
            </li>
            <li>
              <a
                href={project.informedConsentLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("informed_consent")}
              </a>
            </li>
          </ul>

          {/* Botó de participació per al projecte 1 */}
          {project.id === 1 && (
            <div className="text-red">
              <p>
                {user 
                  ? (user.acceptResearchUsage ? t("you_are_enrolled") : t("you_are_not_enrolled"))
                  : t("please_log_in_to_participate")
                }
              </p>
              <button
                className="button-participate"
                onClick={
                  user
                    ? (user.acceptResearchUsage ? toggleEnrollment : openConsentModal)
                    : openLoginModal
                }
                disabled={loading || !project.isActive}
              >
                {user
                  ? (user.acceptResearchUsage ? t("withdraw") : (project.isActive ? t("participate") : t("available_soon")))
                  : (project.isActive ? t("participate") : t("available_soon"))
                }
                {/* Mostrem la LockIcon si el projecte requereix registre */}
                {project.requiresRegistration && <LockIcon isRestricted={true} user={user} />}
              </button>
            </div>
          )}

          {/* Altres projectes */}
          {project.id !== 1 && (
            user ? (
              <button 
                className="button-participate"
                onClick={openConsentModal}
                disabled={!project.isActive}
                style={{ pointerEvents: project.isActive ? "auto" : "none", opacity: project.isActive ? 1 : 0.6 }}
              >
                {project.isActive ? t("participate") : t("available_soon")}
                {/* Mostrem la LockIcon només si el projecte requereix registre */}
                {project.requiresRegistration && <LockIcon isRestricted={true} user={user} />}
              </button>
            ) : (
              <button 
                className="button-participate"
                onClick={openLoginModal}
                disabled={!project.isActive}
                style={{ pointerEvents: project.isActive ? "auto" : "none", opacity: project.isActive ? 1 : 0.6 }}
              >
                {project.isActive ? t("participate") : t("available_soon")}
                {project.requiresRegistration && <LockIcon isRestricted={true} user={user} />}
              </button>
            )
          )}
        </div>
      )}

      <ConsentModal
        isOpen={isConsentModalOpen}
        onRequestClose={closeConsentModal}
        project={project}
        onConfirm={handleParticipation}
      />
      
      <div className="expand-toggle" onClick={onSelect}></div>
    </div>
  );
}

export default ResearchProjectCard;