// InstabilitiesEvaluation.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';
import { variableTranslations } from './../../../../../constants/variableTranslations';

function InstabilitiesEvaluation({
  selectedInstabilities,
  setSelectedInstabilities,
  instabilitiesValues,
  setInstabilitiesValues,
}) {
  const t = useFixedTForResource('CAPEVR');

  const handleCheckboxChange = (instability) => {
    if (selectedInstabilities.includes(instability)) {
      setSelectedInstabilities(selectedInstabilities.filter((item) => item !== instability));
      const updatedValues = { ...instabilitiesValues };
      delete updatedValues[instability];
      setInstabilitiesValues(updatedValues);
    } else {
      const translationKey = variableTranslations[instability] || instability;
      const attributeName = instability === 'otherInstability' ? '' : t(translationKey);
      setSelectedInstabilities([...selectedInstabilities, instability]);
      setInstabilitiesValues({
        ...instabilitiesValues,
        [instability]: {
          VASValue: 0,
          attributeName,
        },
      });
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (selectedInstabilities.includes('otherInstability')) {
      setInstabilitiesValues({
        ...instabilitiesValues,
        otherInstability: {
          ...instabilitiesValues.otherInstability,
          attributeName: newText,
        },
      });
    }
  };

  return (
    <div className="capevr-radio-group">
      <label className="capevr-radio-label">{t('instabilities')}:</label>
      <div className="capevr-checkboxes-horizontal">
        {[
          'aphonicBreak',
          'pitchBreak',
          'pitchInstability',
          'spasm',
          'tremor',
          'otherInstability',
        ].map((value) => {
          const translationKey = variableTranslations[value] || value;
          return (
            <label key={value} className="capevr-checkbox-label-inline">
              <input
                type="checkbox"
                name={value}
                value={value}
                checked={selectedInstabilities.includes(value)}
                onChange={() => handleCheckboxChange(value)}
              />
              {value === 'otherInstability'
                ? instabilitiesValues?.otherInstability?.attributeName || t(translationKey)
                : t(translationKey)}
              {value === 'otherInstability' &&
                selectedInstabilities.includes(value) && (
                  <input
                    type="text"
                    id="otherInstabilityTag"
                    value={instabilitiesValues?.otherInstability?.attributeName || ''}
                    onChange={handleTextChange}
                    placeholder={t('specify')}
                    className="capevr-input-inline"
                  />
                )}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default InstabilitiesEvaluation;