// utils/transformSessionFeedback.js

/**
 * Adapta els resultats d'una sessió interactiva al format d'una avaluació de veu.
 * Si hi ha diversos resultats, pots triar, per exemple, l'últim o fer una agregació.
 *
 * @param {Array} results - L'array de resultats de la sessió.
 * @param {Object} selectedSample - La mostra seleccionada de la sessió.
 * @returns {Array} - Array d'objectes amb forma { perceptualData, participantName, userId }
 */
export const adaptSessionFeedback = (results, selectedSample) => {
  if (!results || results.length === 0) return [];

  // Iterem i adaptem els resultats
  const adaptedResults = results.map((result) => {
      const perceptualData = result.submittedData?.perceptualData || {};  // 🔥 Agafem les dades perceptuals correctament
      return {
          perceptualData: perceptualData,
          participantName: result.participantName || "Unknown",
          userId: result.userId || "Unknown",
          voiceSampleId: selectedSample?._id || null,
      };
  });

  console.log("Adapted session feedback (array):", JSON.stringify(adaptedResults, null, 2));
  return adaptedResults;
};