// OsForm.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useUserData } from './../../../hooks/useUserData';
import VASRatingInput from '../../../components/form-items/VASRatingInput';
import SharingConsentModal from './../../../components/modals/SharingConsentModal';

function OsForm({ apiUrl, selectedSample, sampleDatabase, onSubmit, defaultPerceptualData }) {
  const { t } = useTranslation();
  const { sociodemographicData } = useUserData(apiUrl);
  
  // Desestructuració de les dades de l'usuari autenticat
  const {
    age,
    pronoun,
    countryOfResidence,
    communicationLanguage,
    profession,
    voiceCompetenceLevel,
    wantsToShareEvaluations,
    acceptResearchUsage,
  } = sociodemographicData || {};

  // Estats bàsics per Demographics (opcional)
  const [subjectNameId, setSubjectNameId] = useState('');

  // Estat per overallSeverity (una slider)
  const [overallSeverity, setOverallSeverity] = useState(() => {
    const defaultValue = defaultPerceptualData?.overallSeverity?.VASValue || 0;
    return { VASValue: defaultValue, isConsistent: null };
  });
  

  // Estat per gestionar el consentiment, si fos necessari
  const [isSharingConsentModalOpen, setIsSharingConsentModalOpen] = useState(false);
  const [pendingSharedAssessment, setPendingSharedAssessment] = useState(null);

  useEffect(() => {
    if (sampleDatabase !== 'upload' && selectedSample) {
      setSubjectNameId(selectedSample.subjectId?.subjectNameId || '');
    }
  }, [selectedSample, sampleDatabase]);

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const voiceAssessment = {
      userId: sociodemographicData?.id || null,
      voiceSampleId: selectedSample?._id || null,
      voiceDatabaseId: selectedSample?.identificationData?.voiceDatabaseId?._id || null,
      subjectId: selectedSample?.subjectId?._id || null,
      raterData: {
        age,
        pronoun,
        countryOfResidence,
        communicationLanguage,
        profession,
        voiceCompetenceLevel,
      },
      evaluationTool: "67cebba8ce47613f6ee36027", 
      perceptualData: {
        overallSeverity: { 
          attributeName: "overallSeverity",
          isPresent: overallSeverity.VASValue > 0,
          VASValue: overallSeverity.VASValue,
          isConsistent: overallSeverity.isConsistent
        }
      },
    };

    if (!selectedSample || sampleDatabase === "upload") {
      console.log("🚫 Mostra penjada o sense cap mostra seleccionada. Actualitzem localment sense modal ni POST a DB.");
      onSubmit(voiceAssessment);
      return;
    }
    
    if (acceptResearchUsage || wantsToShareEvaluations) {
      // Si hi ha consentiment, obre el modal
      setPendingSharedAssessment(voiceAssessment);
      setIsSharingConsentModalOpen(true);
    } else {
      onSubmit(voiceAssessment);
    }
  };
  
  // Nota: Si no utilitzes handleConfirmSharing en aquest component, pots eliminar-lo o comentar-lo.
  const handleConfirmSharing = async ({ shareForFeedback, researchUsage }) => {
    if (!pendingSharedAssessment) {
      console.warn("⚠️ No hi ha dades pendents per compartir.");
      return;
    }
  
    if (!shareForFeedback && !researchUsage) {
      console.log("L'usuari no dóna consentiment. No s'envia a la base de dades.");
      setIsSharingConsentModalOpen(false);
      onSubmit(pendingSharedAssessment);
      return;
    }
  
    const assessmentToSubmit = {
      ...pendingSharedAssessment,
      isSharedForFeedback: shareForFeedback,
      acceptResearchUsage: researchUsage,
    };
  
    console.log("📤 Dades enviades a /voice-assessments:", JSON.stringify(assessmentToSubmit, null, 2));
  
    try {
      await axios.post(`${apiUrl}/voice-assessments`, assessmentToSubmit, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("✅ Dades enviades correctament.");
      onSubmit(assessmentToSubmit);
    } catch (error) {
      console.error("⚠️ Error en l'enviament de dades compartides:", error);
      if (error.response) {
        console.error("📩 Resposta del servidor:", error.response.data);
      }
    } finally {
      setIsSharingConsentModalOpen(false);
    }
  };

  return (
    <div>
    <p>{t("os_form_introduction")}</p>
    <form className="os-form" onSubmit={handleSubmit}>
      {selectedSample && (
        <div className="sample-info">
          <p>{t('sample')}: {subjectNameId}</p>
        </div>
      )}
      <div className="capevr-section">
        {selectedSample && (
          <div className="audio-container">
            <h2>{t("sample")}</h2>
            {selectedSample.sampleLink ? (
              <audio controls src={selectedSample.sampleLink}></audio>
            ) : (
              <p>{t("no_audio_for_sample")}</p>
            )}
          </div>
        )}
      </div>
      {!selectedSample && (
        <div className="button-container capevr-section">
          <button
            type="button"
            className="button-center"
            onClick={() => onSubmit({ goBackToSampleSelection: true })}
          >
            {t("choose_a_sample_to_evaluate")}
          </button>
        </div>
      )}
    <div className="capevr-section">
    <h2>{t("rating_form")}</h2>
      </div>
      <VASRatingInput
        label={t('overall_severity')}
        VASValue={overallSeverity.VASValue} 
        setVASValue={(newVal) =>
          setOverallSeverity(prev => ({ ...prev, VASValue: newVal }))
        }
        index={0}
      />
        <br/>
      <div className="button-container">
        <button className="button-center" type="submit">{t('submit')}</button>
      </div>

      <SharingConsentModal
        isOpen={isSharingConsentModalOpen}
        onRequestClose={() => setIsSharingConsentModalOpen(false)}
        onSubmit={handleConfirmSharing}
        initialShareForFeedback={wantsToShareEvaluations || false}
        initialResearchUsage={acceptResearchUsage || false}
        voiceCompetenceLevel={voiceCompetenceLevel}
      />
    </form>
    </div>
  );
}

export default OsForm;