// CAPEVClinic.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CAPEVVocalTasks from './cape-v-shared-files/CAPEVVocalTasks';
import CAPEVCitation from './../../components/citations/CAPEVCitation';
import CAPEVForm from './cape-v-shared-files/CAPEVForm';
import RatingDetails from './cape-v-shared-files/RatingDetails';
import { formatResultsForCSV } from './cape-v-shared-files/formatResultsForCVS'; 
import AudioUploader from './../../components/audio/AudioUploader';
import ReportBuilder from './cape-v-shared-files/ReportBuilder';

function CAPEVClinic() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    overallSeverity: { value: 0, variability: 'consistent' },
    roughness: { value: 0, variability: 'consistent' },
    breathiness: { value: 0, variability: 'consistent' },
    strain: { value: 0, variability: 'consistent' },
    pitch: { value: 0, variability: 'consistent', direction: { tooHigh: false, tooLow: false } },
    loudness: { value: 0, variability: 'consistent', direction: { tooLoud: false, tooSoft: false } },
    additionalResonances: { resonances: {} },
    additionalFeatures: { features: {} },
    comments: ''
  });

  const [resultsVisible, setResultsVisible] = useState(false);
  const [showReportBuilder, setShowReportBuilder] = useState(false);
  const [submissionDate, setSubmissionDate] = useState('');

  const handleFileUpload = (uploadedFile) => {
    // Funció de pujada de fitxers, actualment buida.
  };

  const handleSubmit = (data) => {
    setFormData(data);
    const currentDate = new Date().toISOString().split('T')[0];
    setSubmissionDate(currentDate);
    setResultsVisible(true);
    setShowReportBuilder(false);
    window.scrollTo(0, 0);
  };

  const interpretDirection = (direction, context) => {
    if (context === "pitch") {
      return `${direction?.tooHigh ? t('too_high') : ""}${direction?.tooHigh && direction?.tooLow ? ", " : ""}${direction?.tooLow ? t('too_low') : ""}` || t('normal');
    } else if (context === "loudness") {
      return `${direction?.tooLoud ? t('too_loud') : ""}${direction?.tooLoud && direction?.tooSoft ? ", " : ""}${direction?.tooSoft ? t('too_soft') : ""}` || t('normal');
    }
    return t('normal');
  };

  const formatResultsForReport = (data) => {
    let additionalResonancesResults = '';
    let additionalFeaturesResults = '';
    
    if (data.additionalResonances) {
      Object.entries(data.additionalResonances).forEach(([key, detail]) => {
        const value = detail.value && typeof detail.value === 'object' ? detail.value.value : detail.value;
        const label = key === 'other' && detail && detail.label ? detail.label : t(key);
        additionalResonancesResults += `${label}: ${value}/100 (${t(detail.variability)})\n`;
      });
    }
  
    if (data.additionalFeatures) {
      Object.entries(data.additionalFeatures).forEach(([key, detail]) => {
        const value = detail.value && typeof detail.value === 'object' ? detail.value.value : detail.value;
        const label = key === 'other' && detail && detail.label ? detail.label : t(key);
        additionalFeaturesResults += `${label}: ${value}/100 (${t(detail.variability)})\n`;
      });
    }
  
    return {
      overallSeverity: `${data.overallSeverity.value}/100 (${t(data.overallSeverity.variability)})`,
      roughness: `${data.roughness.value}/100 (${t(data.roughness.variability)})`,
      breathiness: `${data.breathiness.value}/100 (${t(data.breathiness.variability)})`,
      strain: `${data.strain.value}/100 (${t(data.strain.variability)})`,
      pitch: `${data.pitch.value}/100, ${interpretDirection(data.pitch.direction, "pitch")} (${t(data.pitch.variability)})`,
      loudness: `${data.loudness.value}/100, ${interpretDirection(data.loudness.direction, "loudness")} (${t(data.loudness.variability)})`,
      additionalResonancesResults: additionalResonancesResults || t('none'),
      additionalFeaturesResults: additionalFeaturesResults || t('none'),
      comments: data.comments || t('none')
    };
  };

  const handleCopyToClipboard = (format) => {
    let resultsString = '';
    if (format === 'csv') {
      resultsString = formatResultsForCSV(formData, t);
    } else if (format === 'report') {
      const formattedData = formatResultsForReport(formData);
      const lines = [
        `${t('overall_severity')}: ${formattedData.overallSeverity}`,
        `${t('roughness')}: ${formattedData.roughness}`,
        `${t('breathiness')}: ${formattedData.breathiness}`,
        `${t('strain')}: ${formattedData.strain}`,
        `${t('pitch')}: ${formattedData.pitch}`,
        `${t('loudness')}: ${formattedData.loudness}`,
        `${t('comments_resonance')}: ${formattedData.additionalResonancesResults}`,
        `${t('additional_features')}: ${formattedData.additionalFeaturesResults}`,
        `${t('general_comments')}: ${formattedData.comments}`
      ];
      resultsString = lines.join('\n');
    }
    navigator.clipboard.writeText(resultsString)
      .then(() => {
        alert(t("ratings_copied_to_clipboard"));
      })
      .catch(err => {
        console.error(t("error_copying_to_clipboard"), err);
      });
  };  

  const handleRestart = () => {
    setResultsVisible(false);
    setShowReportBuilder(false);
    setFormData({
      overallSeverity: { value: 0, variability: 'consistent' },
      roughness: { value: 0, variability: 'consistent' },
      breathiness: { value: 0, variability: 'consistent' },
      strain: { value: 0, variability: 'consistent' },
      pitch: { value: 0, variability: 'consistent', direction: { tooHigh: false, tooLow: false } },
      loudness: { value: 0, variability: 'consistent', direction: { tooLoud: false, tooSoft: false } },
      additionalResonances: { resonances: {} },
      additionalFeatures: { features: {} },
      comments: ''
    });
    window.scrollTo(0, 0);
  };

  const formattedDataForReport = formatResultsForReport(formData);

  return (
    <div>
      <h1>{t('cape_v_clinic_title')}</h1>
      {!resultsVisible && (
        <>
          <h2>{t('cape_v_tasks_title')}</h2>
          <CAPEVVocalTasks />
          <p>{t('cape_v_clinic_specific_instructions')}</p>
        </>
      )}
      <div className="audio-uploader-center">
        <AudioUploader onFileUpload={handleFileUpload} />
      </div>
      {resultsVisible && !showReportBuilder && (
        <p className="centered-text">{t('cape_v_clinic_ratings_introduction')}</p>
      )}
      {!resultsVisible && (
        <>
          <br/>
          <p>{t('cape_v_general_instructions')}</p>
          <br/>
          <CAPEVForm onSubmit={handleSubmit} />
        </>
      )}
      {resultsVisible && !showReportBuilder && (
        <div className="results-section">
          <h2>{t('your_ratings')}</h2>
          <RatingDetails results={formData} />
        </div>
      )}
      {resultsVisible && !showReportBuilder && (
        <div className="button-container">
          <h3>{t('download')}</h3>
          <button onClick={() => handleCopyToClipboard('report')} className="button-center">{t('copy_ratings_to_clipboard')}</button>
          <button onClick={() => setShowReportBuilder(true)} className="button-center">{t('download_personalized_cape_v_report_in_pdf_format')}</button>
          <h3>{t('restart')}</h3>
          <button onClick={handleRestart} className="button-center">{t('evaluate_another_sample')}</button>
        </div>
      )}
      {showReportBuilder && (
        <ReportBuilder 
          results={formattedDataForReport} 
          submissionDate={submissionDate} 
          onRestart={handleRestart}
        />
      )}
      <div className="cites">
        <CAPEVCitation></CAPEVCitation>
      </div>
      <br />
    </div>
  );
}

export default CAPEVClinic;