// JoinAStudy.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';


const JoinAStudy = () => {
  const { t } = useTranslation();


  return (
    <div>
      <h1>{t('join_a_study')}</h1>
      <p>{t('join_a_study_introduction')}</p><br/>
      <div className="button-container">
        <input
          type="text"
          className="input-field"
          placeholder={t('study_code')}
        />
        <input
          type="text"
          className="input-field"
          placeholder={t('name_id')}
        />
        <button className="button-center">
        {t('join')}
        </button>
      </div>
      <h3 className="text-green centered-text">{t("join_a_study_cta")}</h3>
        <div className="button-container">
          <button 
              className="button-center"
          >
              {t('get_in_touch')}
          </button>
        </div>
    </div>
  );
}

export default JoinAStudy;