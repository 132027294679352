// WhoIsTalking.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function WhoIsTalking() {
    const { t } = useTranslation();

    return (
        <div className="long-text-container">
            <section className="long-section">
            <h1>{t('who_is_talking_about_us')}</h1>
            <p>
                <strong>Belhau, M.</strong> (2024, September 5). <i>Voice and Culture - Broadening Horizons</i> [Conference presentation]. 15th Pan-European Voice Conference (PEVOC), Santander, Spain.
            </p>
            <p>
                <strong>Kempster, G. B., Nagle, K. F., & Solomon, N. P.</strong> (2025). Development and rationale for the consensus auditory-perceptual evaluation of voice-revised (CAPE-Vr). <i>Journal of Voice.</i>
            </p>
            <p>
                <strong>Nagle, K.</strong> (2024, May 31). <i>Describing voice quality in 5 minutes: Balancing feasibility and psychometric validity</i> [Conference presentation]. 53rd Annual Symposium of the Voice Foundation, Philadelphia, PA, United States.
            </p>
            <p>
                <strong>Nagle, K.</strong> (2025). The Voice is More Than The Sum of its Parts: Challenges to Perceptual Evaluation of Disordered Voice.<i> The Voice, 30</i>(1). The Voice Foundation Newsletter. Retrieved from <a href="https://indd.adobe.com/view/bc611740-7979-480b-8c25-2147c2ab8252" target="_blank" rel="noopener noreferrer">https://indd.adobe.com/view/bc611740-7979-480b-8c25-2147c2ab8252</a> 
            </p>
            <p>
                <strong>Nagle, K.</strong> (2025, March 28). <i>Voice Assessment for School-Based SLPs: The Basics</i> [Conference presentation]. 2025 New Jersey Speech-Language Hearing Association Annual Convention, Atlantic City, NJ, United States.
            </p>
            <p>
                <strong>Nagle, K. F., Kempster, G. B., & Solomon, N. P.</strong> (2024). Survey of Voice-Focused Speech-Language Pathologists’ Usage of the Consensus Auditory Perceptual Evaluation of Voice (CAPE-V). <i>Journal of Voice.</i> <a href="https://doi.org/10.1016/j.jvoice.2024.08.032" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.jvoice.2024.08.032</a>
            </p>
            <p>
                <strong>Pommée, T., Defoy, L., & Verduyckt, I.</strong> (2025). From practice to protocol: The CAPE-VFQ by and for Quebec French SLPs. <i> Clinical Linguistics & Phonetics, 1-12.</i>. <a href="https://doi.org/10.1080/02699206.2025.2456214" target="_blank" rel="noopener noreferrer">https://doi.org/10.1080/02699206.2025.2456214</a>
            </p>
            <p>
                <strong>Pommée, T., Renaud, S. E., & Verduyckt, I.</strong> (2025). Reliability and Task Effects in CAPE-V Auditory-Perceptual Voice Assessments: Insights From the PVQD30 Subset <i> Journal of Voice.</i>. <a href="https://doi.org/10.1016/j.jvoice.2025.02.020" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.jvoice.2025.02.020</a>
            </p>
            <p>
                <strong>Shea, B., Palmer, A., Chang, S., Childes, J., & Graville, D. J.</strong> (2024, December 5–7). <i>Current practices in the perceptual assessment of voice in research</i> [Poster presentation]. 2024 American Speech, Language and Hearing Association (ASHA) Convention, Seattle, Washington, United States.
            </p>
            <p>
                <strong>Verduyckt, I. & Renaud, S.E.</strong> (2024, September 6). <i>Reliability, Validity and Usability of the French Quebec Version of the CAPE-V: a Mixed Methods Study</i>. [Conference presentation]. 15th Pan-European Voice Conference (PEVOC), Santander, Spain.
            </p>
            </section>
        </div>
    );
}

export default WhoIsTalking;