// ResourcesForTraining.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../../locales/i18n';
import { useAuth } from '../../auth/authContext';
import NavigationIcons from './../app-views/NavigationIcons';

function ResourcesForTraining() {
    const { t } = useTranslation();
    const { user } = useAuth(); 
    const navigate = useNavigate();

    const renderLockIcon = (isRestricted) => (
        isRestricted ? (
            <i className="fas fa-lock" style={{ color: user ? '#a5d3ce' : 'red' }}></i>
        ) : null
    );

    return (
        <div className="main-content">
            <h1>{t('resources_for_autonomous_training_title')}</h1>
            <p>{t('resources_for_autonomous_training_presentation')}</p>

            <p className="centered-text">{t('practice_evaluating_random_samples_at_your_own_pace')}:</p>
            <button className="button-link" onClick={() => navigate('/cape-v-training')}>
                {t('cape_v_training_title')}
                <span className="restricted-marker">{renderLockIcon(true)}</span>
            </button><br/>

            <button className="button-link" onClick={() => navigate('/os-training')}>
                {t('overall_severity')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/>

            <button className="button-link" onClick={() => navigate('/rbs-training')}>
                {t('roughness_breathiness_strain')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/><br/>

            <p className="centered-text">{t('play_guessing_the_vocal_diagnosis_from_random_samples')}:</p>
            <button className="button-link" onClick={() => navigate('/guess-the-diagnosis')}>
                {t('guess_the_diagnosis_title')}
                <span className="restricted-marker">{renderLockIcon(true)}</span>
            </button><br/><br/><br/>

            <p className="centered-text">{t('turn_your_practice_into_research_power')}:</p>
            <button className="navigation-button" onClick={() => navigate('/research-at-all-voiced')}>
                {t('research_at_allvoiced')}
            </button><br/>

            <div className="navigation-container">
                <NavigationIcons exclude={['/resources-for-autonomous-training']} />
            </div>
        </div>
    );
}

export default ResourcesForTraining;