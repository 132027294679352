// CAPEVRVocalTasks.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';

function CAPEVRVocalTasks({ areSentencesModeled, setAreSentencesModeled, readingPassage, setReadingPassage }) {
  const t = useFixedTForResource('CAPEVR');

  return (
    <div className="capevr-vocal-tasks">

      {/* Vowels */}
      <div className="capevr-task-section">
        <p><strong><em>{t('vowels')}:</em></strong> {t('vowels_description')}</p>
      </div>

      {/* Sentences */}
      <div className="capevr-task-section">
        <p><strong><em>{t('sentences')}:</em></strong></p>

        <div className="capevr-sentences-grid">
          {/* Primera columna */}
          <ol className="capevr-sentences-column" type="a" start={1}>
            <li>{t('sentence_a')}</li>
            <li>{t('sentence_b')}</li>
            <li>{t('sentence_c')}</li>
          </ol>

          {/* Segona columna */}
          <ol className="capevr-sentences-column" type="a" start={4}>
            <li>{t('sentence_d')}</li>
            <li>{t('sentence_e')}</li>
            <li>{t('sentence_f')}</li>
          </ol>

          {/* Tercera columna per a la checkbox */}
          <div className="capevr-checkbox-column">
            <label className="capevr-checkbox-label">
              <input
                type="checkbox"
                name="areSentencesModeled"
                value="areSentencesModeled"
                checked={areSentencesModeled}
                onChange={(e) => setAreSentencesModeled(e.target.checked)}
              />
              {t('check_if_the_examiner_modeled_the_sentences')}
            </label>
          </div>
        </div>
      </div>

      {/* Extemporaneous Speech */}
      <div className="capevr-task-section">
        <p>
          <strong><em>{t('extemporaneous_speech')}:</em></strong> "{t('extemporaneous_speech_description')}."
        </p>
      </div>
      {/* Reading Passage (opcional) */}
      <div className="capevr-task-section">
      <p><strong><em>{t('reading_passage_optional')}:</em></strong></p>
        <textarea
          value={readingPassage}
          onChange={(e) => setReadingPassage(e.target.value)}
          placeholder={t('specify')}
          className="capevr-textarea"
        />
      </div>
    </div>
  );
}

export default CAPEVRVocalTasks;