// CapeVEntrenamiento.jsx
// Igual que CapeVTraining però amb les mostres en castellà de la tesi.
// No publicar en obert
// Compte que hi he enllaçat el mateix fitxer de sharedRatingsData que al CapeVTraining

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CAPEVForm from './cape-v-shared-files/CAPEVForm';
import CAPEVCitation from './../../components/citations/CAPEVCitation';
import FeedbackCapeV from './cape-v-shared-files/FeedbackCapeV';
import feedbackDataThesisConcatenatedSpanish from '../../constants/feedback-data/feedbackDataThesisConcatenatedSpanish';
import sharedRatingsData from '../../constants/feedback-data/sharedRatingsData';

function CapeVEntrenamiento() {
  const { t } = useTranslation();
  const [randomSample, setRandomSample] = useState(null);
  const [formData, setFormData] = useState(null);
  const [feedbackVisible, setFeedbackVisible] = useState(false);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * feedbackDataThesisConcatenatedSpanish.length);
    setRandomSample(feedbackDataThesisConcatenatedSpanish[randomIndex]);
  }, []);

  const mergeData = (originalData, sharedData) => {
    return originalData.map(original => {
      const shared = sharedData.find(s => s.sampleName === original.sampleName);
      return { ...original, ...shared };
    });
  };

  const mergedFeedbackData = mergeData(feedbackDataThesisConcatenatedSpanish, sharedRatingsData);

  const currentFeedbackData = randomSample ? mergedFeedbackData.filter((data) => data.sampleName === randomSample.sampleName) : [];

  const handleSubmit = (formData) => {
    const updatedFormData = {
      ...formData,
      sampleName: randomSample.sampleName
    };
    setFormData(updatedFormData);
    setFeedbackVisible(true);
    window.scrollTo(0, 0);
  };

  const handleRestart = () => {
    const randomIndex = Math.floor(Math.random() * feedbackDataThesisConcatenatedSpanish.length);
    setRandomSample(feedbackDataThesisConcatenatedSpanish[randomIndex]);
    setFormData(null);
    setFeedbackVisible(false);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <h1>{t('cape_v_training_title')}</h1>
      {randomSample && (
        <div className="audio-container">
          <audio controls src={randomSample.sampleLink}>
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
      {!feedbackVisible && (
        <>
          <p>{t('cape_v_training_specific_instructions')}</p>
          <p>{t('cape_v_general_instructions')}</p>
          <br/>
          <CAPEVForm onSubmit={handleSubmit} />
        </>
      )}
      {feedbackVisible && (
        <div className="feedback-screen">
          <p>{t('cape_v_training_feedback_introduction')}</p>
          <FeedbackCapeV feedbackData={currentFeedbackData} formData={formData} t={t} />
          <div className="button-container">
            <button onClick={handleRestart} className="navigation-button">{t('restart_with_another_sample')}</button>
          </div>
        </div>
      )}
      <br/>
      <div className="cites">
        <CAPEVCitation />
      </div>
      <br /> 
    </div>
  );
}

export default CapeVEntrenamiento;