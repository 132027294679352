// RankingsTable.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import countryOptions from "../../../constants/countryOptions";

const RankingsTable = ({ data = [] }) => {
  const { t } = useTranslation();
  const tableRef = useRef();

  useEffect(() => {
    const container = d3.select(tableRef.current);
    container.selectAll('*').remove();

    if (!data || data.length === 0) {
      container.append('p')
        .text(t('no_data_available'))
        .style('font-size', '12px');
      return;
    }

    // Ordenem les dades de manera descendent pel camp count
    const sortedData = [...data].sort((a, b) => b.count - a.count);

    // Creem la taula
    const table = container.append('table')
      .style('width', '100%')
      .style('border-collapse', 'collapse')
      .style('font-size', '14px');

    // Capçalera de la taula: Assignem l'amplada al th de la columna 2 (índex 1)
    const headerData = [t('position'), t('country'), t('assessment_count')];
    const thead = table.append('thead');
    thead.append('tr')
      .selectAll('th')
      .data(headerData)
      .enter()
      .append('th')
      .style('padding', '4px')
      .style('border-bottom', '1px solid #ccc')
      .style('text-align', 'left')
      .style('width', (d, i) => i === 1 ? '75%' : null)
      .text(d => d);

    // Cos de la taula: Per la columna del país li assignem també l'amplada
    const tbody = table.append('tbody');
    sortedData.forEach((d, index) => {
      const row = tbody.append('tr');
      
      // Posició (rànquing)
      row.append('td')
        .style('padding', '4px')
        .style('border-bottom', '1px solid #eee')
        .text(index + 1);
      
      // País: utilitzem countryOptions per obtenir el nom i la bandera
      const countryOption = countryOptions.find(opt => opt.value === d._id.toUpperCase());
      const countryName = countryOption ? countryOption.label : t('unknown');
      const flagUrl = countryOption 
        ? `https://flagcdn.com/24x18/${countryOption.value.toLowerCase()}.png`
        : '';
      row.append('td')
        .style('padding', '4px')
        .style('border-bottom', '1px solid #eee')
        .style('width', '75%')
        .html(countryOption 
          ? `<img src="${flagUrl}" alt="${countryName}" style="margin-right:4px; vertical-align:middle;"> ${countryName}`
          : t('unknown'));
      
      // Nombre d’avaluacions
      row.append('td')
        .style('padding', '4px')
        .style('border-bottom', '1px solid #eee')
        .text(d.count);
    });
  }, [data, t]);

  return <div ref={tableRef}></div>;
};

export default RankingsTable;