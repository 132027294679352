// StandardVocalAttributesRating.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';
import VASRatingInput from '../../../../../components/form-items/VASRatingInput';

function StandardVocalAttributesRating({
  overallSeverity,
  setOverallSeverity,
  roughness,
  setRoughness,
  breathiness,
  setBreathiness,
  strain,
  setStrain,
}) {
  const t = useFixedTForResource('CAPEVR');

  return (
    <div className="capevr-rating">
      {/* Etiquetes per als extrems de la escala */}
      <div className="capevr-scale-container">
        <span className="capevr-scale-label capevr-scale-left">{t('normal')}</span>
        <span className="capevr-scale-label capevr-scale-right">{t('extreme')}</span>
      </div>
      
      {/* Els 4 sliders del CAPE-Vr */}
      <VASRatingInput
        label={t('overall_severity')}
        VASValue={overallSeverity} 
        setVASValue={setOverallSeverity}
        index={0}
      />
     <VASRatingInput
        label={t('roughness')}
        VASValue={roughness}
        setVASValue={setRoughness}
        index={1}
      />
      <VASRatingInput
        label={t('breathiness')}
        VASValue={breathiness}
        setVASValue={setBreathiness}
        index={2}
      />
      <VASRatingInput
        label={t('strain')}
        VASValue={strain}
        setVASValue={setStrain}
        index={3}
      />
    </div>
  );
}

export default StandardVocalAttributesRating;