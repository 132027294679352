// CommentTable.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const CommentCell = ({ userData = {} }) => {
    const { t } = useTranslation();
    const tableRef = useRef();

    useEffect(() => {
        const tableContainer = d3.select(tableRef.current);
        tableContainer.selectAll('*').remove();

        const userComment = userData?.overallImpression ?? t('no_comments_available');

        // 🔹 Crea la taula
        const table = tableContainer.append('table')
            .style('width', '100%')
            .style('border-collapse', 'collapse')
            .style('font-size', '14px');

        // 🔹 Cos de la taula amb una sola fila i cel·la
        const tbody = table.append('tbody');
        const row = tbody.append('tr');

        row.append('td')
            .style('padding', '4px')
            .style('border-bottom', '1px solid #eee')
            .style('text-align', 'left')
            .style('width', '100%')
            .text(userComment);
    }, [userData, t]);

    return <div ref={tableRef}></div>;
};

export default CommentCell;