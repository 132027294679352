// AttributeStatsTable.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { variableTranslations } from '../../constants/variableTranslations';

const AttributeStatsTable = ({
  userData = null,
  assessments = [],
  sessionEvaluations = [],
  consensusEvaluation = null,
  attributeKeys = [],
}) => {
  const { t } = useTranslation();
  const tableRef = useRef();

  const calculateStats = (values) => {
    if (values.length === 0) return { min: '-', q1: '-', median: '-', q3: '-', max: '-' };
    return {
      min: d3.min(values),
      q1: d3.quantile(values, 0.25),
      median: d3.median(values),
      q3: d3.quantile(values, 0.75),
      max: d3.max(values),
    };
  };

  useEffect(() => {
    const tableContainer = d3.select(tableRef.current);
    tableContainer.selectAll('*').remove();

    const dataSources = [
      ...(userData ? [userData] : []),
      ...assessments,
      ...sessionEvaluations,
      ...(consensusEvaluation ? [consensusEvaluation] : [])
    ];

    const aggregatedData = new Map();

    dataSources.forEach(source => {
      Object.entries(source?.perceptualData || {}).forEach(([key, attr]) => {
        if (!attributeKeys.includes(key)) return;

        const label = attr.attributeName || key;
        const identifier = `${key}::${label.trim().toLowerCase()}`;

        if (!aggregatedData.has(identifier)) {
          aggregatedData.set(identifier, {
            attribute: variableTranslations[label] ? t(variableTranslations[label]) : label,
            present: 0,
            consistent: 0,
            intermittent: 0,
            vasValues: []
          });
        }

        const entry = aggregatedData.get(identifier);

        if (attr.isPresent === true) entry.present += 1;
        if (attr.isConsistent === true) {
          entry.consistent += 1;
        } else if (attr.isConsistent === false) {
          entry.intermittent += 1;
        }

        if (attr.VASValue !== undefined && attr.VASValue !== null) {
          entry.vasValues.push(attr.VASValue);
        }
      });
    });

    const data = Array.from(aggregatedData.values()).map(item => ({
      ...item,
      stats: calculateStats(item.vasValues)
    }));

    const totalCount = data.reduce(
      (sum, item) => sum + item.present + item.consistent + item.intermittent,
      0
    );

    if (totalCount === 0) {
      tableContainer.append('p')
        .text(t('no_statistical_data_available'))
        .style('font-size', '12px')
        .style('text-align', 'center');
      return;
    }

    const table = tableContainer.append('table')
      .style('width', '100%')
      .style('border-collapse', 'collapse')
      .style('font-size', '13px');

    table.append('thead').append('tr')
      .selectAll('th')
      .data([
        t('attribute'),
        t('present'),
        t('consistent'),
        t('intermittent'),
        'Min', 'Q1', t('median'), 'Q3', 'Max'
      ])
      .enter().append('th')
      .style('border-bottom', '1px solid #ccc')
      .style('padding', '4px')
      .style('text-align', 'left')
      .text(d => d);

    const tbody = table.append('tbody');

    data.forEach(({ attribute, present, consistent, intermittent, stats }) => {
      const row = tbody.append('tr');
      row.selectAll('td')
        .data([
          attribute, present, consistent, intermittent,
          stats.min, stats.q1, stats.median, stats.q3, stats.max
        ])
        .enter().append('td')
        .style('padding', '4px')
        .style('border-bottom', '1px solid #eee')
        .text(d => d);
    });

  }, [userData, assessments, sessionEvaluations, consensusEvaluation, attributeKeys, t]);

  return <div ref={tableRef}></div>;
};

export default AttributeStatsTable;