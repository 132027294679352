// WVDParticipate.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetchSamples from './../../hooks/useFetchSamples'; 
import CAPEVRForm from './../cape-vr/cape-vr-views/CAPEVRForm';
import CAPEVRRatings from './../cape-vr/cape-vr-views/CAPEVRRatings';
import FullFeedback from './../../components/feedback/FullFeedback';
import CAPEVRCitation from './../../components/citations/CAPEVRCitation';
import WaldenCitation from './../../components/citations/WaldenCitation';

function WVDParticipate() {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  // Per a waldenPVQD, defineix l'ID de la base de dades conegut (exemple)
  const waldenDatabaseId = '67b7575a6c7397bd463488d9';

  // Utilitzem el hook per obtenir les mostres actives
  const { samples, loading, error } = useFetchSamples(waldenDatabaseId, apiUrl);

  const [currentStep, setCurrentStep] = useState("loading"); // "loading", "form", "ratings", "feedback", "error"
  const [selectedSample, setSelectedSample] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);

  // Quan les mostres ja estiguin carregades, tria una mostra aleatòria
  useEffect(() => {
    if (!loading) {
      if (samples.length > 0) {
        const randomSample = samples[Math.floor(Math.random() * samples.length)];
        setSelectedSample(randomSample);
        setCurrentStep("form");
      } else if (error) {
        // Esperem 500 ms per assegurar-nos que no és un error temporal
        const timer = setTimeout(() => {
          setCurrentStep("error");
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [loading, samples, error]);

  const handleSubmit = (data) => {
    if (data?.goBack) {
      setSelectedSample(null);
      setCurrentStep("loading");
    } else {
      setSubmittedData(data);
      setCurrentStep("ratings");
    }
  };

  return (
    <div className="wvd-participate-container" style={{ padding: '16px', textAlign: 'center' }}>
      <img src="/images/wvd-date.png" alt="WVD Icon" style={{ maxWidth: '150px' }} />
      <h2 style={{ color: '#ac1e1a' }}>{t('global_perceptual_voice_assessment_marathon')}</h2>

      {currentStep === "loading" && <p>{t('loading')}</p>}
      {currentStep === "error" && <p style={{ color: 'red' }}>{t('error_fetching_samples')}: {error}</p>}

      {/* Formulari d'avaluació */}
      {currentStep === "form" && selectedSample && (
        <CAPEVRForm
          apiUrl={apiUrl}
          selectedSample={selectedSample}
          sampleDatabase="waldenPVQD"
          onSubmit={handleSubmit}
        />
      )}

      {/* Resultats d'avaluació */}
      {currentStep === "ratings" && (
        <CAPEVRRatings
          submittedData={submittedData}
          sampleDatabase="waldenPVQD"
          onGoToFeedback={() => setCurrentStep("feedback")}
          onRestart={() => setCurrentStep("loading")}
        />
      )}

      {/* Feedback */}
      {currentStep === "feedback" && (
        <FullFeedback
          apiUrl={apiUrl}
          selectedSample={selectedSample}
          userEvaluation={submittedData}
          onGoToForm={() => setCurrentStep("loading")}
          isWVD={true}
        />
      )}

      <br />

      {/* Cites */}
      <div className="cites">
        <CAPEVRCitation />
        <WaldenCitation />
      </div>
    </div>
  );
}

export default WVDParticipate;