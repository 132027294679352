// PitchLoudnessEvaluation.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';

function PitchLoudnessEvaluation({
  normalPitch,
  setNormalPitch,
  tooHighPitch,
  setTooHighPitch,
  tooLowPitch,
  setTooLowPitch,
  normalLoudness,
  setNormalLoudness,
  tooLoudLoudness,
  setTooLoudLoudness,
  tooQuietLoudness,
  setTooQuietLoudness,
}) {
  const t = useFixedTForResource('CAPEVR');

  const handlePitchSelection = (type) => {
    setNormalPitch({ ...normalPitch, isPresent: type === 'normal' });
    setTooHighPitch({ ...tooHighPitch, isPresent: type === 'tooHigh' });
    setTooLowPitch({ ...tooLowPitch, isPresent: type === 'tooLow' });
  };

  const handleLoudnessSelection = (type) => {
    setNormalLoudness({ ...normalLoudness, isPresent: type === 'normal' });
    setTooLoudLoudness({ ...tooLoudLoudness, isPresent: type === 'tooLoud' });
    setTooQuietLoudness({ ...tooQuietLoudness, isPresent: type === 'tooSoft' });
  };

  return (
    <div>
      {/* Pitch Evaluation */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('pitch')}:</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="pitch"
                checked={normalPitch.isPresent}
                onChange={() => handlePitchSelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="pitch"
                checked={tooLowPitch.isPresent}
                onChange={() => handlePitchSelection('tooLow')}
              />
              {t('low')}
            </label>
            <label>
              <input
                type="radio"
                name="pitch"
                checked={tooHighPitch.isPresent}
                onChange={() => handlePitchSelection('tooHigh')}
              />
              {t('high')}
            </label>
          </div>

          {/* Comentari associat a Pitch */}
          {(normalPitch.isPresent || tooLowPitch.isPresent || tooHighPitch.isPresent) && (
            <textarea
              id="pitchComment"
              value={
                normalPitch.isPresent
                  ? normalPitch.attributeComment
                  : tooLowPitch.isPresent
                  ? tooLowPitch.attributeComment
                  : tooHighPitch.isPresent
                  ? tooHighPitch.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalPitch.isPresent) {
                  setNormalPitch({ ...normalPitch, attributeComment: e.target.value });
                } else if (tooLowPitch.isPresent) {
                  setTooLowPitch({ ...tooLowPitch, attributeComment: e.target.value });
                } else if (tooHighPitch.isPresent) {
                  setTooHighPitch({ ...tooHighPitch, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>

      {/* Loudness Evaluation */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('loudness')}:</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="loudness"
                checked={normalLoudness.isPresent}
                onChange={() => handleLoudnessSelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="loudness"
                checked={tooQuietLoudness.isPresent}
                onChange={() => handleLoudnessSelection('tooSoft')}
              />
              {t('soft')}
            </label>
            <label>
              <input
                type="radio"
                name="loudness"
                checked={tooLoudLoudness.isPresent}
                onChange={() => handleLoudnessSelection('tooLoud')}
              />
              {t('loud')}
            </label>
          </div>

          {/* Comentari associat a Loudness */}
          {(normalLoudness.isPresent || tooQuietLoudness.isPresent || tooLoudLoudness.isPresent) && (
            <textarea
              id="loudnessComment"
              value={
                normalLoudness.isPresent
                  ? normalLoudness.attributeComment
                  : tooQuietLoudness.isPresent
                  ? tooQuietLoudness.attributeComment
                  : tooLoudLoudness.isPresent
                  ? tooLoudLoudness.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalLoudness.isPresent) {
                  setNormalLoudness({ ...normalLoudness, attributeComment: e.target.value });
                } else if (tooQuietLoudness.isPresent) {
                  setTooQuietLoudness({ ...tooQuietLoudness, attributeComment: e.target.value });
                } else if (tooLoudLoudness.isPresent) {
                  setTooLoudLoudness({ ...tooLoudLoudness, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PitchLoudnessEvaluation;