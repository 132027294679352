// CAPEVRForm.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFixedTForResource } from '../../../locales/useFixedTForResource';
import ResourceLanguageWarning from '../../../locales/ResourceLanguageWarning';
import { useUserData } from './../../../hooks/useUserData';
import SharingConsentModal from './../../../components/modals/SharingConsentModal';
import Demographics from './cape-vr-files/cape-vr-form-files/Demographics';
import RecordingConditions from './cape-vr-files/cape-vr-form-files/RecordingConditions';
import VocalTasks from './cape-vr-files/cape-vr-form-files/VocalTasks';
import RatingConditions from './cape-vr-files/cape-vr-form-files/RatingConditions';
import StandardVocalAttributesRating from './cape-vr-files/cape-vr-form-files/StandardVocalAttributesRating';
import OtherVocalAttributesRating from './cape-vr-files/cape-vr-form-files/OtherVocalAttributesRating';
import PitchLoudnessEvaluation from './cape-vr-files/cape-vr-form-files/PitchLoudnessEvaluation';
import ResonanceNasalityEvaluation from './cape-vr-files/cape-vr-form-files/ResonanceNasalityEvaluation';
import InconsistenciesEvaluation from './cape-vr-files/cape-vr-form-files/InconsistenciesEvaluation';
import InstabilitiesEvaluation from './cape-vr-files/cape-vr-form-files/InstabilitiesEvaluation';
import AdditionalFeaturesEvaluation from './cape-vr-files/cape-vr-form-files/AdditionalFeaturesEvaluation';
import OverallImpression from './cape-vr-files/cape-vr-form-files/OverallImpression';

function CAPEVRForm({ apiUrl, selectedSample, sampleDatabase, onSubmit }) {
  const t = useFixedTForResource('CAPEVR');

  const { sociodemographicData } = useUserData(apiUrl);

  // 🔹 Estat per gestionar l'obertura del SharingConsentModal
  const [isSharingConsentModalOpen, setIsSharingConsentModalOpen] = useState(false);
  const [pendingSharedAssessment, setPendingSharedAssessment] = useState(null);

  // Destructurem les dades de l'usuari autenticat per fer-les servir a `raterData`
  const {
    age: raterAge,
    pronoun: raterPronoun,
    countryOfResidence: raterCountry,
    communicationLanguage: raterLanguage,
    profession: raterProfession,
    voiceCompetenceLevel: raterVoiceCompetence,
    wantsToShareEvaluations, 
    acceptResearchUsage, 
  } = sociodemographicData || {};

  // Estats per a Demographics
  const [subjectNameId, setSubjectNameId] = useState('');
  const [subjectGender, setSubjectGender] = useState('');
  const [subjectAge, setSubjectAge] = useState('');
  const [examinerId, setExaminerId] = useState('');
  const [recordingDate, setRecordingDate] = useState('');

  // Estats per a RecordingConditions
  const [isAudioRecorded, setIsAudioRecorded] = useState('');
  const [sessionModality, setSessionModality] = useState('');
  const [recordingEnvironment, setRecordingEnvironment] = useState('');
  const [recordingSetup, setRecordingSetup] = useState('');
  const [mouthToMicDistanceCm, setMouthToMicDistanceCm] = useState('');

  // Estats per a CAPEVRVocalTasks
  const [areSentencesModeled, setAreSentencesModeled] = useState(false);
  const [readingPassage, setReadingPassage] = useState('');

  // Estats per a RatingConditions
  const [voiceSource, setVoiceSource] = useState('');
  const [listeningDevice, setListeningDevice] = useState('');
  const [auditoryAnchors, setAuditoryAnchors] = useState('');
  const [raterId, setRaterId] = useState('');
  const [ratingDate, setRatingDate] = useState('');
  const [timesPlayed, setTimesPlayed] = useState('');

  // Estats per a StandardVocalAttributesRating
  const [overallSeverity, setOverallSeverity] = useState(0);
  const [roughness, setRoughness] = useState(0);
  const [breathiness, setBreathiness] = useState(0);
  const [strain, setStrain] = useState(0);

  // Estats per a OtherVocalAttributesRating 
  const [instabilitiesValues, setInstabilitiesValues] = useState({});
  const [additionalFeaturesValues, setAdditionalFeaturesValues] = useState({});

  // Estats per a Pitch
  const [normalPitch, setNormalPitch] = useState({ isPresent: false, attributeComment: '' });
  const [tooHighPitch, setTooHighPitch] = useState({ isPresent: false, attributeComment: '' });
  const [tooLowPitch, setTooLowPitch] = useState({ isPresent: false, attributeComment: '' });

  // Estats per a Loudness
  const [normalLoudness, setNormalLoudness] = useState({ isPresent: false, attributeComment: '' });
  const [tooLoudLoudness, setTooLoudLoudness] = useState({ isPresent: false, attributeComment: '' });
  const [tooQuietLoudness, setTooQuietLoudness] = useState({ isPresent: false, attributeComment: '' });

  // Estats per a ResonanceNasalityEvaluation
  const [normalResonance, setNormalResonance] = useState({ isPresent: false, attributeComment: '' });
  const [frontResonance, setFrontResonance] = useState({ isPresent: false, attributeComment: '' });
  const [backResonance, setBackResonance] = useState({ isPresent: false, attributeComment: '' });
  const [normalNasality, setNormalNasality] = useState({ isPresent: false, attributeComment: '' });
  const [hypernasality, setHypernasality] = useState({ isPresent: false, attributeComment: '' });
  const [hyponasality, setHyponasality] = useState({ isPresent: false, attributeComment: '' });

  // Estats per a InconsistenciesEvaluation
  const [vowelsInconsistency, setVowelsInconsistency] = useState({ isPresent: false, attributeComment: '' });
  const [sentencesInconsistency, setSentencesInconsistency] = useState({ isPresent: false, attributeComment: '' });
  const [extemporaneousSpeechInconsistency, setExtemporaneousSpeechInconsistency] = useState({ isPresent: false, attributeComment: '' });

  // Estats per a InstabilitiesEvaluation
  const [selectedInstabilities, setSelectedInstabilities] = useState([]);

  // Estats per a AdditionalFeaturesEvaluation
  const [selectedAdditionalFeatures, setSelectedAdditionalFeatures] = useState([]);

  // Estat per a OverallImpression
  const [overallImpressionComment, setOverallImpressionComment] = useState('');

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setRatingDate(today);
  }, []); 

  useEffect(() => {
    if (sampleDatabase !== 'upload' && selectedSample) {

        setSubjectNameId(selectedSample.subjectId?.subjectNameId || '');
        setSubjectGender(selectedSample.subjectId?.subjectProfile?.gender || '');
        setSubjectAge(selectedSample.subjectId?.subjectProfile?.age || '');
        setExaminerId(selectedSample.identificationData?.examinerId || '');
        setRecordingDate(selectedSample.identificationData?.recordingDate || '');

        if (selectedSample.identificationData?.voiceDatabaseId?.recordingConditions) {
            const rc = selectedSample.identificationData.voiceDatabaseId.recordingConditions;
            setIsAudioRecorded(rc.isAudioRecorded ? "yes" : "no");
            setSessionModality(rc.sessionModality || '');
            setRecordingEnvironment(rc.recordingEnvironment || '');
            setRecordingSetup(rc.recordingSetup || '');
            setMouthToMicDistanceCm(rc.mouthToMicDistanceCm || '');
        }

        setVoiceSource("recordedVoice");
    }
}, [selectedSample, sampleDatabase]);

const handleSubmit = async (event) => {
  event.preventDefault();

    const voiceAssessment = {
      userId: sociodemographicData?.id || null,
      voiceSampleId: selectedSample?._id || null,
      voiceDatabaseId: selectedSample?.identificationData?.voiceDatabaseId || null,
      subjectId: selectedSample?.subjectId || null,
      isSharedForFeedback: false,

      raterData: {
        age: raterAge,
        pronoun: raterPronoun,
        countryOfResidence: raterCountry,
        communicationLanguage: raterLanguage,
        profession: raterProfession,
        voiceCompetenceLevel: raterVoiceCompetence,
      },

      ratingConditions: {
        voiceSource: voiceSource || null,
        listeningDevice: listeningDevice || null,
        auditoryAnchors: auditoryAnchors === "yes" ? true : auditoryAnchors === "no" ? false : null,
        ratingDate,
        timesPlayed,
      },

      evaluationTool: "67cebba8ce47613f6ee36024",

      perceptualData: {
        overallSeverity: { attributeName: "overallSeverity", isPresent: overallSeverity > 15, VASValue: overallSeverity },
        roughness: { attributeName: "roughness", isPresent: roughness > 14, VASValue: roughness },
        breathiness: { attributeName: "breathiness", isPresent: breathiness > 13, VASValue: breathiness },
        strain: { attributeName: "strain", isPresent: strain > 15, VASValue: strain },
  
        normalPitch: { attributeName: "normalPitch", ...normalPitch },
        tooHighPitch: { attributeName: "tooHighPitch", ...tooHighPitch },
        tooLowPitch: { attributeName: "tooLowPitch", ...tooLowPitch },
  
        normalLoudness: { attributeName: "normalLoudness", ...normalLoudness },
        tooLoudLoudness: { attributeName: "tooLoudLoudness", ...tooLoudLoudness },
        tooQuietLoudness: { attributeName: "tooQuietLoudness", ...tooQuietLoudness },
  
        normalResonance: { attributeName: "normalResonance", ...normalResonance },
        frontResonance: { attributeName: "frontResonance", ...frontResonance },
        backResonance: { attributeName: "backResonance", ...backResonance },
        normalNasality: { attributeName: "normalNasality", ...normalNasality },
        hypernasality: { attributeName: "hypernasality", ...hypernasality },
        hyponasality: { attributeName: "hyponasality", ...hyponasality },
  
        aphonicBreak: { attributeName: "aphonicBreak", isPresent: selectedInstabilities.includes("aphonicBreak"), VASValue: additionalFeaturesValues.aphonicBreak?.VASValue || 0  },
        pitchBreak: { attributeName: "pitchBreak", isPresent: selectedInstabilities.includes("pitchBreak"), VASValue: additionalFeaturesValues.pitchBreak?.VASValue || 0  },
        pitchInstability: { attributeName: "pitchInstability", isPresent: selectedInstabilities.includes("pitchInstability"), VASValue: additionalFeaturesValues.pitchInstability?.VASValue || 0  },
        spasm: { attributeName: "spasm", isPresent: selectedInstabilities.includes("spasm"), VASValue: additionalFeaturesValues.spasm?.VASValue || 0  },
        tremor: { attributeName: "tremor", isPresent: selectedInstabilities.includes("tremor"), VASValue: additionalFeaturesValues.tremor?.VASValue || 0  },
        otherInstability: {
          attributeName: instabilitiesValues.otherInstability?.attributeName || t('otherInstability'),
          isPresent: selectedInstabilities.includes("otherInstability"),
          VASValue: instabilitiesValues.otherInstability?.VASValue || 0
        },        
  
        aphonia: { attributeName: "aphonia", isPresent: selectedAdditionalFeatures.includes("aphonia"), VASValue: additionalFeaturesValues.aphonia?.VASValue || 0  },
        asthenia: { attributeName: "asthenia", isPresent: selectedAdditionalFeatures.includes("asthenia"), VASValue: additionalFeaturesValues.asthenia?.VASValue || 0  },
        diplophonia: { attributeName: "diplophonia", isPresent: selectedAdditionalFeatures.includes("diplophonia"), VASValue: additionalFeaturesValues.diplophonia?.VASValue || 0  },
        falsetto: { attributeName: "falsetto", isPresent: selectedAdditionalFeatures.includes("falsetto"), VASValue: additionalFeaturesValues.falsetto?.VASValue || 0  },
        fry: { attributeName: "fry", isPresent: selectedAdditionalFeatures.includes("fry"), VASValue: additionalFeaturesValues.fry?.VASValue || 0  },
        hardGlottalAttack: { attributeName: "hardGlottalAttack", isPresent: selectedAdditionalFeatures.includes("hardGlottalAttack"), VASValue: additionalFeaturesValues.hardGlottalAttack?.VASValue || 0  },
        wetGurgly: { attributeName: "wetGurgly", isPresent: selectedAdditionalFeatures.includes("wetGurgly"), VASValue: additionalFeaturesValues.wetGurgly?.VASValue || 0  },
        otherAdditionalFeature: {
          attributeName: additionalFeaturesValues.otherAdditionalFeature?.attributeName || t('otherAdditionalFeature'),
          isPresent: selectedAdditionalFeatures.includes("otherAdditionalFeature"),
          VASValue: additionalFeaturesValues.otherAdditionalFeature?.VASValue || 0
        },        
  
        vowelsInconsistency: { attributeName: "vowelsInconsistency", ...vowelsInconsistency },
        sentencesInconsistency: { attributeName: "sentencesInconsistency", ...sentencesInconsistency },
        extemporaneousSpeechInconsistency: { attributeName: "extemporaneousSpeechInconsistency", ...extemporaneousSpeechInconsistency },
  
        overallImpression: overallImpressionComment,
      },
    };

    if (!selectedSample || sampleDatabase === "upload") {
      console.log("🚫 Mostra penjada o sense cap mostra seleccionada. Actualitzem localment sense modal ni POST a DB.");
      onSubmit(voiceAssessment);
      return;
    }
  
    if (acceptResearchUsage || wantsToShareEvaluations) {
      // Si hi ha consentiment, obre el modal i no crida onSubmit immediatament
      setPendingSharedAssessment(voiceAssessment);
      setIsSharingConsentModalOpen(true);
    } else {
      // Si no hi ha consentiment, actualitza localment i no envia a DB.
      onSubmit(voiceAssessment);
    }
  };
  
  const handleConfirmSharing = async ({ shareForFeedback, researchUsage }) => {
    if (!pendingSharedAssessment) {
      console.warn("⚠️ No hi ha dades pendents per compartir.");
      return;
    }
  
    // Si l'usuari no dóna consentiment, tanquem la modal i actualitzem la vista localment
    if (!shareForFeedback && !researchUsage) {
      console.log("L'usuari no dóna consentiment. No s'envia a la base de dades.");
      setIsSharingConsentModalOpen(false);
      onSubmit(pendingSharedAssessment); // Actualitza la vista (ratings/feedback) localment
      return;
    }
  
    const assessmentToSubmit = {
      ...pendingSharedAssessment,
      isSharedForFeedback: shareForFeedback,
      acceptResearchUsage: researchUsage,
    };
  
    console.log("📤 Dades enviades a /voice-assessments:", JSON.stringify(assessmentToSubmit, null, 2));
  
    try {
      await axios.post(`${apiUrl}/voice-assessments`, assessmentToSubmit, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("✅ Dades enviades correctament.");
      onSubmit(assessmentToSubmit);
    } catch (error) {
      console.error("⚠️ Error en l'enviament de dades compartides:", error);
      if (error.response) {
        console.error("📩 Resposta del servidor:", error.response.data);
      }
    } finally {
      setIsSharingConsentModalOpen(false);
    }
  };  

  return (
    <form className="capevr-form" onSubmit={handleSubmit}>
      
      <ResourceLanguageWarning resourceKey="CAPEVR" />
      
      <div className="capevr-section">
        <Demographics
          subjectNameId={subjectNameId}
          setSubjectNameId={setSubjectNameId}
          subjectGender={subjectGender}
          setSubjectGender={setSubjectGender}
          subjectAge={subjectAge}
          setSubjectAge={setSubjectAge}
          examinerId={examinerId}
          setExaminerId={setExaminerId}
          recordingDate={recordingDate}
          setRecordingDate={setRecordingDate}
        />
      </div>
      
      <div className="capevr-section">
        <h2>{t('recording_conditions')}</h2>
        <RecordingConditions
          isAudioRecorded={isAudioRecorded}
          setIsAudioRecorded={setIsAudioRecorded}
          sessionModality={sessionModality}
          setSessionModality={setSessionModality}
          recordingEnvironment={recordingEnvironment}
          setRecordingEnvironment={setRecordingEnvironment}
          recordingSetup={recordingSetup}
          setRecordingSetup={setRecordingSetup}
          mouthToMicDistanceCm={mouthToMicDistanceCm}
          setMouthToMicDistanceCm={setMouthToMicDistanceCm}
        />
      </div>

      <div className="capevr-section">
        <h2>{t('stimuli')}</h2>
        <VocalTasks
          areSentencesModeled={areSentencesModeled}
          setAreSentencesModeled={setAreSentencesModeled}
          readingPassage={readingPassage} 
          setReadingPassage={setReadingPassage}
        />
      </div>

      <div className="capevr-section">
        <h2>{t('rating_conditions')}</h2>
        <RatingConditions
          voiceSource={voiceSource}
          setVoiceSource={setVoiceSource}
          listeningDevice={listeningDevice}
          setListeningDevice={setListeningDevice}
          auditoryAnchors={auditoryAnchors}
          setAuditoryAnchors={setAuditoryAnchors}
          raterId={raterId}
          setRaterId={setRaterId}
          ratingDate={ratingDate}
          setRatingDate={setRatingDate}
          timesPlayed={timesPlayed}
          setTimesPlayed={setTimesPlayed}
        />
      </div>

      <div className="capevr-section">
        {selectedSample && (
          <div className="audio-container">
            <h2>{t("sample")}</h2>
            {selectedSample.sampleLink ? (
              <audio controls src={selectedSample.sampleLink}></audio>
            ) : (
              <p>{t("no_audio_for_sample")}</p> 
            )}
          </div>
        )}
      </div>
      
      {!selectedSample && (
        <div className="button-container capevr-section">
          <h2>{t("sample")}</h2>
          <button 
            type="button" 
            className="button-center"
            onClick={() => onSubmit({ goBackToSampleSelection: true })}
          >
            {t("choose_a_sample_to_evaluate")}
          </button>
        </div>
      )}

      <div className="capevr-section">
        <h2>{t('rating_form')}</h2>
        <StandardVocalAttributesRating
          overallSeverity={overallSeverity}
          setOverallSeverity={setOverallSeverity}
          roughness={roughness}
          setRoughness={setRoughness}
          breathiness={breathiness}
          setBreathiness={setBreathiness}
          strain={strain}
          setStrain={setStrain}
        />
      </div>

      <div className="capevr-section">
        <OtherVocalAttributesRating
          selectedInstabilities={selectedInstabilities}
          instabilitiesValues={instabilitiesValues}
          setInstabilitiesValues={setInstabilitiesValues}
          selectedAdditionalFeatures={selectedAdditionalFeatures}
          additionalFeaturesValues={additionalFeaturesValues}
          setAdditionalFeaturesValues={setAdditionalFeaturesValues}
        />
      </div>

      <div className="capevr-section">
        <PitchLoudnessEvaluation
          normalPitch={normalPitch}
          setNormalPitch={setNormalPitch}
          tooHighPitch={tooHighPitch}
          setTooHighPitch={setTooHighPitch}
          tooLowPitch={tooLowPitch}
          setTooLowPitch={setTooLowPitch}
          normalLoudness={normalLoudness}
          setNormalLoudness={setNormalLoudness}
          tooLoudLoudness={tooLoudLoudness}
          setTooLoudLoudness={setTooLoudLoudness}
          tooQuietLoudness={tooQuietLoudness}
          setTooQuietLoudness={setTooQuietLoudness}
        />
      </div>

      <div className="capevr-section">
        <ResonanceNasalityEvaluation
          normalResonance={normalResonance}
          setNormalResonance={setNormalResonance}
          frontResonance={frontResonance}
          setFrontResonance={setFrontResonance}
          backResonance={backResonance}
          setBackResonance={setBackResonance} 
          normalNasality={normalNasality}
          setNormalNasality={setNormalNasality}
          hypernasality={hypernasality}
          setHypernasality={setHypernasality}
          hyponasality={hyponasality}
          setHyponasality={setHyponasality}
        />
      </div>
      <div className="capevr-section">
      <div className="capevr-section">
        <InconsistenciesEvaluation
          vowelsInconsistency={vowelsInconsistency}
          setVowelsInconsistency={setVowelsInconsistency}
          sentencesInconsistency={sentencesInconsistency}
          setSentencesInconsistency={setSentencesInconsistency}
          extemporaneousSpeechInconsistency={extemporaneousSpeechInconsistency}
          setExtemporaneousSpeechInconsistency={setExtemporaneousSpeechInconsistency}
        />
      </div>
      </div>
      <div className="capevr-section">
        <InstabilitiesEvaluation
          selectedInstabilities={selectedInstabilities}
          setSelectedInstabilities={setSelectedInstabilities}
          instabilitiesValues={instabilitiesValues} 
          setInstabilitiesValues={setInstabilitiesValues} 
        />
      </div>
      <div className="capevr-section">
        <AdditionalFeaturesEvaluation
          selectedAdditionalFeatures={selectedAdditionalFeatures}
          setSelectedAdditionalFeatures={setSelectedAdditionalFeatures}
          additionalFeaturesValues={additionalFeaturesValues} 
          setAdditionalFeaturesValues={setAdditionalFeaturesValues} 
        />
      </div>
      <div className="capevr-section">
        <OverallImpression
          overallImpressionComment={overallImpressionComment}
          setOverallImpressionComment={setOverallImpressionComment}
        />
      </div>

      <div className="button-container">
      <button className="button-center" type="submit"> {t('submit')}</button>
      </div>

        {/* 🔹 SharingConsentModal per compartir dades */}
        <SharingConsentModal 
          isOpen={isSharingConsentModalOpen} 
          onRequestClose={() => setIsSharingConsentModalOpen(false)}
          onSubmit={handleConfirmSharing} 
          initialShareForFeedback={sociodemographicData?.wantsToShareEvaluations || false}
          initialResearchUsage={sociodemographicData?.acceptResearchUsage || false}
          voiceCompetenceLevel={sociodemographicData?.voiceCompetenceLevel}  
        />

    </form>
  );
}

export default CAPEVRForm;