// ContactFormModal.jsx

import React from 'react';
import Modal from 'react-modal';
import ContactFormComponent from '../forms/contactFormComponent';

Modal.setAppElement('#root');

const ContactFormModal = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Contact Form Modal"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '600px', // Ajusta l'amplada màxima si cal
                    minWidth: '300px', // Amplada mínima per a evitar que sigui massa petit
                    padding: '20px',
                },
            }}
        >
            <ContactFormComponent />
        </Modal>
    );
};

export default ContactFormModal;