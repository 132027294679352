// DatabaseSelector.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

const DatabaseSelector = ({ value, onChange, hideUploadOption = false }) => {
    const { t } = useTranslation();

    return (
        <div>
            <select
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="select"
            >
                <option value="">{t('select_the_sample_database')}</option>
                <option value="waldenPVQD">PVQD (Walden, 2022)</option>
                {!hideUploadOption && (
                    <option value="upload">{t('upload_your_own_sample')}</option>
                )}
                {/* 🔒 Future database options */}
                {/* <option value="neuroVoz">NeuroVoz (2024)</option>
                <option value="saarbrueken">Saarbrueken (2024)</option>
                <option value="Calaf">Calaf (2024)</option> */}
            </select>
        </div>
    );
};

export default DatabaseSelector;