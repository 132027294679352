// LogoutComponent.jsx

import React from 'react';
import { useAuth } from '../../auth/authContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const LogoutComponent = () => {
    const auth = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogout = () => {
        auth.logout();
        navigate('/');  // Redirigeix a la pàgina principal després del logout
    };

    return (
        <span
            onClick={handleLogout}
            className="header-link logout-button"
        >
            {t('logout')}
        </span>
    );
};

export default LogoutComponent;