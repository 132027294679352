// useFetchSamples.js

import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchSamples = (databaseId, apiUrl) => {
    const [samples, setSamples] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSamples = async () => {
            if (!databaseId) {
                console.log("No databaseId provided");
                return;  
            }
            setLoading(true);
            setError(null);
            
            try {
                console.log("Fetching samples from:", `${apiUrl}/voice-samples?voiceDatabaseId=${databaseId}`);
                const response = await axios.get(`${apiUrl}/voice-samples?voiceDatabaseId=${databaseId}`);
                const allSamples = response.data.data || [];
                console.log("Total samples fetched:", allSamples.length);
    
                // Filtra només les mostres actives
                const activeSamples = allSamples.filter(sample => sample.visibility?.isActive === true);
                console.log("Active samples:", activeSamples.length);
    
                setSamples(activeSamples);
            } catch (err) {
                console.error("❌ Error fetching samples:", err);
                setError(err.message || "Error retrieving samples.");
            } finally {
                setLoading(false);
            }
        };
    
        fetchSamples();
    }, [databaseId, apiUrl]);    

    return { samples, loading, error };
};

export default useFetchSamples;