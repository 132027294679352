// SampleManager.jsx

import React, { useState, useEffect } from 'react';
import DatabaseSelector from './DatabaseSelector';
import SampleSelector from './SampleSelector';
import AudioManager from './AudioManager';

const SampleManager = ({ apiUrl, onSampleSelected, onDatabaseChange, hideUploadOption = false }) => {
    const [sampleDatabase, setSampleDatabase] = useState('');
    const [samplePreference, setSamplePreference] = useState('');
    const [databaseId, setDatabaseId] = useState(null);

    // Mapa d'IDs coneguts
    useEffect(() => {
        const databaseMapping = {
            waldenPVQD: '67b7575a6c7397bd463488d9',
            neuroVoz: '67b76b11ef593b0e91dbb139',
            Calaf: '67b76b11ef593b0e91dbb13f',
        };
        setDatabaseId(databaseMapping[sampleDatabase] || null);
        onDatabaseChange(sampleDatabase);
    }, [sampleDatabase, onDatabaseChange]);

    return (
        <div className="button-container">
            <DatabaseSelector 
                value={sampleDatabase} 
                onChange={(value) => {
                    setSampleDatabase(value);
                    onDatabaseChange(value);  
                }} 
                hideUploadOption={hideUploadOption}
            />

            {sampleDatabase && sampleDatabase !== 'upload' && (
                <SampleSelector value={samplePreference} onChange={setSamplePreference} />
            )}

            {(samplePreference === 'random' || samplePreference === 'manual') && databaseId && (
                <AudioManager
                    apiUrl={apiUrl}
                    mode={samplePreference}
                    sampleDatabaseId={databaseId}
                    onSampleSelected={onSampleSelected}
                />
            )}

            {sampleDatabase === 'upload' && (
                <AudioManager mode="upload" onSampleSelected={onSampleSelected} />
            )}
        </div>
    );
};

export default SampleManager;