// LockIcon.jsx

import React from 'react';

const LockIcon = ({ isRestricted, user }) => {
    if (!isRestricted) return null;

    return user ? (
        <i className="fas fa-lock-open" style={{ color: "#a5d3ce", marginLeft: "10px" }}></i>
    ) : (
        <i className="fas fa-lock" style={{ color: "red", marginLeft: "10px" }}></i>
    );
};

export default LockIcon;
