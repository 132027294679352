// AdditionalFeatures.jsx

import React, { useState, useEffect } from 'react';
import { useFixedTForResource } from '../../../../locales/useFixedTForResource';
import i18n from './../../../../locales/i18n';
import { variableTranslations } from './../../../../constants/variableTranslations';

function AdditionalFeatures({ value = {}, onChange = () => {} }) {
  const t = useFixedTForResource('CAPEV');

  const [features, setFeatures] = useState({
    diplophonia: value.diplophonia || { attributeName: variableTranslations.diplophonia, isPresent: false },
    fry: value.fry || { attributeName: variableTranslations.fry, isPresent: false },
    falsetto: value.falsetto || { attributeName: variableTranslations.falsetto, isPresent: false },
    asthenia: value.asthenia || { attributeName: variableTranslations.asthenia, isPresent: false },
    aphonia: value.aphonia || { attributeName: variableTranslations.aphonia, isPresent: false },
    pitchInstability: value.pitchInstability || { attributeName: variableTranslations.pitchInstability, isPresent: false },
    tremor: value.tremor || { attributeName: variableTranslations.tremor, isPresent: false },
    wetGurgly: value.wetGurgly || { attributeName: variableTranslations.wetGurgly, isPresent: false },
    otherAdditionalFeature: value.otherAdditionalFeature || { attributeName: '', isPresent: false }
  });

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      setFeatures(value);
    }
  }, [value]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const updatedFeatures = {
      ...features,
      [name]: { ...features[name], isPresent: checked }
    };
    setFeatures(updatedFeatures);
    onChange(updatedFeatures);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    const updatedFeatures = {
      ...features,
      otherAdditionalFeature: {
        ...features.otherAdditionalFeature,
        isPresent: true,
        attributeName: newText
      }
    };
    setFeatures(updatedFeatures);
    onChange(updatedFeatures);
  };

  const featureKeys = i18n.language === 'jp'
    ? Object.keys(features).filter(key => key !== "wetGurgly")
    : Object.keys(features);

  return (
    <div className="button-container">
      <label className="centered-text"><strong>{t('additional_features')}</strong></label>
      <div style={{ textAlign: 'center' }}>
        {featureKeys.map((feature) => (
          <label key={feature} className="horizontal-labels">
            <input
              type="checkbox"
              name={feature}
              checked={features[feature]?.isPresent || false}
              onChange={handleCheckboxChange}
            />
            {feature === 'otherAdditionalFeature'
              ? features[feature].attributeName || t(variableTranslations[feature])
              : t(variableTranslations[feature])}
            {feature === 'otherAdditionalFeature' && (
              <input
                type="text"
                value={features.otherAdditionalFeature.attributeName || ''}
                onChange={handleTextChange}
                placeholder={t('specify')}
              />
            )}
          </label>
        ))}
      </div>
    </div>
  );
}

export default AdditionalFeatures;