// File: LineWithMarks.jsx
import React from 'react';
import Mark from './Mark';
import useAudioPlayer from '../../../hooks/useAudioPlayer';

const LineWithMarks = ({ blackMarks, blueMarks, currentSelectedMark, onMarkSelection }) => {
  const { playAudio } = useAudioPlayer();

  const handleClick = (value, isBlueMark) => {
    if (isBlueMark && blueMarks.some(mark => mark.value === value)) {
      const audioLink = blueMarks.find(mark => mark.value === value).audioLink;
      playAudio(audioLink); // Play audio if a blue mark is selected
    }
    onMarkSelection(value); // Update selected mark
  };

  const marks = Array.from({ length: 101 }, (_, i) => i);

  return (
    <div className="line">
      {marks.map((mark) => {
        const isBlue = blueMarks.some(blueMark => blueMark.value === mark);
        const isSelected = currentSelectedMark === mark;
        return (
          <Mark
            key={mark}
            value={mark}
            isSelected={isSelected}
            isBlue={isBlue}
            onMarkClick={() => handleClick(mark, isBlue)}
          />
        );
      })}
    </div>
  );
};

export default LineWithMarks;