// ResourcesRepository.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../../locales/i18n';

function ResourcesRepository() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const resources = [

        { title: t('auditory_perceptual_evaluation_tools'), icon: '/images/evaluation-tools-icon.png', route: '/tools' },
        { title: t('interactive_sessions'), icon: '/images/interactive-sessions-icon.png', route: '/interactive-sessions' },
        { title: t('auditory_perceptual_games'), icon: '/images/games-icon.png', route: '/games' },
        { title: t('conceptual_resources'), icon: '/images/concepts-icon.png', route: '/conceptual-resources' },
        //{ title: t('resource_builder'), icon: '/images/resources-builder-icon.png', route: '/resource-builder' },
    ];

    return (
    <div className="main-content">
        <div className="welcome-message">
            <h1>{t('resources_repository')}</h1>
        </div>
        
        <div className="icon-button-container">
            {resources.map((resource, index) => (
                <div key={index} className="icon-container">
                    <button className="icon-button" onClick={() => navigate(resource.route)}>
                        <img src={resource.icon} alt={`${resource.title} Icon`} className="link-icon" />
                        <span className="link-text">{resource.title}</span>
                    </button>
                </div>
            ))}
        </div>
    </div>
    );
}

export default ResourcesRepository;