// SignUpModal.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import RegisterComponent from './../../auth/account/RegisterComponent';

Modal.setAppElement('#root');

const SignUpModal = ({ isOpen, onRequestClose, openLoginModal }) => {
    const { t } = useTranslation();
    const handleLoginClick = () => {
        onRequestClose();
        openLoginModal();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Sign Up Modal"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '600px',
                    minWidth: '300px',
                    padding: '20px',
                    maxHeight: '90vh', // Limita l'altura màxima al 80% de l'alçada de la pantalla
                    overflowY: 'auto', // Afegeix scroll vertical si el contingut és més gran que l'altura màxima
                },
            }}
        >
            <h2>{t('sign_up')}</h2>
            <RegisterComponent onRequestClose={onRequestClose} />
            <br />
            <button className="button-simplelink" onClick={handleLoginClick}>
                {t('already_have_an_account_login')}
            </button>
        </Modal>
    );
};

export default SignUpModal;