// LoginModal.jsx

import React from 'react'; 
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import LoginComponent from './../../auth/account/LoginComponent'; 

Modal.setAppElement('#root');

const LoginModal = ({ isOpen, onRequestClose, openSignUpModal }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Login Modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80%',
          overflowY: 'auto',
          width: 'auto',
        },
      }}
    >
      <h2>{t('login')}</h2>
      <LoginComponent onRequestClose={onRequestClose} />
      <br />
      <button className="button-simplelink" onClick={openSignUpModal}>
        {t('need_an_account_sign_up')}
      </button>
    </Modal>
  );
};

export default LoginModal;