// userUtils.js

import axios from 'axios';

/**
 * Calcula l'edat a partir de la data de naixement.
 * @param {string} birthDate - Data de naixement (en format ISO).
 * @returns {number} - L'edat calculada.
 */
export const calculateAge = (birthDate) => {
  if (!birthDate) return null; // Si no hi ha data de naixement, retorna null.
  
  const birth = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  
  if (
    today.getMonth() < birth.getMonth() ||
    (today.getMonth() === birth.getMonth() && today.getDate() < birth.getDate())
  ) {
    age--;
  }
  
  return age;
};

/**
 * Recupera les dades de l'usuari autenticat.
 * @param {string} apiUrl - La URL base de l'API.
 * @returns {Promise<object>} - Les dades de l'usuari o un error si no s'ha pogut recuperar.
 */
export const fetchUserData = async (apiUrl) => {
  const token = localStorage.getItem('token'); // Llegeix el token del localStorage.
  if (!token) {
    throw new Error('Authorization token not found'); // Llança un error si no hi ha token.
  }
  
  try {
    // Fa la sol·licitud GET a l'endpoint `/users/me` amb el token al header.
    const response = await axios.get(`${apiUrl}/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const user = response.data; // Dades de l'usuari retornades per l'API.
    
    console.log("🔍 API response from /users/me:", user); // Depuració

    // Retorna totes les dades de l'usuari segons l'esquema de la base de dades.
    return {
      id: user._id,
      username: user.username,
      email: user.email,
      role: user.role,
      age: calculateAge(user.dateOfBirth), 
      dateOfBirth: user.dateOfBirth, 
      pronoun: user.pronoun,
      countryOfResidence: user.countryOfResidence,
      communicationLanguage: user.communicationLanguage,
      profession: user.profession,
      voiceCompetenceLevel: user.voiceCompetenceLevel,
      wantsToShareEvaluations: user.wantsToShareEvaluations, // ✅ Afegit
      acceptPrivacyPolicy: user.acceptPrivacyPolicy,
      receiveInformation: user.receiveInformation,
      acceptResearchUsage: user.acceptResearchUsage, // ✅ Afegit
      isVerified: user.isVerified,
      createdAt: user.createdAt
    };
  } catch (error) {
    console.error('⚠️ Error fetching user data:', error);
    throw error;
  }
};