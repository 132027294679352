// AdminDashboard.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../../locales/i18n';
import { useAuth } from '../../auth/authContext';
import UpdateConsentModal from '../../components/modals/UpdateConsentModal';
import axios from 'axios';

function AdminDashboard() {
    const { t } = useTranslation();
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const [isUpdateConsentModalOpen, setUpdateConsentModalOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';
    
    useEffect(() => {
        if (user && user.acceptResearchUsage === null) {
            setUpdateConsentModalOpen(true);
        }
    }, [user]);

    const handleAcceptUpdateConsent = (consentGiven) => {
        console.log("Attempting to update consent for user:", user._id, "with consent:", consentGiven);
        axios.put(`${apiUrl}/users/${user._id}/update-consent`, { acceptResearchUsage: consentGiven }, {
            headers: { Authorization: `Bearer ${user.token}` },
        })        
        .then(response => {
            console.log("Response from server:", response.data);
            setUser({ ...user, acceptResearchUsage: consentGiven });
            setUpdateConsentModalOpen(false);
        })
        .catch(error => {
            console.error('Error updating consent:', error);
        });
    };

    const resources = [
        // { title: t('join_todays_challenge'), icon: '/images/join-todays-challenge-icon.png', route: '/join-todays-challenge' },
        { title: t('resources_repository'), icon: '/images/resources-icon.png', route: '/resources' },
        { title: t('open_research_hub'), icon: '/images/open-research-hub-icon.png', route: '/open-research-hub' },
        { title: t('custom_research_solutions'), icon: '/images/services-icon.png', route: '/custom-research-solutions' },
        { title: t('join_the_wvd_event'), icon: '/images/wvd.png', route: '/wvd-event' },
    ];

    return (
        <div className="main-content">
            {user ? (
                <div className="welcome-message">
                    <h1>Admin Dashboard</h1>
                    <h1>{t('hello')}, {user.username}!</h1>
                    <p className="centered-text">{t('welcome_back_message')}</p>
                </div>
            ) : (
                <div className="welcome-message">
                    <h1>{t('welcome_message')}</h1>
                </div>
            )}

            <div className="icon-button-container">
                {resources.map((resource, index) => (
                    <div key={index} className="icon-container">
                        <button className="icon-button" onClick={() => navigate(resource.route)}>
                            <img src={resource.icon} alt={`${resource.title} Icon`} className="link-icon" />
                            <span className="link-text">{resource.title}</span>
                        </button>
                    </div>
                ))}
            </div>

            <UpdateConsentModal
                isOpen={isUpdateConsentModalOpen}
                onUpdateConsent={handleAcceptUpdateConsent}
            />
        </div>
    );
}

export default AdminDashboard;