// ParticipantSessionView.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToolDisplayer from '../ToolDisplayer';
import RatingsDisplayer from './RatingsDisplayer';
import { useAuth } from '../../../../auth/authContext';
import { getTempParticipantId } from '../../../../utils/generateTempId';

const ParticipantSessionView = ({ sessionData, apiUrl, onSubmit, participantName, isSessionMode, onJoinAnotherSession }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [results, setResults] = useState(null); // local state for submitted results

  // Si no hi ha login, utilitzem un ID temporal
  const participantId = user?._id || getTempParticipantId();

  const handleResultsSubmitted = (data) => {
    // Actualitzem l'estat local per mostrar immediatament els ratings
    setResults(data);
    onSubmit(data);
  };

  return (
    <div>
      <h2>{t('Session Code')}: {sessionData?.code}</h2>
      
      {results === null ? (
        // Mostrem el formulari si encara no s'han enviat els resultats
        <ToolDisplayer
          selectedTool={sessionData?.evaluationTool}
          selectedSample={sessionData?.selectedSample}
          sessionCode={sessionData?.code}  // Nou prop amb el codi de la sessió
          apiUrl={apiUrl}
          onSubmit={handleResultsSubmitted}
          isSessionMode={true}
          participantName={participantName}
          userId={participantId}
        />

      ) : (
        // Mostrem els ratings immediatament amb les dades locals
        <RatingsDisplayer
          selectedTool={sessionData?.evaluationTool}
          results={[{ perceptualData: results, voiceSampleId: sessionData?.selectedSample?._id }]}
          selectedSample={sessionData?.selectedSample}
          isSessionMode={isSessionMode}
          onJoinAnotherSession={onJoinAnotherSession}
        />
      )}
    </div>
  );
};

export default ParticipantSessionView;