// currentProjects.js

const currentProjects = [
    {
      id: 1,
      title: "global_database_title",
      recruitmentCall: "global_database_recruitment_call",
      recruitmentDescription: "global_database_recruitment_description",
      inclusionCriteria: "global_database_inclusion_criteria",
      principalInvestigator: "Neus Calaf, PhD",
      researcherOrganization: "Autonomous University of Barcelona (Spain)",
      participantInformationLink: "/global-database-participant-information-sheet",
      informedConsentLink: "/global-database-informed-consent",
      participationLink: "/profile",
      isActive: true,
      requiresRegistration: true 
    },
    {
      id: 2,
      title: "cape_vr_adaptation_title",
      recruitmentCall: "cape_vr_adaptation_recruitment_call",
      recruitmentDescription: "cape_vr_adaptation_recruitment_description",
      inclusionCriteria: "cape_vr_adaptation_inclusion_criteria",
      principalInvestigator: "Neus Calaf, PhD",
      researcherOrganization: "Autonomous University of Barcelona (Spain)",
      participantInformationLink: "cape-vr-adaptation-participant-information-sheet",
      informedConsentLink: "cape-vr-adaptation-informed-consent",
      participationLink: "",
      isActive: false,
      requiresRegistration: false 
     },
    // Afegir més projectes aquí
  ];
  
  export default currentProjects;