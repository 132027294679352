// SessionCreator.jsx

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../auth/authContext";
import axios from 'axios';
import LoginModal from "../../../../components/modals/LoginModal";
import SampleManager from "../../../../components/audio/SampleManager";
import EvaluationToolSelector from "../../../../components/forms/EvaluationToolSelector";
import LockIcon from "../../../../components/icons/LockIcon";

const SessionCreator = ({ apiUrl, onSessionCreated, onDatabaseChange }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  
  const [selectedEvaluationTool, setSelectedEvaluationTool] = useState(null);
  const [selectedSample, setSelectedSample] = useState(null);
  const [error, setError] = useState("");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const handleEvaluationToolSelected = (tool) => {
    setSelectedEvaluationTool(tool);
    setError("");
  };

  const handleSampleSelected = (sample) => {
    setSelectedSample(sample);
    setError("");
  };

  const handleGoToForm = () => {
    if (!selectedEvaluationTool) {
        setError(t("error_select_tool"));
        return;
    }
    if (!selectedSample) {
        setError(t("error_select_sample"));
        return;
    }

    // Preparem les dades per enviar-les a l'API
    const sessionData = {
        moderatorId: user ? user._id : null,
        selectedSample,  // ✅ Passem l'objecte sencer
        evaluationTool: selectedEvaluationTool,  // ✅ Passem l'objecte sencer
    };

    // Crida a l'API per crear la sessió
    axios.post(`${apiUrl}/interactive-sessions/create`, sessionData)
        .then((response) => {
            console.log("Sessió creada:", response.data);
            onSessionCreated(response.data.session);  // 🔥 Passem la sessió retornada directament
        })
        .catch((error) => {
            console.error("Error creant la sessió:", error);
            setError(t("error_creating_session"));
        });
};

  return (
    <div className="main-content">
      <p>{t("lorem_ipsum")}</p>

      {/* Selecció de l'eina d'avaluació */}
      <EvaluationToolSelector apiUrl={apiUrl} onToolSelected={handleEvaluationToolSelected} />

      {/* Gestió de selecció de mostra ja integrada en SampleManager */}
      <SampleManager
        apiUrl={apiUrl}
        onDatabaseChange={onDatabaseChange}
        onSampleSelected={handleSampleSelected}
        hideUploadOption={true}
      />

      {error && (
        <p style={{ textAlign: "center", color: "red", marginTop: "10px" }}>
          {error}
        </p>
      )}

      <button
        onClick={user ? handleGoToForm : () => setIsLoginModalOpen(true)}
        className="button-center"
      >
        {t("create_this_session")}
        <span><LockIcon isRestricted={true} user={user} /></span>
      </button>

      <LoginModal isOpen={isLoginModalOpen} onRequestClose={() => setIsLoginModalOpen(false)} />
    </div>
  );
};

export default SessionCreator;