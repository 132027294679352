// OpenResearchHub.jsx

import { useState } from "react";
import { useTranslation } from "react-i18next";
import ResearchProjectCard from "./research-projects-cards/ResearchProjectCard";
import currentProjects from "./research-projects-cards/currentProjects";
import ContactFormModal from "./../../components/modals/ContactFormModal";
import { useAuth } from "../../auth/authContext";
import LoginModal from "./../../components/modals/LoginModal";

function OpenResearchHub() {
    const { t } = useTranslation();
    const { user, setUser } = useAuth();
    const [selectedProject, setSelectedProject] = useState(null);
    const [isContactModalOpen, setContactModalOpen] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [studyCode, setStudyCode] = useState("");
    const [nameId, setNameId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const openContactModal = () => {
        setContactModalOpen(true);
    };

    const closeContactModal = () => {
        setContactModalOpen(false);
    };

    const openLoginModal = () => {
      setLoginModalOpen(true);
    };
  
    const closeLoginModal = () => {
      setLoginModalOpen(false);
    };

    // Funció per simular l'acció de "unir-se a un estudi"
    const joinStudy = () => {
      // Codi fake: suposem que el codi correcte és "1234"
      if (studyCode !== "6789") {
        setErrorMessage(t("invalid_study_code_error", "invalid_study_code"));
      } else {
        setErrorMessage("");
        // Aquí aniria la lògica per unir-se a l'estudi
        console.log("Usuari unint-se a l'estudi amb nom/ID:", nameId);
      }
    };

    // Traduïm els projectes abans de passar-los a les targetes
    const translatedProjects = currentProjects.map((project) => ({
        ...project,
        title: t(project.title),
        recruitmentCall: t(project.recruitmentCall),
        recruitmentDescription: t(project.recruitmentDescription),
        inclusionCriteria: t(project.inclusionCriteria),
    }));
  
    return (
      <div className="container">
        <h1>{t("open_research_hub")}</h1>
        <p>{t("open_research_hub_introduction")}</p>
        <br/>
        <div className="button-container">
          <input
            type="text"
            className="input-field"
            placeholder={t('study_code')}
            value={studyCode}
            onChange={(e) => setStudyCode(e.target.value)}
          />
          <input
            type="text"
            className="input-field"
            placeholder={t('name_id')}
            value={nameId}
            onChange={(e) => setNameId(e.target.value)}
          />
          <button className="button-center" onClick={joinStudy}>
            {t('join')}
          </button>
        </div>
        {/* Missatge d'error */}
        {errorMessage && (
          <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
        )}

        <div className="projects-grid">
          {translatedProjects.map((project) => (
            <ResearchProjectCard
              user={user}
              setUser={setUser}
              key={project.id}
              project={project}
              isExpanded={selectedProject?.id === project.id}
              onSelect={() => setSelectedProject(selectedProject?.id === project.id ? null : project)}
              openLoginModal={openLoginModal}
            />
          ))}
        </div>
        <br/>
        <br/>
        <br/>
        <h4 className="text-green centered-text">{t("open_research_hub_cta")}</h4>
        <div className="button-container">
          <button onClick={openContactModal} className="button-center">
            {t('get_in_touch')}
          </button>
        </div>

        <LoginModal isOpen={isLoginModalOpen} onRequestClose={closeLoginModal}/>
        <ContactFormModal isOpen={isContactModalOpen} onRequestClose={closeContactModal} />
      </div>
    );
}
  
export default OpenResearchHub;