// AdditionalFeaturesEvaluation.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';
import { variableTranslations } from './../../../../../constants/variableTranslations';

function AdditionalFeaturesEvaluation({
  selectedAdditionalFeatures,
  setSelectedAdditionalFeatures,
  additionalFeaturesValues,
  setAdditionalFeaturesValues,
}) {
  const t = useFixedTForResource('CAPEVR');

  const handleCheckboxChange = (feature) => {
    if (selectedAdditionalFeatures.includes(feature)) {
      setSelectedAdditionalFeatures(
        selectedAdditionalFeatures.filter((item) => item !== feature)
      );
      const updatedValues = { ...additionalFeaturesValues };
      delete updatedValues[feature];
      setAdditionalFeaturesValues(updatedValues);
    } else {
      const translationKey = variableTranslations[feature] || feature;
      const attributeName = feature === 'otherAdditionalFeature' ? '' : t(translationKey);
      setSelectedAdditionalFeatures([...selectedAdditionalFeatures, feature]);
      setAdditionalFeaturesValues({
        ...additionalFeaturesValues,
        [feature]: {
          VASValue: 0,
          attributeName,
        },
      });
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (selectedAdditionalFeatures.includes('otherAdditionalFeature')) {
      setAdditionalFeaturesValues({
        ...additionalFeaturesValues,
        otherAdditionalFeature: {
          ...additionalFeaturesValues.otherAdditionalFeature,
          attributeName: newText,
        },
      });
    }
  };

  return (
    <div className="capevr-radio-group">
      <label className="capevr-radio-label">{t('additional_features')}:</label>
      <div className="capevr-checkboxes-horizontal">
        {[
          'aphonia',
          'asthenia',
          'diplophonia',
          'falsetto',
          'fry',
          'hardGlottalAttack',
          'wetGurgly',
          'otherAdditionalFeature',
        ].map((value) => {
          const translationKey = variableTranslations[value] || value;
          return (
            <label key={value} className="capevr-checkbox-label-inline">
              <input
                type="checkbox"
                name={value}
                value={value}
                checked={selectedAdditionalFeatures.includes(value)}
                onChange={() => handleCheckboxChange(value)}
              />
              {value === 'otherAdditionalFeature'
                ? additionalFeaturesValues?.otherAdditionalFeature?.attributeName || t(translationKey)
                : t(translationKey)}
              {value === 'otherAdditionalFeature' &&
                selectedAdditionalFeatures.includes(value) && (
                  <input
                    type="text"
                    id="otherAdditionalFeatureTag"
                    value={additionalFeaturesValues?.otherAdditionalFeature?.attributeName || ''}
                    onChange={handleTextChange}
                    placeholder={t('specify')}
                    className="capevr-input-inline"
                  />
                )}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default AdditionalFeaturesEvaluation;