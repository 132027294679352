// Countdown.jsx

import React, { useState, useEffect, useCallback } from 'react';

function Countdown({ targetDate }) {
  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const diff = targetDate - now;
    if (diff <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    return {
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
      seconds: Math.floor((diff / 1000) % 60)
    };
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

    }, 1000);
    return () => clearInterval(timer);
  }, [calculateTimeLeft, targetDate]);

  return (
    <div style={{ color: '#ac1e1a', fontSize: '2rem', fontWeight: 'bold' }}>
      <span>
        {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
      </span>
    </div>
  );
}

export default Countdown;