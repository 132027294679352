// ResourceLanguageWarning.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from './LanguageContext';

function ResourceLanguageWarning({ resourceKey }) {
  const { t } = useTranslation();
  const { appLanguage, resourceLanguages } = useLanguage();

  const availableLanguages = resourceLanguages[resourceKey] || [];
  const isUnavailable = !availableLanguages.includes(appLanguage);

  if (!isUnavailable) return null;

  const translatedList = availableLanguages
    .map((code) => t(`language_names.${code}`))
    .join(', ');

  return (
    <div style={{ color: 'gray', fontStyle: 'italic', textAlign: 'center', marginBottom: '1em' }}>
      ⚠️ {t('resource_available_languages', { languages: translatedList })}
    </div>
  );
}

export default ResourceLanguageWarning;
