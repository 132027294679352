// Demographics.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';


function Demographics({
  subjectNameId,
  setSubjectNameId,
  subjectGender,
  setSubjectGender,
  subjectAge,
  setSubjectAge,
  examinerId,
  setExaminerId,
  recordingDate,
  setRecordingDate,
}) {
  const t = useFixedTForResource('CAPEVR');

  return (
    <div className="demographics">
      <h2>{t('demographics')}</h2>
      <div className="demographics-grid">
        {/* Client ID */}
        <div className="demographics-item">
          <label htmlFor="subjectNameId">{t('name_id')}:</label>
          <input
            type="text"
            id="subjectNameId"
            value={subjectNameId}
            onChange={(e) => setSubjectNameId(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Subject Gender */}
        <div className="demographics-item">
          <label htmlFor="subjectGender">{t('gender')}:</label>
          <input
            type="text"
            id="subjectGender"
            value={subjectGender}
            onChange={(e) => setSubjectGender(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Subject Age */}
        <div className="demographics-item">
          <label htmlFor="subjectAge">{t('age')}:</label>
          <input
            type="number"
            id="subjectAge"
            value={subjectAge}
            onChange={(e) => setSubjectAge(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Examiner */}
        <div className="demographics-item">
          <label htmlFor="examinerId">{t('examiner')}:</label>
          <input
            type="text"
            id="examinerId"
            value={examinerId}
            onChange={(e) => setExaminerId(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Date */}
        <div className="demographics-item">
          <label htmlFor="recordingDate">{t('date')}:</label>
          <input
            type="date"
            id="recordingDate"
            value={recordingDate}
            onChange={(e) => setRecordingDate(e.target.value)}
            className="demographics-input"
          />
        </div>
      </div>
    </div>
  );
}

export default Demographics;