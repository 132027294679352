// OpenResearchHub.jsx

import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactFormModal from "../../components/modals/ContactFormModal";

function CustomResearchSolutions() {
    const { t } = useTranslation();
    const [isContactModalOpen, setContactModalOpen] = useState(false);

    const openContactModal = () => {
        setContactModalOpen(true);
    };

    const closeContactModal = () => {
        setContactModalOpen(false);
    };
  
    return (
      <div className="main-content">
        <h1>{t("custom_research_solutions")}</h1>
        <p>{t('lorem_ipsum')}</p>
        <br/>
        <br/>
        <br/>
        <h4 className="text-green centered-text">{t("open_research_hub_cta")}</h4>
        <div className="button-container">
        <button 
            onClick={openContactModal} 
            className="button-center"
            >
            {t('get_in_touch')}
        </button>
        </div>

        <ContactFormModal isOpen={isContactModalOpen} onRequestClose={closeContactModal} />
      </div>
    );
}
  
export default CustomResearchSolutions;