// AuditoryPerceptualTools.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OsParent from './../os/OsParent';
import CAPEVParent from './../cape-v/CAPEVParent';
import CAPEVRParent from './../cape-vr/CAPEVRParent';
import '../../locales/i18n';

function AuditoryPerceptualTools() {
  const { t } = useTranslation();
  const [selectedTool, setSelectedTool] = useState("");

  const handleSelectorChange = (e) => {
    setSelectedTool(e.target.value);
  };

  return (
    <div className="main-content">
      <h1>{t('auditory_perceptual_evaluation_tools')}</h1>
      <div className="selector-container">
        <select value={selectedTool} onChange={handleSelectorChange}>
          <option value="">{t('select_an_option')}</option>
          <option value="os">{t('overall_severity_vas')}</option>
          <option value="capev">{t('cape_v')}</option>
          <option value="capevr">{t('cape_vr')}</option>
        </select>
      </div>
      <div className="tool-container">
        {selectedTool === 'os' && <OsParent />}
        {selectedTool === 'capev' && <CAPEVParent />}
        {selectedTool === 'capevr' && <CAPEVRParent />}
      </div>
    </div>
  );
}

export default AuditoryPerceptualTools;