// InstructorView.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import pusher from '../../../../services/pusher'; // Importa Pusher
import CAPEVFeedback from './CAPEVFeedback';
import feedbackDataOriginalPVQD from '../../../../constants/feedback-data/feedbackDataOriginalPVQD';
import CAPEVForm from './../../cape-v-shared-files/CAPEVForm';
import DataVisualizationControls from './DataVisualizationControls';
import { prepareInitialConsensusData } from './consensusDataUtils';
import SessionDetails from './SessionDetails';
import WaldenCitation from './../../../../components/citations/WaldenCitation';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const InstructorView = ({ sessionCode, userId, onConsensusSubmit, consensusData, consensusSubmitted, onStartAnotherSession }) => {
  const { t } = useTranslation();

  const [studentsResults, setStudentsResults] = useState([]);
  const [session, setSession] = useState(null);
  const [showStudentRatings, setShowStudentRatings] = useState(true);
  const [showSociodemographicData, setShowSociodemographicData] = useState(false);
  const [showStandardAttributes, setShowStandardAttributes] = useState(true);
  const [showAdditionalAttributes, setShowAdditionalAttributes] = useState(false);
  const [selectedSampleData, setSelectedSampleData] = useState(null);
  const [showConsensusForm, setShowConsensusForm] = useState(false);
  const [showSharedEvaluations, setShowSharedEvaluations] = useState(false);
  const [sharedEvaluations, setSharedEvaluations] = useState([]);
  const [showPVQDEvaluations, setShowPVQDEvaluations] = useState(false);
  const [showConsensusData, setShowConsensusData] = useState(false);

  const handleToggleStudentRatings = () => {
    setShowStudentRatings(prevState => !prevState);
  };

  const handleToggleConsensusData = () => setShowConsensusData(!showConsensusData);
  const handleToggleSharedEvaluations = () => setShowSharedEvaluations(!showSharedEvaluations);
  const handleTogglePVQDEvaluations = () => setShowPVQDEvaluations(!showPVQDEvaluations);
  const handleToggleSociodemographicData = () => setShowSociodemographicData(!showSociodemographicData);
  const handleToggleStandardAttributes = () => setShowStandardAttributes(!showStandardAttributes);
  const handleToggleAdditionalAttributes = () => setShowAdditionalAttributes(!showAdditionalAttributes);

  const canShowConsensusData = consensusSubmitted;
  const canShowSharedEvaluations = consensusSubmitted;
  const canShowPVQDEvaluations = consensusSubmitted;

  const handleShowConsensusForm = () => setShowConsensusForm(true);
  const handleBack = () => setShowConsensusForm(false);

  // Fetch session details from the server
  const fetchSessionDetails = useCallback(() => {
    axios.get(`${apiUrl}/sessions/${sessionCode}`)
      .then(response => {
        const sessionData = response.data;
        setSession(sessionData);
        setStudentsResults(sessionData.results);

        const sampleData = feedbackDataOriginalPVQD.find(sample => sample.sampleName === sessionData.selectedSample);
        setSelectedSampleData(sampleData);
      })
      .catch(error => {
        console.error('Error fetching session details:', error);
      });
  }, [sessionCode]);

  // Fetch shared evaluations data from the server
  const fetchSharedEvaluations = useCallback(() => {
    if (selectedSampleData && selectedSampleData.sampleName) {
      axios.get(`${apiUrl}/shared-evaluations/sample/${selectedSampleData.sampleName}`)
        .then(response => {
          const data = Array.isArray(response.data) ? response.data : [response.data];
          setSharedEvaluations(data);
        })
        .catch(error => {
          console.error('Error fetching shared evaluations:', error);
          setSharedEvaluations([]);
        });
    }
  }, [selectedSampleData]);

  useEffect(() => {
    if (sessionCode) {
        fetchSessionDetails();
        window.scrollTo({
            top: 0,
            behavior: 'auto',
        });
    }
  }, [sessionCode, fetchSessionDetails]);

  useEffect(() => {
    const channel = pusher.subscribe('my-channel'); // Subscriu-te al canal de Pusher

    // Canviem l'esdeveniment per un nom més significatiu
    channel.bind('server-create-session', (data) => {
      console.log('Pusher event received:', data);
      fetchSessionDetails(); // Actualitza els detalls de la sessió quan arribi un esdeveniment
    });

    return () => {
      channel.unbind_all(); // Neteja els esdeveniments quan el component es desmunta
      channel.unsubscribe();
    };
  }, [fetchSessionDetails]);

  useEffect(() => {
    if (showSharedEvaluations) {
      fetchSharedEvaluations();
    }
  }, [showSharedEvaluations, fetchSharedEvaluations]);

  const handleConsensusSubmit = (data) => {
    onConsensusSubmit(data);
    setShowConsensusForm(false);
    setShowConsensusData(true); // Marca automàticament aquesta opció
    setShowSharedEvaluations(false); // Reseteja aquest estat perquè es requereixi activar manualment
    setShowPVQDEvaluations(false); // Reseteja aquest estat perquè es requereixi activar manualment

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  const handleShowConsensusData = () => {
    setShowConsensusForm(false);
    setShowConsensusData(true);
  };

  const getFilteredPVQDEvaluations = () => {
    if (!selectedSampleData) return [];
    return feedbackDataOriginalPVQD.filter(
      (data) => data.sampleName === selectedSampleData.sampleName
    );
  };

  return (
    <div>
      <h1>{t('cape_v_workshop_instructor_view')}</h1>
      <h2>{t('session_code')}: {sessionCode}</h2>
      {session && !showConsensusForm && (
        <div>
          <SessionDetails session={session} onRefresh={fetchSessionDetails} />
          <DataVisualizationControls
            showStudentRatings={showStudentRatings}
            showConsensusData={showConsensusData}
            showSharedEvaluations={showSharedEvaluations}
            showPVQDEvaluations={showPVQDEvaluations}
            showSociodemographicData={showSociodemographicData}
            showStandardAttributes={showStandardAttributes}
            showAdditionalAttributes={showAdditionalAttributes}
            onRefresh={fetchSessionDetails}
            onToggleStudentRatings={handleToggleStudentRatings}
            onToggleConsensusData={handleToggleConsensusData}
            onToggleSharedEvaluations={handleToggleSharedEvaluations}
            onTogglePVQDEvaluations={handleTogglePVQDEvaluations}
            onToggleSociodemographicData={handleToggleSociodemographicData}
            onToggleStandardAttributes={handleToggleStandardAttributes}
            onToggleAdditionalAttributes={handleToggleAdditionalAttributes}
            canShowConsensusData={canShowConsensusData}
            canShowSharedEvaluations={canShowSharedEvaluations}
            canShowPVQDEvaluations={canShowPVQDEvaluations}
            onShowConsensusForm={handleShowConsensusForm}
          />
          {selectedSampleData && (
            <div className="audio-container">
              <audio controls src={selectedSampleData.sampleLink}>
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          <CAPEVFeedback
            feedbackData={studentsResults}
            sampleData={selectedSampleData}
            t={t}
            consensusData={consensusData}
            consensusSubmitted={consensusSubmitted}
            sharedEvaluations={sharedEvaluations}
            showSharedEvaluations={showSharedEvaluations}
            showPVQDEvaluations={showPVQDEvaluations}
            pvqdEvaluations={showPVQDEvaluations ? getFilteredPVQDEvaluations() : []}
            showStandardAttributes={showStandardAttributes}
            showAdditionalAttributes={showAdditionalAttributes}
            showStudentRatings={showStudentRatings}
            showConsensusData={showConsensusData}
            showSociodemographicData={showSociodemographicData} // Afegit per a gestionar la visibilitat de les dades sociodemogràfiques
          />
        </div>
      )}
      {showConsensusForm && session && (
        <CAPEVForm
          initialData={prepareInitialConsensusData(studentsResults)}
          onSubmit={handleConsensusSubmit}
          onBack={handleBack}
          sampleData={selectedSampleData}
          userId={userId}
          sampleName={selectedSampleData.sampleName}
          onShowConsensusData={handleShowConsensusData}
        />
      )}
      <br/>
      <div className="button-container">
        <button className="button-center" onClick={onStartAnotherSession}>{t('start_another_session')}</button>
      </div>
      <br />
      <WaldenCitation />
    </div>
  );
};

export default InstructorView;