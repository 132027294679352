// ParticipantsRatingsDisplayer.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionData } from '../../../../hooks/useSessionData';
import FullFeedback from './../../../../components/feedback/FullFeedback';
import { adaptSessionFeedback } from '../../../../utils/transformSessionFeedback';

const FullFeedbackDisplayer = ({ apiUrl, sessionCode, onGoToPreview }) => {
  const { t } = useTranslation();
  const { sessionData, loading, error } = useSessionData(apiUrl, sessionCode);

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{t('error_loading_session')}</p>;
  if (!sessionData || !sessionData.results) return <p>{t('no_session_data')}</p>;

  const adaptedSessionEvaluations = adaptSessionFeedback(sessionData.results, sessionData.selectedSample);

  return (
    <div>
      <FullFeedback
        apiUrl={apiUrl}
        selectedSample={sessionData?.selectedSample}
        sessionEvaluations={adaptedSessionEvaluations}
        onGoToPreview={onGoToPreview}
        isConsensusMode={true}
      />
    </div>
  );
};

export default FullFeedbackDisplayer;