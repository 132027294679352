// SessionControlView.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ParticipantsTable from './ParticipantsTable';
import ParticipantsRatingsDisplayer from './ParticipantsRatingsDisplayer';
import ToolDisplayer from './../ToolDisplayer';
import { aggregatePerceptualData } from '../../../../utils/aggregatePerceptualData';
import FullFeedbackDisplayer from './FullFeedbackDisplayer';

const SessionControlView = ({ apiUrl, sessionData, sampleDatabase, onRefresh, onCreateAnotherSession }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState('default');
  const handleConsensusClick = () => setMode('consensus');
  const defaultPerceptualData = sessionData?.results ? aggregatePerceptualData(sessionData.results) : null;

  if (mode === 'consensus') {
    return (
      <div>
        <ToolDisplayer
          selectedTool={sessionData?.evaluationTool}
          sampleDatabase={sampleDatabase}
          selectedSample={sessionData?.selectedSample}
          apiUrl={apiUrl}
          sessionCode={sessionData?.code}
          onSubmit={() => setMode('feedback')}
          isSessionMode={true}
          isConsensusMode={true}
          defaultPerceptualData={defaultPerceptualData}
        />
      </div>
    );
  }

  if (mode === 'ratings') {
    return (
      <div>
        <ParticipantsRatingsDisplayer 
          apiUrl={apiUrl} 
          sessionCode={sessionData?.code}
          onGoToPreview={() => setMode('ratings')}
        />
        <div className="button-container">
          <button className="button-center" onClick={handleConsensusClick}>
            {t('send_consensus_evaluation')}
          </button>
        </div>
      </div>
    );
  }

  if (mode === 'feedback') {
    return (
      <div>
        <FullFeedbackDisplayer
          apiUrl={apiUrl}
          sessionCode={sessionData?.code}
          onGoToPreview={onCreateAnotherSession}
        />
        <div className="button-container">
          <button className="button-center" onClick={onCreateAnotherSession}>
            {t('create_another_session')}
          </button>
          <br/>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2>{t('Session Code')}: {sessionData?.code}</h2>

      <ParticipantsTable 
        participants={sessionData?.participants || []} 
        results={sessionData?.results || []} 
      />

      <br/>
      <div className="button-container">
        <button className="button-center" onClick={onRefresh}>
          {t('refresh_session_data')}
        </button>

        <div className="audio-container">
          {sessionData?.selectedSample?.sampleLink ? (
            <audio controls style={{ marginTop: '10px' }}>
              <source src={`/${sessionData.selectedSample?.sampleLink}`} type="audio/wav" />
              {t('Your browser does not support the audio element.')}
            </audio>
          ) : (
            <p>{t('no_audio_for_sample')}</p>
          )}
        </div>

        <button className="button-center" onClick={() => setMode('ratings')}>
          {t('view_session_ratings')}
        </button>
      </div>
    </div>
  );
};

export default SessionControlView;