// CAPEVRatings.jsx
// TODO Activar la creació de PDF quan hagi decidir en quines condicions

import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTableCI from './../../../components/feedback/DataTableCI';
import { downloadCSV } from './../../../utils/cvsUtils';
import { variableTranslations } from './../../../constants/variableTranslations';
//import { PDFDownloadLink } from '@react-pdf/renderer';
//import UserRatingsPDF from '../../../components/feedback/UserRatingsPDF';

function CAPEVRatings({ submittedData, sampleDatabase, onGoToFeedback, onRestart, isSessionMode }) {
  const { t } = useTranslation();

  if (!submittedData) {
    return <p>{t('no_ratings_available')}</p>;
  }

  const userData = submittedData.perceptualData || {};

  const processedData = Object.entries(userData).map(([key, value]) => {
    if (!value) return null;

    const attribute = value.attributeName || t(variableTranslations[key] || key);
    const userRating = value.VASValue ? `${value.VASValue}/100` : value.isPresent ? t('yes') : '-';
    const consistency = value.isConsistent === true
      ? t('consistent')
      : value.isConsistent === false
        ? t('intermittent')
        : '-';

    if (key === 'overallImpression') return null;

    return { Attribute: attribute, Rating: userRating, Consistency: consistency };
  }).filter(Boolean);

  if (submittedData.perceptualData?.overallImpression) {
    processedData.push({
      Attribute: t(variableTranslations['overallImpression'] || 'Overall Impression'),
      Rating: '-',
      Consistency: '-'
    });
  }

  const handleCopyToClipboard = () => {
    const csvContent = processedData.map(row => `${row.Attribute},${row.Rating},${row.Consistency}`).join('\n');
    navigator.clipboard.writeText(`Attribute,Rating,Consistency\n${csvContent}`);
    alert(t('copied_to_clipboard'));
  };

  const handleDownloadCSV = () => {
    downloadCSV(processedData, 'user_ratings.csv', ['Attribute', 'Rating', 'Consistency']);
  };

  return (
    <div className="capevr-ratings">
      <h2>{t('your_ratings')}</h2>
      <p>{t('ratings_introduction')}</p>

      <div className="button-container">
        <button onClick={handleCopyToClipboard} className="button-center">
          {t('copy_ratings_to_clipboard')}
        </button>
        <button onClick={handleDownloadCSV} className="button-center">
          {t('download_ratings_in_csv_format')}
        </button>
        {/*<PDFDownloadLink
          document={<UserRatingsPDF data={processedData} />}
          fileName="user_ratings.pdf"
          className="button-center"
        >
          {({ loading }) => (loading ? t('generating_pdf') : t('download_pdf'))}
        </PDFDownloadLink>*/}
      </div>

      {/* Taules per blocs d'atributs */}
      <h4>{t('standard_attributes')}</h4>
      <DataTableCI
        userData={userData}
        attributeKeys={['overallSeverity', 'roughness', 'breathiness', 'strain', 'normalPitch', 'tooLowPitch', 'tooHighPitch', 'normalLoudness', 'tooQuietLoudness', 'tooLoudLoudness']}
      />

      <h4>{t('comments_resonance')}</h4>
      <DataTableCI
        userData={userData}
        attributeKeys={['normalNasality', 'hyponasality', 'hypernasality', 'otherResonance']}
      />

      <h4>{t('additional_features')}</h4>
      <DataTableCI
        userData={userData}
        attributeKeys={['diplophonia', 'fry', 'falsetto', 'asthenia', 'aphonia', 'pitchInstability', 'tremor', 'wetGurgly', 'otherAdditionalFeature']}
      />

      <br />
      <div className="button-container">
        {(!submittedData.voiceSampleId || sampleDatabase === 'upload') ? (
          <button onClick={onRestart} className="button-center">
            {t('restart_with_another_sample')}
          </button>
        ) : (
          !isSessionMode && (
            <button onClick={onGoToFeedback} className="button-center">
              {t('view_feedback')}
            </button>
          )
        )}
      </div>
    </div>
  );
}

export default CAPEVRatings;