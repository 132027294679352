// CAPEVRProtocol.jsx

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLanguage } from '../../../locales/LanguageContext';
import CAPEVRCitation from './../../../components/citations/CAPEVRCitation';

function CAPEVRProtocol() {
  const { t } = useTranslation();
  const { appLanguage, resourceLanguages } = useLanguage();
  const availableCAPEVRLanguages = resourceLanguages.CAPEVR || [];
  const isLanguageUnavailable = !availableCAPEVRLanguages.includes(appLanguage);
    
  const translatedLanguageList = availableCAPEVRLanguages
    .map(code => t(`language_names.${code}`))
    .join(', ');

  return (
    <div className="main-content"> 

      {isLanguageUnavailable && (
        <div style={{ color: 'gray', fontStyle: 'italic', textAlign: 'center', marginBottom: '1em' }}>
          ⚠️ {t('resource_available_languages', { languages: translatedLanguageList })}
        </div>
      )}

      {/* 🔹 Contingut principal del protocol */}
      <div className="long-text-container">
        <section className="long-section">
          <Trans 
            i18nKey="cape-vr-protocol_whole_text" 
            components={{
              h1: <h1>{t('Title')}</h1>,
              h2: <h2>{t('Subtitle')}</h2>,
              h3: <h3>{t('Section')}</h3>,
              h4: <h4>{t('Subsection')}</h4>,
              p: <p />,
              ul: <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }} />,
              li: <li style={{ marginBottom: '5px' }} />,
              strong: <strong />,
            }}
          />
        </section>
      </div>
    <br/>
      {/* 🔹 Cita i botó de retorn (fora de long-text-container) */}
      <div className="extra-content">
        <div className="cites">
          <CAPEVRCitation />
        </div>


      </div>
    </div>
  );
}

export default CAPEVRProtocol;