// RatingConditions.jsx

import React from 'react';
import { useFixedTForResource } from '../../../../../locales/useFixedTForResource';

function RatingConditions({
  voiceSource,
  setVoiceSource,
  listeningDevice,
  setListeningDevice,
  auditoryAnchors,
  setAuditoryAnchors,
  raterId,
  setRaterId,
  ratingDate,
  setRatingDate,
  timesPlayed,
  setTimesPlayed,
}) {
  const t = useFixedTForResource('CAPEVR');

  return (
    <div className="capevr-conditions-section">
      {/* Voice Source */}
      <div className="capevr-condition-item">
        <div className="capevr-radio-group">
          <label>
            <input
              type="radio"
              name="voiceSource"
              value="liveVoice"
              checked={voiceSource === "liveVoice"}
              onChange={(e) => setVoiceSource(e.target.value)}
            />
            {t('live_voice')}
          </label>
          <label>
            <input
              type="radio"
              name="voiceSource"
              value="recordedVoice"
              checked={voiceSource === "recordedVoice"}
              onChange={(e) => setVoiceSource(e.target.value)}
            />
            {t('recorded_voice')}
          </label>
        </div>
      </div>

      {/* Listening Device */}
      <div className="capevr-condition-item">
        <div className="capevr-radio-group">
          <label>
            <input
              type="radio"
              name="listeningDevice"
              value="headphones"
              checked={listeningDevice === "headphones"}
              onChange={(e) => setListeningDevice(e.target.value)}
            />
            {t('headphones')}
          </label>
          <label>
            <input
              type="radio"
              name="listeningDevice"
              value="speakers"
              checked={listeningDevice === "speakers"}
              onChange={(e) => setListeningDevice(e.target.value)}
            />
            {t('speakers')}
          </label>
        </div>
      </div>

      {/* Auditory Anchors */}
      <div className="capevr-condition-item">
        <label>{t('auditory_anchors')}:</label>
        <div className="capevr-radio-group">
          <label>
            <input
              type="radio"
              name="auditoryAnchors"
              value="yes"
              checked={auditoryAnchors === "yes"}
              onChange={(e) => setAuditoryAnchors(e.target.value)}
            />
            {t('yes')}
          </label>
          <label>
            <input
              type="radio"
              name="auditoryAnchors"
              value="no"
              checked={auditoryAnchors === "no"}
              onChange={(e) => setAuditoryAnchors(e.target.value)}
            />
            {t('no')}
          </label>
        </div>
      </div>


      {/* Rater ID */}
      <div className="capevr-condition-item">
        <label htmlFor="raterId">{t('rater')}:</label>
        <input
          type="text"
          id="raterId"
          value={raterId}
          onChange={(e) => setRaterId(e.target.value)}
          className="input-field"
        />
      </div>

      {/* Rating Date */}
      <div className="capevr-condition-item">
        <label htmlFor="ratingDate">{t('date')}:</label>
        <input
          type="date"
          id="ratingDate"
          value={ratingDate}
          onChange={(e) => setRatingDate(e.target.value)}
          className="input-field"
        />
      </div>

      {/* Number of Times Sample Was Played */}
      <div className="capevr-condition-item">
        <label htmlFor="timesPlayed">{t('number_of_times_sample_was_played')}:</label>
        <input
          type="number"
          id="timesPlayed"
          value={timesPlayed}
          onChange={(e) => setTimesPlayed(e.target.value)}
          className="input-field"
        />
      </div>
    </div>
  );
}

export default RatingConditions;