// VASGraph.jsx

import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const VASGraph = ({ attributeName, userValues = [], sharedValues = [], sessionValues = [], consensusValues = [] }) => {
    const { t } = useTranslation();
    const d3Container = useRef(null);
    const [containerWidth, setContainerWidth] = useState(800);

    // 🔹 Monitoritza la mida del contenidor per redimensionar la gràfica
    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            if (entries[0]?.contentRect?.width) {
                setContainerWidth(entries[0].contentRect.width);
            }
        });

        if (d3Container.current?.parentElement) {
            observer.observe(d3Container.current.parentElement);
        }

        return () => observer.disconnect();
    }, []);

    // 🔹 Funció per generar el jitter (desplaçament aleatori)
    const getJitter = (range = 1) => (Math.random() - 0.5) * range;

    // 🔹 Renderitza la gràfica
    useEffect(() => {
        if (!d3Container.current) return;

        const margin = { left: 40, right: 120, top: 20, bottom: 40 };
        const width = containerWidth - margin.left - margin.right;
        const height = 70;

        // 🔸 Neteja la gràfica existent
        const svg = d3.select(d3Container.current);
        svg.selectAll('*').remove();

        // 🔸 Configura la vista responsive
        svg.attr('viewBox', `0 0 ${containerWidth} ${height + margin.top + margin.bottom}`)
           .attr('preserveAspectRatio', 'xMidYMid meet')
           .style('overflow', 'visible');

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // 🔸 Escala horitzontal
        const xScale = d3.scaleLinear().domain([0, 100]).range([0, width]);

        // 🔸 Eix X
        chart.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(xScale).ticks(5).tickFormat(d => `${d}`))
            .selectAll("text")
            .style("font-size", "12px")
            .style("fill", "#333");

        // 🔹 Dibuixa el valor de l'usuari
        userValues.forEach(({ value }) => {
            chart.append("circle")
                .attr("cx", xScale(value))
                .attr("cy", height - 10 + getJitter(10))
                .attr("r", 5)
                .style("fill", "red")
                .style("stroke", "#fff")
                .style("stroke-width", 1.5);
        });

        // 🔹 Dibuixa valoracions compartides amb jitter
        sharedValues.forEach(({ value, raterSource }) => {
            const color = raterSource === 'pvqdRater' ? '#f39c12' : '#209385';

            chart.append("circle")
                .attr("cx", xScale(value))
                .attr("cy", height - 10 + getJitter(10))
                .attr("r", 5)
                .style("fill", color)
                .style("stroke", "#fff")
                .style("stroke-width", 1);
        });

        // 🔹 Dibuixa valoracions de sessió amb cercles buits vermells
        sessionValues.forEach(({ value }) => {
            chart.append("circle")
                .attr("cx", xScale(value))
                .attr("cy", height - 10 + getJitter(10))
                .attr("r", 4)
                .style("fill", "none")  // Cercle buit
                .style("stroke", "red")  // Contorn vermell
                .style("stroke-width", 2);  // Contorn una mica més gruixut per destacar
        });

        // 🔹 Dibuixa el valor de consens
        consensusValues.forEach(({ value }) => {
            chart.append("circle")
                .attr("cx", xScale(value))
                .attr("cy", height - 10 + getJitter(10))
                .attr("r", 5)
                .style("fill", "red")
                .style("stroke", "#fff")
                .style("stroke-width", 1.5);
        });

        // 🔸 Llegenda amb posició fixa
        const legend = svg.append('g')
        .attr('transform', `translate(${containerWidth - 300}, -10)`);

        // 🔍 Comprova si hi ha valoracions de cada font de dades
        const hasUserEvaluations = userValues.length > 0;
        const hasSessionEvaluations = sessionValues.length > 0;
        const hasConsensusEvaluation = consensusValues.length > 0;
        const hasSharedEvaluations = sharedValues.some(({ raterSource }) => raterSource === 'allvoicedUser');
        const hasPvqdEvaluations = sharedValues.some(({ raterSource }) => raterSource === 'pvqdRater');

        // 🔹 Defineix les dades de la llegenda
        const legendData = [
        ...(hasUserEvaluations ? [{ color: "red", fill: "red", label: t('your_evaluation') }] : []),
        ...(hasSessionEvaluations ? [{ color: "red", fill: "none", label: t('session_evaluations') }] : []),
        ...(hasConsensusEvaluation ? [{ color: "red", fill: "red", label: t('consensus_evaluation') }] : []),
        ...(hasSharedEvaluations ? [{ color: "#209385", fill: "#209385", label: t('other_allvoiced_users_evaluations') }] : []),
        ...(hasPvqdEvaluations ? [{ color: "#f39c12", fill: "#f39c12", label: t('pvqd_evaluations') }] : []),
        ];

        // 🖼️ Renderitza la llegenda
        legend.selectAll("rect")
            .data(legendData)
            .enter()
            .append("circle")
            .attr("cx", 6)
            .attr("cy", (_, i) => i * 20 + 6)
            .attr("r", 5)
            .style("fill", d => d.fill)   
            .style("stroke", d => d.color) 
            .style("stroke-width", 2);    

        legend.selectAll("text")
            .data(legendData)
            .enter()
            .append("text")
            .attr("x", 20)
            .attr("y", (_, i) => i * 20 + 10)
            .text(d => d.label)
            .style("font-size", "12px");

    }, [attributeName, userValues, sharedValues, sessionValues, consensusValues, containerWidth, t]);

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <svg ref={d3Container} width="100%" height="150px"></svg>
        </div>
    );
};

export default VASGraph;