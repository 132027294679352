// ToolDisplayer.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import OsForm from '../../os/os-views/OsForm';
import CAPEVForm from '../../cape-v/cape-v-views/CAPEVForm';
import CAPEVRForm from '../../cape-vr/cape-vr-views/CAPEVRForm';

const ToolDisplayer = ({ selectedTool, sampleDatabase, selectedSample, sessionCode, apiUrl, onSubmit, participantName, userId, isSessionMode, isConsensusMode, defaultPerceptualData }) => {
  const { t } = useTranslation();

  const sendToSession = async (data) => {
    try {
      const resultData = {
        code: sessionCode,
        participantName: isConsensusMode ? 'Consensus' : participantName,
        userId: isConsensusMode ? null : userId,
        perceptualData: data.perceptualData || data, 
        voiceSampleId: selectedSample?._id 
      };
      console.log("✅ Enviant dades de sessió:", JSON.stringify(resultData, null, 2));
      await axios.post(`${apiUrl}/interactive-sessions/submit`, resultData);
    } catch (error) {
      console.error("⚠️ Error enviant resultats a la sessió:", error);
    }
  };  
  
  const handleSubmit = async (data) => {
    if (isSessionMode) {
      await sendToSession(data);  // 🔥 Espera que s'enviïn les dades
    }
    onSubmit(data);
  };   

  const renderForm = () => {
    switch (selectedTool?.toolName) {
      case 'OS':
        return (
          <OsForm
            apiUrl={apiUrl}
            sampleDatabase={sampleDatabase}
            selectedSample={selectedSample}
            onSubmit={handleSubmit}
            defaultPerceptualData={defaultPerceptualData}
          />
        );
      case 'CAPE-V':
        return (
          <CAPEVForm
            apiUrl={apiUrl}
            sampleDatabase={sampleDatabase}
            selectedSample={selectedSample}
            onSubmit={handleSubmit}
            defaultPerceptualData={defaultPerceptualData}
          />
        );
      case 'CAPE-Vr':
        return (
          <CAPEVRForm
            apiUrl={apiUrl}
            sampleDatabase={sampleDatabase}
            selectedSample={selectedSample}
            onSubmit={handleSubmit}
            defaultPerceptualData={defaultPerceptualData}
          />
        );
      default:
        return <p>{t('tool_not_supported')}</p>;
    }
  };

  return (
    <div>
      <h2>{selectedTool?.description}</h2>
      {renderForm()}
    </div>
  );
};

export default ToolDisplayer;