// OSFeedback.jsx

import React from 'react';
import VASFeedbackGraph from '../../components/graphs/VASFeedbackGraph';
import BarGraph from './BarGraph';
import { useTranslation } from 'react-i18next';

const OSFeedback = ({ feedbackData, formData}) => {
    const { t } = useTranslation();
    const userRatings = {
      overallSeverity: formData ? formData.overallSeverity : undefined,
      g: formData ? formData.g : undefined, // Afegeix valoració de 0-3
    };
  
    const attributes = [t('overall_severity_0_100'), t('overall_severity_0_3')]; // Afegeix nou atribut
  
    const attributeMapping = {
        [t('overall_severity_0_100')]: 'overallSeverity',
        [t('overall_severity_0_3')]: 'g',  // Atribut per a les valoracions 0-3
    };
  
    return (
      <div className="feedback-container">
        <h2>{t('feedback')}</h2>
        {feedbackData && feedbackData.map((sample, index) => (
          <div key={index} className="feedback-item">
            <p className="small">{t('subject')}: {sample.participantId}</p>
            <p className="small">{t('age')}: {sample.age}</p>
            <p className="small">{t('gender')}: {sample.gender ? t(sample.gender) : t('not_available')}</p>
            <p className="small">{t('diagnose')}: {sample.diagnose ? t(sample.diagnose) : t('not_available')}</p>
            <p className="small">{t('language')}: {sample.language ? t(sample.language) : t('not_available')}</p>
            <p className="small">{t('database')}: {sample.database}</p>
          </div>
        ))}
        {attributes.map(attributeName => {
          const dataKey = attributeMapping[attributeName];
          return (
            <div key={attributeName}>
              {dataKey === 'g' ? ( // Si és el nou atribut 0-3, mostra el nou gràfic
                <BarGraph
                  attributeName={attributeName}
                  attributeData={{
                    pastRatings: feedbackData.map(sample => sample[dataKey]).flat().filter(rating => rating !== undefined),
                    userRating: userRatings[dataKey],
                    sharedRatings: feedbackData.map(sample => sample[`shared_${dataKey}_ratings`]).flat().filter(rating => rating !== undefined),
                  }}
                />
              ) : (
                <VASFeedbackGraph
                  attributeName={attributeName}
                  attributeData={{
                    pastRatings: feedbackData.map(sample => sample[dataKey]).flat().filter(rating => rating !== undefined),
                    userRating: userRatings[dataKey],
                    sharedRatings: feedbackData.map(sample => sample[`shared_${dataKey}_ratings`]).flat().filter(rating => rating !== undefined),
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };
  
  export default OSFeedback;