// Training.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getRandomCollectionAndVoices } from './getRandomCollectionAndVoices.js';
import createRandomPairs from '../../utils/createRandomPairs.js';
import PairComparison from './../methods/PairComparison.jsx';
import calculateSampleRanking from '../../utils/calculateSampleRanking.js';
import Ranking from './../methods/Ranking.jsx';
import Sliders from './../methods/Sliders.jsx';
import Results from './Results.jsx';
import Feedback from './Feedback.jsx';
import feedbackDataPVQDSustainedVowels from '../../constants/feedback-data/feedbackDataPVQDSustainedVowels.js';
import WaldenCitation from '../../components/citations/WaldenCitation.jsx';

const Training = () => {
  const { t } = useTranslation();
  const [evaluatedAttribute, setEvaluatedAttribute] = useState('');
  const [samplePairs, setSamplePairs] = useState([]);
  const [pairComparisonData, setPairComparisonData] = useState([]);
  const [calculatedRankingData, setCalculatedRankingData] = useState([]);
  const [rankingData, setRankingData] = useState(null);
  const [sliderValues, setSliderValues] = useState([]);
  const [experimentStage, setExperimentStage] = useState('Introduction');

  useEffect(() => {
    const attributes = [t('roughness'), t('breathiness'), t('strain')];
    setEvaluatedAttribute(attributes[Math.floor(Math.random() * attributes.length)]);
  }, [t]);

  useEffect(() => {
    const samples = getRandomCollectionAndVoices();
    const pairs = createRandomPairs(samples);
    console.log("Samples and Pairs:", samples, pairs); // Log the samples and pairs
    setSamplePairs(pairs);
  }, []);

  const handlePairComparisonCompletion = ({ userResponses }) => {
    const ranking = calculateSampleRanking(userResponses);
    console.log("User Responses:", userResponses); 
    console.log("Calculated Rankings:", ranking); 
    setPairComparisonData(userResponses);
    setCalculatedRankingData(ranking);
    setExperimentStage('Ranking');
};

  const handleRankingCompletion = (sortedData) => {
    console.log("Sorted Data:", sortedData);
    setRankingData(sortedData);
    setExperimentStage('Sliders');
  };

  const handleSliderCompletion = (values, sorts) => {
    console.log("Slider Values:", values); // Log the slider values
    console.log("Sorts:", sorts); // Log the sorted data
    setSliderValues(values);
    setRankingData(sorts); // Set the sorted data
    setExperimentStage('Results');
  };

  const startExperiment = () => {
    setExperimentStage('PairComparison'); // Function to start the experiment
  };

  const renderIntroduction = () => (
    <div>
      <h2>{t('training')} in three phases</h2>
      <p>{t('training_introduction')}</p>
      <button onClick={startExperiment}>{t('click_to_start')}</button>
    </div>
  );

  const renderResults = () => (
    <div>
      <Results
        evaluatedAttribute={evaluatedAttribute}
        pairComparisonData={pairComparisonData}
        calculatedRankingData={calculatedRankingData}
        rankingData={rankingData}
        sliderValues={sliderValues}
      />
      <button onClick={handleViewFeedbackClick} style={{marginTop: '20px'}}>View Feedback</button>
    </div>
  );  

  const handleViewFeedbackClick = () => {
    console.log("Before setting to Feedback:", experimentStage);
    setExperimentStage('Feedback');
    console.log("After setting to Feedback:", experimentStage);
  };   

  const renderFeedback = () => {
    const userEvaluatedSamples = calculatedRankingData.map(data => data.sampleName); 
    const relevantFeedbackData = feedbackDataPVQDSustainedVowels.filter(feedback => userEvaluatedSamples.includes(feedback.sampleName));
    return (
      <Feedback
        feedbackData={relevantFeedbackData}
        sliderValues={sliderValues}
        evaluatedAttribute={evaluatedAttribute} 
      />
    );
  };
  

  return (
    <div>
      {experimentStage === 'Introduction' && renderIntroduction()}
      {experimentStage === 'PairComparison' &&
        <PairComparison evaluatedAttribute={evaluatedAttribute} samplePairs={samplePairs} onCompletion={handlePairComparisonCompletion} />
      }
      {experimentStage === 'Ranking' && 
        <Ranking evaluatedAttribute={evaluatedAttribute} userData={calculatedRankingData} onConfirm={handleRankingCompletion} />}
      {experimentStage === 'Sliders' && (
        console.log(rankingData),
        <Sliders evaluatedAttribute={evaluatedAttribute} sorts={rankingData} onCompletion={handleSliderCompletion} />
      )}
      {experimentStage === 'Results' && renderResults()}
      {experimentStage === 'Feedback' && renderFeedback()} {/* Render feedback here */}
      <WaldenCitation />
    </div>
  );
};

export default Training;