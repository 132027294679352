// ParticipantsTable.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const ParticipantsTable = ({ participants, results }) => {
  const { t } = useTranslation();
  const tableRef = useRef();

  useEffect(() => {
    const tableContainer = d3.select(tableRef.current);
    tableContainer.selectAll('*').remove();

    // Preparem les dades de la taula
    const data = participants.map(participant => {
      const isConnected = true; // Aquí podries afegir la lògica real per detectar connexions
      const hasSubmittedResults = results.some(result => {
        // Si tots dos tenen userId, comparem-los
        if (result.userId && participant.userId) {
          return result.userId.toString() === participant.userId.toString();
        }
        // Si no tenen userId, comparem per nom (i potser convertir-los a minúscules per evitar diferències de majúscules)
        return result.participantName.trim().toLowerCase() === participant.participantName.trim().toLowerCase();
      });
      return {
        participantName: participant.participantName || 'Unknown',
        isConnected,
        hasSubmittedResults
      };
    });     

    // Creació de la taula
    const table = tableContainer.append('table')
      .style('width', '100%')
      .style('border-collapse', 'collapse')
      .style('font-size', '13px');

    // Capçalera de la taula
    table.append('thead').append('tr')
      .selectAll('th')
      .data([t('Participant'), t('Connected'), t('Submitted Results')])
      .enter().append('th')
      .style('border-bottom', '1px solid #ccc')
      .style('padding', '4px')
      .style('text-align', 'left')
      .text(d => d);

    // Cos de la taula
    const tbody = table.append('tbody');

    data.forEach(({ participantName, isConnected, hasSubmittedResults }) => {
      const row = tbody.append('tr');
      row.selectAll('td')
        .data([
          participantName,
          isConnected ? t('Yes') : t('No'),
          hasSubmittedResults ? t('Yes') : t('No')
        ])
        .enter().append('td')
        .style('padding', '4px')
        .style('border-bottom', '1px solid #eee')
        .text(d => d);
    });

  }, [participants, results, t]);

  return <div ref={tableRef} style={{ marginTop: '20px' }}></div>;
};

export default ParticipantsTable;