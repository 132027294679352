// CAPEVRRatings.
// TODO Activar la creació de PDF quan hagi decidir en quines condicions

import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from './../../../components/feedback/DataTable';
import CommentCell from '../../../components/feedback/CommentCell';
import { downloadCSV } from './../../../utils/cvsUtils';
import { variableTranslations } from './../../../constants/variableTranslations';
//import { PDFDownloadLink } from '@react-pdf/renderer';
//import UserRatingsPDF from '../../../components/feedback/UserRatingsPDF';

function CAPEVRRatings({ submittedData, sampleDatabase, onGoToFeedback, onRestart, isSessionMode }) {
  const { t } = useTranslation();

  if (!submittedData) {
    return <p>{t('no_ratings_available')}</p>;
  }

  const userData = submittedData.perceptualData || {};

  // Processa les dades per exportar
  const processedData = Object.entries(userData).map(([key, value]) => {
    if (!value) return null;

    // Utilitza attributeName si existeix i no és buit, sinó tradueix la clau
    const attribute = value.attributeName && value.attributeName.trim() !== ''
      ? value.attributeName
      : t(variableTranslations[key] || key);

    const userRating = value.VASValue ? `${value.VASValue}/100` : value.isPresent ? t('yes') : '-';
    const userComment = value?.attributeComment ?? '-';

    // Exclou overallImpression per evitar duplicats
    if (key === 'overallImpression') return null;

    return { Attribute: attribute, Rating: userRating, Comment: userComment };
  }).filter(Boolean); // Elimina resultats nuls

  // Afegim l'overallImpression al final si existeix
  if (submittedData.perceptualData?.overallImpression) {
    processedData.push({
      Attribute: t(variableTranslations['overallImpression'] || 'Overall Impression'),
      Rating: '-',
      Comment: submittedData.perceptualData.overallImpression
    });
  }    

  // Copia al porta-retalls
  const handleCopyToClipboard = () => {
    const csvContent = processedData.map(row => `${row.Attribute},${row.Rating},${row.Comment}`).join('\n');
    navigator.clipboard.writeText(`Attribute,Rating,Comment\n${csvContent}`);
    alert(t('copied_to_clipboard'));
  };

  // Descarrega CSV
  const handleDownloadCSV = () => {
    downloadCSV(processedData, 'user_ratings.csv');
  };

  return (
      <div className="capevr-ratings">
          <h2>{t('your_ratings')}</h2>
          <p>{t('ratings_introduction')}</p>
            {/* 🔹 Botons d'exportació */}
            <div className="button-container">
                <button onClick={handleCopyToClipboard} className="button-center">
                    {t('copy_ratings_to_clipboard')}
                </button>
                <button onClick={handleDownloadCSV} className="button-center">
                    {t('download_ratings_in_csv_format')}
                </button>
                {/*<PDFDownloadLink
                    document={<UserRatingsPDF data={processedData} />}
                    fileName="user_ratings.pdf"
                    className="button-center"
                >
                    {({ loading }) => (loading ? t('generating_pdf') : t('download_pdf'))}
                </PDFDownloadLink>*/}
            </div>

           {/* Taules per blocs d'atributs */}
      <h4>{t('standard_attributes')}</h4>
      <DataTable
        userData={userData}
        attributeKeys={['overallSeverity', 'roughness', 'breathiness', 'strain']}
      />

      <h4>{t('pitch_and_loudness')}</h4>
      <DataTable
        userData={userData}
        attributeKeys={['normalPitch', 'tooLowPitch', 'tooHighPitch', 'normalLoudness', 'tooQuietLoudness', 'tooLoudLoudness']}
      />

      <h4>{t('resonance_and_nasality')}</h4>
      <DataTable
        userData={userData}
        attributeKeys={['normalResonance', 'frontResonance', 'backResonance', 'normalNasality', 'hyponasality', 'hypernasality']}
      />

      <h4>{t('inconsistencies')}</h4>
      <DataTable
        userData={userData}
        attributeKeys={['vowelsInconsistency', 'sentencesInconsistency', 'extemporaneousSpeechInconsistency']}
      />

      <h4>{t('instabilities')}</h4>
      <DataTable
        userData={userData}
        attributeKeys={['aphonicBreak', 'pitchBreak', 'pitchInstability', 'spasm', 'tremor', 'otherInstability']}
      />

      <h4>{t('additional_features')}</h4>
      <DataTable
        userData={userData}
        attributeKeys={['aphonia', 'asthenia', 'diplophonia', 'falsetto', 'fry', 'hardGlottalAttack', 'wetGurgly', 'otherAdditionalFeature']}
      />

      <h4>{t('overall_impression')}</h4>
      <CommentCell
        userData={userData}
        attributeKeys={['overallImpression']}
      />

      {/* Botons segons la base de dades i el mode */}
      <div className="button-container">
        {(!submittedData.voiceSampleId || sampleDatabase === 'upload') ? (
          <button onClick={onRestart} className="button-center">
            {t('restart_with_another_sample')}
          </button>
        ) : (
          !isSessionMode && (
            <button onClick={onGoToFeedback} className="button-center">
              {t('view_feedback')}
            </button>
          )
        )}
      </div>
    </div>
  );
}

export default CAPEVRRatings;