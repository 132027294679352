// CommentsTable.jsx

import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { variableTranslations } from '../../constants/variableTranslations';

const CommentsTable = ({ userData = {}, assessments = [], sessionEvaluations = [], consensusEvaluation = null, attributeKeys = [] }) => {
    const { t } = useTranslation();
    const tableRef = useRef();

    // 🎨 Colors
    const colorMap = useMemo(() => ({
        user: 'red',
        session: 'red',
        shared: '#209385'
    }), []);

    // 🔍 Processa comentaris segons els atributs especificats
    const processComments = () => {
        const commentsData = {};

        // 🔸 Afegeix comentaris de l'usuari
        Object.entries(userData?.perceptualData || {}).forEach(([key, value]) => {
            if (value?.attributeComment && (attributeKeys.length === 0 || attributeKeys.includes(key))) {
                const attributeName = t(variableTranslations[key] || key);
                if (!commentsData[attributeName]) {
                    commentsData[attributeName] = { userComment: value.attributeComment, sessionComments: [], sharedComments: [] };
                } else {
                    commentsData[attributeName].userComment = value.attributeComment;
                }
            }
        });

        // 🔸 Afegeix comentaris compartits
        assessments.forEach((assessment) => {
            Object.entries(assessment.perceptualData || {}).forEach(([key, value]) => {
                if (value?.attributeComment && (attributeKeys.length === 0 || attributeKeys.includes(key))) {
                    const attributeName = t(variableTranslations[key] || key);
                    if (!commentsData[attributeName]) {
                        commentsData[attributeName] = { userComment: '', sessionComments: [], sharedComments: [value.attributeComment] };
                    } else {
                        commentsData[attributeName].sharedComments.push(value.attributeComment);
                    }
                }
            });
        });

        // 🔸 Afegeix comentaris de sessió
        sessionEvaluations.forEach((evaluation) => {
            Object.entries(evaluation.perceptualData || {}).forEach(([key, value]) => {
                if (value?.attributeComment && (attributeKeys.length === 0 || attributeKeys.includes(key))) {
                    const attributeName = t(variableTranslations[key] || key);
                    if (!commentsData[attributeName]) {
                        commentsData[attributeName] = { userComment: '', sessionComments: [value.attributeComment], sharedComments: [] };
                    } else {
                        commentsData[attributeName].sessionComments.push(value.attributeComment);
                    }
                }
            });
        });

        // 🔸 Afegeix comentaris de consens
        if (consensusEvaluation && consensusEvaluation.perceptualData) {
            Object.entries(consensusEvaluation.perceptualData).forEach(([key, value]) => {
                if (value?.attributeComment && (attributeKeys.length === 0 || attributeKeys.includes(key))) {
                    const attributeName = t(variableTranslations[key] || key);
                    if (!commentsData[attributeName]) {
                        commentsData[attributeName] = { userComment: '', sessionComments: [value.attributeComment], sharedComments: [] };
                    } else {
                        commentsData[attributeName].sessionComments.push(value.attributeComment);
                    }
                }
            });
        }  

        return Object.entries(commentsData).map(([attribute, { userComment, sessionComments, sharedComments }]) => ({
            attribute,
            userComment,
            sessionComments: sessionComments.join('; '),
            sharedComments: sharedComments.join('; ')
        }));
    };

    const commentsData = useMemo(processComments, [userData, assessments, sessionEvaluations, consensusEvaluation, attributeKeys, t]);

    // 🎨 Renderitza la taula amb D3
    useEffect(() => {
        const tableContainer = d3.select(tableRef.current);
        tableContainer.selectAll('*').remove();

        if (commentsData.length === 0) {
            tableContainer.append('p')
                .text(t('no_comments_available'))
                .style('font-size', '12px')
                .style('text-align', 'center');
            return;
        }

        // Determina si hi ha dades de sessió
        const hasSessionData = commentsData.some(row => row.sessionComments && row.sessionComments.trim() !== '' && row.sessionComments.trim() !== '-');

        // Construeix l'array de capçaleres de forma dinàmica
        const headers = [t('attribute'), t('your_comment')];
        if (hasSessionData) {
            headers.push(t('session_comments'));
        }
        headers.push(t('other_allvoiced_users_comments'));

        const table = tableContainer.append('table')
            .style('width', '100%')
            .style('border-collapse', 'collapse')
            .style('font-size', '12px');

        // Capçalera
        const thead = table.append('thead');
        thead.append('tr')
            .selectAll('th')
            .data(headers)
            .enter()
            .append('th')
            .style('text-align', 'left')
            .style('padding', '4px')
            .style('border-bottom', '1px solid #ccc')
            .text(d => d);

        // Cos de la taula
        const tbody = table.append('tbody');
        commentsData.forEach(({ attribute, userComment, sessionComments, sharedComments }) => {
            const row = tbody.append('tr');

            row.append('td')
                .style('padding', '4px')
                .style('border-bottom', '1px solid #eee')
                .text(attribute);

            row.append('td')
                .style('padding', '4px')
                .style('border-bottom', '1px solid #eee')
                .style('color', colorMap.user)
                .text(userComment || '-');

            if (hasSessionData) {
                row.append('td')
                    .style('padding', '4px')
                    .style('border-bottom', '1px solid #eee')
                    .style('color', colorMap.session)
                    .text(sessionComments || '-');
            }

            row.append('td')
                .style('padding', '4px')
                .style('border-bottom', '1px solid #eee')
                .style('color', colorMap.shared)
                .text(sharedComments || '-');
        });
    }, [commentsData, t, colorMap]);

    return (
        <div className="comments-table-container">
            <div ref={tableRef}></div>
        </div>
    );
};

export default CommentsTable;