// RatingsDisplayer.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import OsRatings from './../../../os/os-views/OsRatings';
import CAPEVRatings from './../../../cape-v/cape-v-views/CAPEVRatings';
import CAPEVRRatings from './../../../cape-vr/cape-vr-views/CAPEVRRatings';

const RatingsDisplayer = ({ selectedTool, results, selectedSample, isSessionMode, onJoinAnotherSession }) => {
  const { t } = useTranslation();

  // Si no hi ha resultats, mostra un missatge
  if (!results || results.length === 0) {
    return <p>{t('no_results')}</p>;
  }

  // Funció d'adaptació: converteix cada resultat en l'estructura que OSRatings espera
  const adaptResult = (result) => ({
    submittedData: {
      perceptualData: result.perceptualData && result.perceptualData.perceptualData 
                        ? result.perceptualData.perceptualData 
                        : {},
      voiceSampleId: selectedSample?._id || null,
    },
  });

  const renderRatings = () => {
    switch (selectedTool?.toolName) {
      case 'OS':
        return results.map((result, index) => (
          <OsRatings key={index} submittedData={adaptResult(result).submittedData} isSessionMode={isSessionMode} />
        ));
      case 'CAPE-V':
        return results.map((result, index) => (
          <CAPEVRatings key={index} submittedData={adaptResult(result).submittedData} isSessionMode={isSessionMode} />
        ));
      case 'CAPE-Vr':
        return results.map((result, index) => (
          <CAPEVRRatings key={index} submittedData={adaptResult(result).submittedData} isSessionMode={isSessionMode} />
        ));
      default:
        return <p>{t('tool_not_supported')}</p>;
    }
  };

  return (
    <div>
      <h2>{selectedTool?.description}</h2>
      {renderRatings()}
  
      <div className="button-container" style={{ marginTop: '20px' }}>
        <button className="button-center" onClick={onJoinAnotherSession}>
          {t('join_another_session')}
        </button>
      </div>
      <br/>
    </div>
  );  
};

export default RatingsDisplayer;