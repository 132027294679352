// Collection.jsx

import React from 'react';
import useAudioPlayer from '../../../hooks/useAudioPlayer';
import { useTranslation } from 'react-i18next';
import './../../../locales/i18n';

function Collection({ audioFiles, ratings, handleSliderChange, collectionIndex, numberOfSliders, markedSamples = [] }) {
  const { t } = useTranslation();
  const { playAudio } = useAudioPlayer();

  const sliderElements = audioFiles.map((audioFile, index) => {
    // Handle undefined markedSamples by setting a default value of false
    const isMarked = markedSamples[index] || false;

    return (
      <div key={index} className="slider-item">
        <button
          onClick={() => playAudio(audioFile)}
          style={isMarked ? { fontWeight: 'bold', backgroundColor: '#ffd700' } : {}}
        >
          Play {isMarked ? t('tie') : ""}
        </button>
        <input
          type="range"
          id={`slider-${collectionIndex}-${index}`}
          min="0"
          max="100"
          value={ratings[collectionIndex * numberOfSliders + index]}
          onChange={(e) => handleSliderChange(collectionIndex * numberOfSliders + index, parseInt(e.target.value))}
        />
      </div>
    );
  }).slice(0, numberOfSliders);

  return (
    <div>
      {sliderElements}
    </div>
  );
}

export default Collection;