// ResourcesForTeaching.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../../locales/i18n';
import { useAuth } from '../../auth/authContext';
import NavigationIcons from './../app-views/NavigationIcons';

function ResourcesForTeaching() {
    const { t } = useTranslation();
    const { user } = useAuth(); 
    const navigate = useNavigate();

    const renderLockIcon = (isRestricted) => (
        isRestricted ? (
            <i className="fas fa-lock" style={{ color: user ? '#a5d3ce' : 'red' }}></i>
        ) : null
    );

    return (
        <div className="main-content">
            <h1>{t('resources_for_teaching_and_learning_title')}</h1>
            <p>{t('resources_for_teaching_and_learning_presentation')}</p>


            {/* CAPE-V Workshop */}
            <p className="centered-text">{t('create_or_join_a_group_session_to_gather_compare_and_discuss_evaluations')}:</p>
            <button className="button-link" onClick={() => navigate('/cape-v-workshop')}>
                {t('cape_v_workshop_title')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/>

            {/* Classroom */}
            <p className="centered-text">{t('evaluate_instructor_s_selected_sample_and_get_feedback')}:</p>
            <button className="button-link" onClick={() => navigate('/os-classroom')}>
                {t('overall_severity')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/>
            <button className="button-link" onClick={() => navigate('/cape-v-classroom')}>
                {t('cape_v_classroom_title')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/>

            {/* CAPE-V Scholar */}
            <p className="centered-text">{t('upload_and_evaluate_your_own_samples')}:</p>
            <button className="button-link" onClick={() => navigate('/cape-v-scholar')}>
                {t('cape_v_scholar_title')}
                <span className="restricted-marker">{renderLockIcon(false)}</span>
            </button><br/>

            {/* Researck Link */}

            <p className="centered-text">{t('transform_your_classroom_insights_into_research_impact')}:</p>
            <button className="navigation-button" onClick={() => navigate('/research-at-all-voiced')}>
                {t('research_at_allvoiced')}
            </button><br/>

            <div className="navigation-container">
                <NavigationIcons exclude={['/resources-for-teaching-and-learning']} />
            </div>
        </div>
    );    
}

export default ResourcesForTeaching;
