// LanguageContext.jsx

import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from './i18n'; // 🔹 Assegura't que la ruta sigui correcta

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [appLanguage, setAppLanguage] = useState('en'); // idioma de la interfície

  const [resourceLanguages] = useState({
    CAPEV: ['en', 'es', 'ca', 'fr', 'jp', 'my'],
    // en procés de negociació: 'fa', 'fr-CA', 'kn', 'pt-br'
    CAPEVR: ['en'],
  });

  // 🔁 Sincronitza amb i18n.language
  useEffect(() => {
    const syncWithI18n = () => {
      const currentLang = i18n.language;
      setAppLanguage(currentLang);
    };

    // Inicialització
    syncWithI18n();

    // Escolta canvis d’idioma
    i18n.on('languageChanged', syncWithI18n);

    // Neteja al desmuntar
    return () => {
      i18n.off('languageChanged', syncWithI18n);
    };
  }, []);

  const changeAppLanguage = (lang) => {
    i18n.changeLanguage(lang); // 🔁 Assegura que també canviï a i18n
    localStorage.setItem('selectedLanguage', lang);
  };

  return (
    <LanguageContext.Provider value={{ appLanguage, changeAppLanguage, resourceLanguages }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook per accedir fàcilment al context
export const useLanguage = () => useContext(LanguageContext);