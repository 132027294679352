// AuditoryPerceptualGames.jsx

import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/authContext';
import LockIcon from './../../components/icons/LockIcon';
import '../../locales/i18n';
import ContactFormModal from "./../../components/modals/ContactFormModal";

function AuditoryPerceptualGames() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isContactModalOpen, setContactModalOpen] = useState(false);

    const openContactModal = () => {
        setContactModalOpen(true);
    };

    const closeContactModal = () => {
        setContactModalOpen(false);
    };

    const resources = [
        { title: t('guess_the_diagnosis_title'), route: '/guess-the-diagnosis', restricted: true },
    ];

    return (
        <div className="main-content">
            <h1>{t('auditory_perceptual_games')}</h1>
            {resources.map((resource, index) => (
                <button 
                    key={index}
                    className="button-center"
                    onClick={() => navigate(resource.route)}
                >
                    {resource.title}
                    {resource.restricted && (
                        <span className="restricted-marker">
                            <LockIcon isRestricted={true} user={user} />
                        </span>
                    )}
                </button>
            ))}
            <br/>
            <br/>
            <br/>
            <h4 className="text-green centered-text">{t("auditory_perceptual_games_cta")}</h4>
                <div className="button-container">
                    <button onClick={openContactModal} className="button-center">
                        {t('get_in_touch')}
                    </button>
                </div>

            <ContactFormModal isOpen={isContactModalOpen} onRequestClose={closeContactModal} />
        </div>
    );
}

export default AuditoryPerceptualGames;