// VASValueFeedback.jsx

import React from 'react';
import VASGraph from './VASGraph';
import { useTranslation } from 'react-i18next';
import { variableTranslations } from '../../constants/variableTranslations';

const VASValueFeedback = ({ userData = {}, assessments = [], sessionEvaluations = [], consensusEvaluation = [], attributeKeys }) => {
  const { t } = useTranslation();

  const excludedAttributes = ['pitch', 'loudness'];

  const attributeOrder = [
    'overallSeverity', 'roughness', 'breathiness', 'strain',
    'aphonicBreak', 'pitchBreak', 'pitchInstability', 'spasm', 'tremor',
    'aphonia', 'asthenia', 'diplophonia', 'falsetto', 'fry', 'hardGlottalAttack', 'wetGurgly',
  ];

  const processVASData = () => {
    const vasData = new Map();

    const addEntry = (source, key, value, raterSource = null) => {
      const VASValue = value?.VASValue;
      const attributeName = value?.attributeName || key;
      if (VASValue <= 0 || excludedAttributes.includes(key)) return;
      if (!vasData.has(attributeName)) {
        vasData.set(attributeName, {
          key,
          user: [],
          shared: [],
          session: [],
          consensus: [],
        });
      }
      const entry = { value: VASValue };
      if (raterSource) entry.raterSource = raterSource;
      vasData.get(attributeName)[source].push(entry);
    };

    Object.entries(userData?.perceptualData || {}).forEach(([key, value]) => {
      if (attributeKeys?.includes(key)) {
        addEntry('user', key, value);
      }
    });

    sessionEvaluations.forEach(evaluation => {
      Object.entries(evaluation?.perceptualData || {}).forEach(([key, value]) => {
        if (attributeKeys?.includes(key)) {
          addEntry('session', key, value);
        }
      });
    });

    if (consensusEvaluation?.perceptualData) {
      Object.entries(consensusEvaluation.perceptualData).forEach(([key, value]) => {
        if (attributeKeys?.includes(key)) {
          addEntry('consensus', key, value);
        }
      });
    }

    assessments.forEach(assessment => {
      const raterSource = assessment.raterSource || 'unknownSource';
      Object.entries(assessment?.perceptualData || {}).forEach(([key, value]) => {
        if (attributeKeys?.includes(key)) {
          addEntry('shared', key, value, raterSource);
        }
      });
    });

    return Array.from(vasData.entries())
      .sort(([aName, aData], [bName, bData]) => {
        const indexA = attributeOrder.indexOf(aData.key);
        const indexB = attributeOrder.indexOf(bData.key);
        return (indexA !== -1 ? indexA : 999) - (indexB !== -1 ? indexB : 999);
      })
      .map(([attributeName, values]) => {
        const isTranslatable = variableTranslations.hasOwnProperty(attributeName);
        const displayName = isTranslatable ? t(variableTranslations[attributeName]) : attributeName;

        return {
          attributeName,
          displayName,
          userValues: values.user,
          sharedValues: values.shared,
          sessionValues: values.session,
          consensusValues: values.consensus,
        };
      });
  };

  const vasData = processVASData();

  return (
    <div className="feedback-section">
      {vasData.length > 0 ? (
        vasData.map(({ attributeName, displayName, userValues, sharedValues, sessionValues, consensusValues }) => (
          <div key={attributeName} className="vas-graph">
            <p className="small">{displayName}</p>
            <VASGraph
              attributeName={attributeName}
              userValues={userValues}
              sharedValues={sharedValues}
              sessionValues={sessionValues}
              consensusValues={consensusValues}
            />
          </div>
        ))
      ) : (
        <p style={{ textAlign: 'center', fontSize: '0.7em' }}>{t('no_vas_data_available')}</p>
      )}
    </div>
  );
};

export default VASValueFeedback;