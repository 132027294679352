// AdditionalResonances.jsx

import React, { useState, useEffect, useRef } from 'react';
import { useFixedTForResource } from '../../../../locales/useFixedTForResource';
import { variableTranslations } from './../../../../constants/variableTranslations';

function AdditionalResonances({ value = {}, onChange }) {
  const t = useFixedTForResource('CAPEV');

  const [evaluationType, setEvaluationType] = useState('');

  const [resonances, setResonances] = useState({
    hyponasality: { attributeName: variableTranslations.hyponasality, isPresent: false },
    hypernasality: { attributeName: variableTranslations.hypernasality, isPresent: false },
    otherResonance: { attributeName: '', isPresent: false }
  });

  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current && value && Object.keys(value).length > 0) {
      const isAnyResonancePresent =
        value.hyponasality?.isPresent ||
        value.hypernasality?.isPresent ||
        value.otherResonance?.isPresent;
      setEvaluationType(isAnyResonancePresent ? 'other' : '');
      setResonances({
        hyponasality: value.hyponasality || { attributeName: variableTranslations.hyponasality, isPresent: false },
        hypernasality: value.hypernasality || { attributeName: variableTranslations.hypernasality, isPresent: false },
        otherResonance: value.otherResonance || {
          attributeName: '',
          isPresent: false
        }
      });
      isInitialized.current = true;
    }
  }, [value]);

  const handleRadioChange = (e) => {
    const selected = e.target.value;
    setEvaluationType(selected);
    if (selected === 'normal') {
      const newResonances = {
        hyponasality: { attributeName: variableTranslations.hyponasality, isPresent: false },
        hypernasality: { attributeName: variableTranslations.hypernasality, isPresent: false },
        otherResonance: {
          attributeName: '',
          isPresent: false
        }
      };
      setResonances(newResonances);
      onChange({
        normalNasality: { attributeName: variableTranslations.normalNasality, isPresent: true, VASValue: 0 },
        ...newResonances,
      });
    } else {
      onChange({
        normalNasality: { attributeName: variableTranslations.normalNasality, isPresent: false, VASValue: 0 },
        hyponasality: resonances.hyponasality,
        hypernasality: resonances.hypernasality,
        otherResonance: resonances.otherResonance,
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const newResonances = {
      ...resonances,
      [name]: {
        ...resonances[name],
        isPresent: checked
      }
    };
    setResonances(newResonances);
    onChange({
      normalNasality: { attributeName: variableTranslations.normalNasality, isPresent: false, VASValue: 0 },
      hyponasality: newResonances.hyponasality,
      hypernasality: newResonances.hypernasality,
      otherResonance: newResonances.otherResonance,
    });
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    const newResonances = {
      ...resonances,
      otherResonance: {
        ...resonances.otherResonance,
        attributeName: newText
      }
    };
    setResonances(newResonances);
    onChange({
      normalNasality: { attributeName: variableTranslations.normalNasality, isPresent: false, VASValue: 0 },
      hyponasality: newResonances.hyponasality,
      hypernasality: newResonances.hypernasality,
      otherResonance: newResonances.otherResonance,
    });
  };

  return (
    <div className="button-container">
      <label className="centered-text">
        <strong>{t('comments_resonance')}</strong>
      </label>
      <div>
        <label className="horizontal-labels">
          <input
            type="radio"
            name="evaluationType"
            value="normal"
            checked={evaluationType === 'normal'}
            onChange={handleRadioChange}
          />
          {t('normal')}
        </label>
        <label className="horizontal-labels">
          <input
            type="radio"
            name="evaluationType"
            value="other"
            checked={evaluationType === 'other'}
            onChange={handleRadioChange}
          />
          {t('other')}
        </label>
      </div>

      {evaluationType === 'other' && (
        <div>
          <div className="horizontal-labels">
            <label>
              <input
                type="checkbox"
                name="hyponasality"
                checked={resonances.hyponasality?.isPresent || false}
                onChange={handleCheckboxChange}
              />
              {t(variableTranslations.hyponasality)}
            </label>
          </div>
          <div className="horizontal-labels">
            <label>
              <input
                type="checkbox"
                name="hypernasality"
                checked={resonances.hypernasality?.isPresent || false}
                onChange={handleCheckboxChange}
              />
              {t(variableTranslations.hypernasality)}
            </label>
          </div>
          <div className="horizontal-labels">
          <label>
            <input
              type="checkbox"
              name="otherResonance"
              checked={resonances.otherResonance?.isPresent || false}
              onChange={handleCheckboxChange}
            />
            {resonances.otherResonance?.attributeName || t(variableTranslations.otherResonance)}
          </label>
          </div>
          <input
            type="text"
            value={resonances.otherResonance?.attributeName || ''}
            onChange={handleTextChange}
            placeholder={t('specify')}
          />
        </div>
      )}
    </div>
  );
}

export default AdditionalResonances;